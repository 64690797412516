<template>
  <div
    :id="reservation.id"
    :class="{ highlight: highlight }"
    class="sm:h-12 flex items-center gap-y-2 sm:gap-2 text-n-0 font-body text-sm w-full px-4 py-4 sm:py-0 border-b border-n-600 cursor-pointer flex-wrap sm:flex-nowrap hover:bg-n-700"
  >
    <div class="sm:w-4/12 w-5/6">
      {{ reservation.name }} {{ reservation.surname }}
    </div>
    <div class="sm:w-2/12 w-1/6 text-center font-bold">
      x{{ reservation.diners }}
    </div>
    <div class="sm:w-2/12 w-1/4 flex items-center gap-1">
      <l-icon name="time" />
      {{ d(reservation.dateTime, 'time') }}
    </div>
    <div class="sm:w-2/12 w-1/4 flex items-center gap-1">
      <l-icon name="table" />
      {{ tables }}
    </div>
    <div class="sm:w-2/12 w-1/4 flex items-center gap-1">
      <l-icon name="floorplan" />
      {{ floorplan }}
    </div>
    <div class="sm:w-2/12 w-1/4">
      {{ source }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { LIcon } from '@last/core-ui/paprika'

import { useTablesStore } from '@/store/tables'
import { Reservation } from '@/types'

const props = defineProps<{
  reservation: Reservation
  highlight?: boolean
}>()

const { t, d } = useI18n()

const tablesStore = useTablesStore()
const { tables: allTables } = storeToRefs(tablesStore)

const tables = computed(() => {
  const { tables, zone } = props.reservation
  if (tables && tables.length) {
    return tables.map(table => allTables.value[table].name).join(', ')
  }
  return zone || '-'
})

const floorplan = computed(() => {
  const { tables } = props.reservation
  if (tables && tables.length) {
    return tablesStore.getTableFloorplan(tables[0]).name
  }
  return '-'
})

const source = computed(() => {
  const source = props.reservation.source
  if (source && !['pos', 'admin'].includes(source)) {
    return props.reservation.source
  }
  return t('reservations.restaurant')
})
</script>

<style scoped>
.highlight {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    @apply bg-n-700;
  }
  50% {
    @apply bg-n-800;
  }
}
</style>
