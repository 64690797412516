import { format, isWithinInterval, parse } from 'date-fns'
import { enGB } from 'date-fns/locale'

/**
 * Check if a product is enabled based on its schedule
 */
export function isProductEnabled(
  product: {
    hasSchedule: boolean
    schedules: Array<{
      days: Array<string>
      hourFrom: string
      hourTo: string
    }>
  },
  date: Date,
  _ = 'Europe/Madrid'
) {
  if (!product.hasSchedule) {
    return true
  }
  const tabDayName = format(date, 'EEEE', {
    locale: enGB
  }).toLowerCase()
  const productSchedules = (product.schedules || []).filter(s =>
    s.days.includes(tabDayName)
  )
  let isEnabled = false
  productSchedules.forEach(s => {
    const beforeTime = parse(s.hourFrom, 'HH:mm', new Date())
    const afterTime = parse(s.hourTo, 'HH:mm', new Date())
    if (isWithinInterval(date, { start: beforeTime, end: afterTime })) {
      isEnabled = true
    }
  })
  return isEnabled
}
