<template>
  <div class="dark">
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 transition-all duration-300 bg-n-900/80 backdrop-blur-sm absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden"
        @click="emit('close')"
      >
        <div
          class="menu transition-all menu-background backdrop-blur-sm w-[19.75rem] h-full overflow-y-scroll"
          @click.stop
        >
          <div class="relative text-n-0 flex flex-col px-3 h-full">
            <div
              class="absolute top-0 right-0 p-3 mt-5 cursor-pointer"
              @click="emit('close')"
            >
              <l-icon name="close" class="text-n-0" />
            </div>
            <div
              v-if="importantNotifications.length > 0"
              class="flex flex-col mt-20 py-4 w-full"
            >
              <div class="font-title font-bold text-base leading-5 mb-6">
                {{ $t('notifications.important-notifications') }}
              </div>
              <transition-group
                class="notification-list flex flex-col items-center"
                tag="div"
                name="list"
                appear
                @before-leave="beforeLeave"
              >
                <l-notification
                  v-for="notification in importantNotifications"
                  :key="notification.id"
                  class="notification mb-2 pointer-events-auto"
                  v-bind="notification"
                  :close="false"
                  @cancel="() => cancelProgress(notification)"
                />
              </transition-group>
            </div>
            <div
              class="flex flex-col py-4 w-full"
              :class="{ 'mt-20': importantNotifications.length <= 0 }"
            >
              <div class="flex justify-between items-center mb-6">
                <div class="font-title font-bold text-base leading-5">
                  {{ $t('notifications.notifications') }}
                </div>
                <div
                  class="text-v cursor-pointer underline text-sm"
                  :class="{
                    'opacity-50 pointer-events-none': !(
                      notifications.length > 0
                    )
                  }"
                  @click="clearAll"
                >
                  {{ $t('notifications.clear-all') }}
                </div>
              </div>
              <transition-group
                v-if="notifications.length > 0"
                class="notification-list flex flex-col items-center"
                tag="div"
                name="list"
                appear
                @before-leave="beforeLeave"
              >
                <l-notification
                  v-for="notification in standardNotifications"
                  :key="notification.id"
                  class="notification mb-2 pointer-events-auto"
                  v-bind="notification"
                  @close="() => deleteNotification(notification.id)"
                  @cancel="() => cancelProgress(notification)"
                />
              </transition-group>
              <empty-case
                v-else
                type="empty"
                :item-translation-key="$t('notifications.notifications')"
              ></empty-case>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { EmptyCase, LIcon } from '@last/core-ui/paprika'

import LNotification from './LNotification.vue'
import type { Notification } from './types'

const props = withDefaults(
  defineProps<{
    notifications: Notification[]
    open: boolean
  }>(),
  {
    notifications: () => [],
    open: false
  }
)

const emit = defineEmits(['close', 'cancel', 'clear', 'update:notifications'])

function deleteNotification(id: string) {
  const updatedNotifications = props.notifications.filter(
    notification => notification.id !== id
  )
  emit('update:notifications', updatedNotifications)
}

function cancelProgress(notification: Notification) {
  emit('cancel', notification)
  deleteNotification(notification.id)
}

function clearAll() {
  emit('update:notifications', importantNotifications.value)
}

const importantNotifications = computed(() =>
  props.notifications.filter(notification => notification.important)
)

const standardNotifications = computed(() =>
  props.notifications.filter(notification => !notification.important)
)

const beforeLeave = (element: Element) => {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.menu-background {
  background: rgba(42, 42, 66, 0.6);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.list-enter-active {
  transition: all 0.4s ease;
}
.list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}
.list-move {
  transition: all 0.3s ease;
}
</style>
