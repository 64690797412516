type Size = { width: number; height?: number }

const DEFAULT_SIZE: Size = { width: 150, height: 75 }

const IMAGE_CACHE = new Map<string, HTMLImageElement>()

function generateImageUrl(
  imageId: string,
  size: Size = DEFAULT_SIZE,
  isGrayscale = false
) {
  return `https://res.cloudinary.com/lastpos/image/upload/c_thumb,g_center,w_${size.width}${size.height ? `,h_${size.height}` : ''},f_auto,q_auto${isGrayscale ? ',e_grayscale' : ''}/${imageId}`
}

async function preloadLogo(imageId: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const url = generateImageUrl(imageId, { width: 300 }, true)
    const image = new Image()
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      IMAGE_CACHE.set(imageId, image)
      resolve(image)
    }
    image.onerror = reject
    image.src = url
  })
}

async function getImage(imageId: string): Promise<HTMLImageElement> {
  if (IMAGE_CACHE.has(imageId)) {
    return Promise.resolve(IMAGE_CACHE.get(imageId)!)
  }
  return preloadLogo(imageId)
}

function removeOldCache() {
  // Deprecated logo
  localStorage.removeItem('ticket-logo')
  // Deprecated image cache
  Object.keys(localStorage).forEach(key => {
    if (
      key.startsWith('image-cache-') ||
      key.startsWith('logo-image-') ||
      key.startsWith('brand-logo-')
    ) {
      const imageKey = localStorage.getItem(key)
      if (imageKey) {
        localStorage.removeItem(key)
        localStorage.removeItem(imageKey)
      }
    }
  })
}

export default {
  preloadLogo,
  getImage,
  generateImageUrl,
  removeOldCache
}
