import api from '@/api/fetch'
import { Location } from '@/types'

export function getLocations() {
  return api.get<Location[]>('/locations').then(res => res.data)
}

export function getLocation() {
  return api.get<Location>('/location').then(res => res.data)
}
