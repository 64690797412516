/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoginRequestOneOfDeviceInfo } from './LoginRequestOneOfDeviceInfo';
import {
    LoginRequestOneOfDeviceInfoFromJSON,
    LoginRequestOneOfDeviceInfoFromJSONTyped,
    LoginRequestOneOfDeviceInfoToJSON,
    LoginRequestOneOfDeviceInfoToJSONTyped,
} from './LoginRequestOneOfDeviceInfo';

/**
 * 
 * @export
 * @interface LoginRequestOneOf
 */
export interface LoginRequestOneOf {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOf
     */
    pin: string;
    /**
     * 
     * @type {LoginRequestOneOfDeviceInfo}
     * @memberof LoginRequestOneOf
     */
    deviceInfo: LoginRequestOneOfDeviceInfo;
}

/**
 * Check if a given object implements the LoginRequestOneOf interface.
 */
export function instanceOfLoginRequestOneOf(value: object): value is LoginRequestOneOf {
    if (!('pin' in value) || value['pin'] === undefined) return false;
    if (!('deviceInfo' in value) || value['deviceInfo'] === undefined) return false;
    return true;
}

export function LoginRequestOneOfFromJSON(json: any): LoginRequestOneOf {
    return LoginRequestOneOfFromJSONTyped(json, false);
}

export function LoginRequestOneOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequestOneOf {
    if (json == null) {
        return json;
    }
    return {
        
        'pin': json['pin'],
        'deviceInfo': LoginRequestOneOfDeviceInfoFromJSON(json['deviceInfo']),
    };
}

export function LoginRequestOneOfToJSON(json: any): LoginRequestOneOf {
    return LoginRequestOneOfToJSONTyped(json, false);
}

export function LoginRequestOneOfToJSONTyped(value?: LoginRequestOneOf | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'pin': value['pin'],
        'deviceInfo': LoginRequestOneOfDeviceInfoToJSON(value['deviceInfo']),
    };
}

