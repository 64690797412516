<template>
  <div
    class="h-8 font-body text-sm inline-flex flex-row items-center px-6 py-2 border-b shrink-0"
    :class="getClasses"
  >
    <l-icon
      v-if="!!icon"
      class="overflow-hidden mr-2"
      :name="icon"
      size="small"
    />
    <span class="leading-none">{{ name }}</span>
    <l-badge
      v-if="!!badge"
      class="ml-2"
      type="large"
      :value="badge"
      :mode="type"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { LIconName } from '@last/core-ui/paprika'
import { LBadge, LIcon } from '@last/core-ui/paprika'

export type Props = {
  /**
   * Represents a unique identifier for an entity.
   * Can be a string or a numeric value, depending on the context.
   */
  id: string | number
  /**
   * Represents the name associated with a specific entity or object.
   * This variable is typically used to store a string value that identifies or describes the entity.
   */
  name: string
  /**
   * Represents the selection state of an element or item.
   * Can be used to determine if an item is selected.
   * Optional property, defaults to undefined if not provided.
   */
  selected?: boolean
  /**
   * Indicates whether a particular element or functionality is disabled.
   * If set to `true`, the element is disabled and non-interactive.
   * If set to `false` or `undefined`, the element is enabled and interactive.
   */
  disabled?: boolean
  /**
   * Represents the name of an icon from a predefined set of icons.
   * This optional variable is used to select and apply a specific icon.
   * The value should correspond to a valid name within the LIconName type.
   */
  icon?: LIconName
  /**
   * Represents the badge count associated with a specific item or user.
   *
   * This optional property is typically used to display a numeric indicator,
   * such as unread notifications, achievements, or other counters.
   * If undefined or null, it suggests the absence of a badge or count value.
   */
  badge?: number
  /**
   * Specifies the type of the element.
   *
   * This property accepts one of the following string values:
   * - 'primary': Indicates the primary type, typically used for main actions or prominent elements.
   * - 'secondary': Indicates the secondary type, often used for less prominent or supplementary elements.
   */
  type?: 'primary' | 'secondary'
}

const props = withDefaults(defineProps<Props>(), {
  selected: false,
  icon: undefined,
  badge: undefined,
  disabled: false,
  type: 'primary'
})

const getClasses = computed<string[]>((): string[] => {
  const classes = ['cursor-pointer', 'text-n-800', 'dark:text-n-0']
  const defaultClasses = ['border-transparent']
  const hoverClasses = ['hover:text-v-500', 'dark:hover:text-v-300']

  if (props.disabled) {
    classes.push('opacity-30', 'cursor-default', 'pointer-events-none')
  } else if (props.selected) {
    const newClasses =
      props.type === 'primary'
        ? ['border-v-500', 'dark:border-v-300', 'text-v-500', 'dark:text-v-300']
        : ['border-n-800', 'dark:border-n-0']
    classes.push(...newClasses)
  } else {
    if (props.type === 'primary') {
      classes.push(...hoverClasses)
    }

    classes.push(...defaultClasses)
  }

  return classes
})
</script>
