import app from '@/app'
import { useAuthStore } from '@/store/auth'

import { PrinterConfig, PrinterDriver } from '../types'

export default class Browser implements PrinterDriver {
  constructor(_config: PrinterConfig) {}

  printImage(imageData: string) {
    return new Promise<void>(resolve => {
      const auth = useAuthStore()
      const locationId = auth.locationId
      const demoLocationId = import.meta.env.VITE_APP_DEMO_LOCATION_ID
      const isDemoLocation = locationId === demoLocationId
      if (isDemoLocation) return resolve()

      if (!app.isElectron) {
        const image = new Image()
        image.src = imageData
        const w = window.open('')
        w?.document.write(image.outerHTML)
      }
      resolve()
    })
  }

  openCashDrawer() {}
}
