/* eslint-disable no-console */
import type { Tracker, UserData } from './tracker'

export class ConsoleTracker implements Tracker {
  track(eventName: string, eventProperties: Record<string, any>) {
    console.debug(`Track: ${eventName}`, eventProperties)
  }

  identify(userData: UserData) {
    console.debug('Identify:', userData)
  }

  getFeatureFlag(name: string): boolean {
    console.debug('Getting feature flag:', name)
    return true // no-op
  }

  hasFeatureFlag(name: string): boolean {
    console.debug('Checking existing feature flag:', name)
    return true // no-op
  }

  clear() {
    console.debug('Tracker cleared')
  }
}
