<template>
  <new-password
    :recover-id="recoverId"
    :token="token"
    :recover-password="recoverPassword"
    :verification-token="verificationToken"
    :new-password="newPasswordCallback"
    @login="goToLogin"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { NewPassword } from '@last/core-ui/shared'

import { get, post } from '@/api/fetch'

const route = useRoute()
const router = useRouter()

const recoverId = computed(() => route.params.recoverId as string)
const token = computed(() => route.params.token as string)

function recoverPassword(recoverId: string) {
  return get(`/users/recovery/${recoverId}`).then(response => {
    return response.data.email
  })
}

function verificationToken(email: string) {
  return post(`users/verification-token`, {
    email: email
  })
}

function newPasswordCallback(data: {
  token: string
  recoverId: string
  password: string
}) {
  return post(`/users/recovery/${data.recoverId}`, {
    token: data.token,
    password: data.password
  })
}

function goToLogin() {
  router.push({ name: 'login' })
}
</script>
