<template>
  <l-modal
    :title="$t('reservations.new')"
    :open="open"
    :button-text="$t('reservations.accept')"
    :button-enabled="isComplete"
    :secondary-button="!!reservation?.id"
    :secondary-button-text="$t('reservations.delete-reservation')"
    @secondary-action="cancel()"
    @action="save()"
    @close="$emit('close')"
  >
    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.name')" class="w-full">
        <l-input
          v-model="reservation.name"
          :placeholder="$t('reservations.name')"
          icon="user"
        />
      </l-field>
      <l-field :label="$t('reservations.surname')" class="w-full">
        <l-input
          v-model="reservation.surname"
          :placeholder="$t('reservations.surname')"
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.phone-number')">
      <l-phone-input
        v-model="reservation.phoneNumber"
        icon="phone"
        :placeholder="$t('reservations.phone-number')"
        :wrong-value="validating && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>

    <div class="flex items-center justify-between gap-4">
      <l-field class="w-full" :label="$t('reservations.date')">
        <l-date-picker
          v-model="reservation.dateTime"
          :placeholder="$t('reservations.time-placeholder')"
          :only-future="true"
          :show-time="false"
          :default-time="defaultTime"
        />
      </l-field>
      <l-field class="w-full" :label="$t('reservations.time')">
        <l-time-picker
          v-model="reservation.dateTime"
          :minute-step="15"
          picker-size="large"
          :placeholder="$t('reservations.time-placeholder')"
          :default="defaultTime"
        />
      </l-field>
    </div>

    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.diners')" class="w-full">
        <l-input
          v-model="reservation.diners"
          :placeholder="$t('reservations.diners-placeholder')"
          type="number"
        />
      </l-field>
      <l-field :label="$t('reservations.table')" class="w-full">
        <l-input
          :model-value="
            reservation?.tables?.length ? printTables(reservation.tables) : null
          "
          icon="external"
          icon-position="right"
          :placeholder="$t('reservations.no-table')"
          read-only
          @click="$emit('selectTable')"
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.comments')">
      <l-input
        v-model="reservation.customerComments"
        type="textarea"
        :placeholder="$t('reservations.comments-placeholder')"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { format, roundToNearestMinutes } from 'date-fns'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  LDatePicker,
  LField,
  LInput,
  LModal,
  LPhoneInput,
  LTimePicker,
  useDialog
} from '@last/core-ui/paprika'

import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { useTablesStore } from '@/store/tables'
import { Reservation } from '@/types'

defineProps<{
  open: boolean
}>()

const emit = defineEmits<{
  close: []
  selectTable: []
  cancel: []
}>()

const reservation = defineModel<Partial<Reservation>>({
  default: {}
})

const { t } = useI18n()
const dialog = useDialog()
const tablesStore = useTablesStore()
const configStore = useConfigStore()
const { createReservation, editReservation, cancelReservation } =
  useReservationsStore()

const { tables } = storeToRefs(tablesStore)
const { config } = storeToRefs(configStore)

const validating = ref(false)

const isComplete = computed(() => {
  return (
    !!reservation.value?.name &&
    !!reservation.value?.diners &&
    !!reservation.value?.dateTime
  )
})

const defaultTime = computed(() => {
  return format(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 'HH:mm')
})

const validPhoneNumber = computed(() => {
  const parsed = parsePhoneNumberFromString(
    reservation.value?.phoneNumber || ''
  )
  return parsed?.isValid()
})

function formatNumber(phoneNumber: string) {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber)
  return parsedNumber ? parsedNumber.number : phoneNumber
}

function save() {
  validating.value = true
  if (!validPhoneNumber.value) return
  let formattedReservation = {
    ...reservation.value,
    phoneNumber: formatNumber(reservation.value.phoneNumber!)
  }
  if (formattedReservation.id) {
    editReservation(formattedReservation as Reservation)
  } else {
    formattedReservation = {
      ...formattedReservation,
      id: uuid(),
      cancelled: false
    }
    createReservation(formattedReservation as Reservation)
  }
  emit('close')
}

function cancel() {
  dialog({
    title: t('reservations.delete-reservation'),
    content: t('reservations.delete-reservation-content'),
    secondaryLabel: t('reservations.cancel'),
    icon: 'delete',
    onConfirm: () => {
      if (!reservation.value.id) return
      cancelReservation(reservation.value.id)
      emit('close')
    }
  })
}

function printTables(bookedTables: string[]) {
  return bookedTables.map(table => tables.value[table].name).toString()
}
</script>
