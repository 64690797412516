<template>
  <div
    class="h-8 -pb-[0.063rem] mb-[0.063rem] align-top font-body text-sm border-b border-n-300"
    :class="extendLine ? 'flex' : 'inline-flex'"
  >
    <div v-for="tab in tabs" :key="tab.id" ref="tabRefs" class="shrink-0">
      <l-tab
        v-bind="tab"
        :type="type"
        :selected="tab.id === model"
        @click="tabClicked(tab)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, useTemplateRef, watch } from 'vue'

import type { LIconName } from '@last/core-ui/paprika'
import { LTab } from '@last/core-ui/paprika'

export type Tab = {
  id: string | number
  name: string
  disabled?: boolean
  badge?: number
  icon?: LIconName
}

export type Props = {
  /**
   * Represents an array of Tab objects. Each Tab object within the array can hold
   * specific information related to a tab, such as title, content, or other properties
   * depending on the implementation of the Tab type.
   */
  tabs: Tab[]
  /**
   * Specifies the type for the component or element. Can be one of the following string literals:
   * - 'primary': Represents the primary variant.
   * - 'secondary': Represents the secondary variant.
   */
  type?: 'primary' | 'secondary'
  /**
   * A boolean flag that determines whether a line should be extended.
   * If set to `true`, the line will continue beyond its typical boundary or length.
   * If set to `false`, the line will retain its standard boundary or length.
   * This property is optional and may be undefined.
   */
  extendLine?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  extendLine: false
})

const model = defineModel<string | number>()

const tabRefs = useTemplateRef<HTMLElement[]>('tabRefs')

watch(model, () => {
  if (!model.value || !tabRefs.value) return
  const tabIndex = props.tabs.findIndex(t => t.id === model.value)
  if (tabIndex === -1) return

  tabRefs.value[tabIndex].scrollIntoView?.({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center'
  })
})

onMounted(() => {
  if (!model.value) {
    model.value = props.tabs[0].id
  }
})

function tabClicked(tab: Tab): void {
  model.value = tab.id
}
</script>
