<template>
  <l-modal
    :title="$t('new-delivery.tab-details')"
    :button-enabled="!isInvalidDeliveryForm"
    :button-text="isEdit ? $t('ctas.update') : $t('new-delivery.cta-create')"
    @action="
      isEdit
        ? updateTabAndUpdateUserIfNeeded()
        : createNewTabAndUpdateUserIfNeeded()
    "
    @close="$emit('close')"
  >
    <div class="flex gap-4">
      <l-field class="flex-1" :label="$t('new-delivery.pickup-date')">
        <l-date-picker
          v-model="internalTab.schedulingTime"
          :placeholder="$t('new-delivery.schedule-now')"
          :only-future="true"
          :show-time="false"
          @update:model-value="schedulingTimeEdited = true"
          :default-time="defaultTime"
        />
      </l-field>

      <l-field class="flex-1" :label="$t('new-delivery.pickup-time')">
        <l-time-picker
          v-model="internalTab.schedulingTime"
          picker-size="large"
          :placeholder="$t('new-delivery.schedule-now')"
          @update:model-value="schedulingTimeEdited = true"
          :default="defaultTime"
        />
      </l-field>
    </div>

    <div class="flex gap-4">
      <l-field class="flex-1" :label="$t('new-delivery.tab-name-label')">
        <l-input
          v-model="internalTab.name"
          :placeholder="$t('new-delivery.tab-name-placeholder')"
        />
      </l-field>
      <l-field
        v-if="!isEdit"
        class="flex-1"
        :label="$t('new-delivery.client-number-label')"
        :mandatory="seatsRequired"
      >
        <l-select
          v-model="internalTab.seats"
          :options="numberOfPeople"
          :wrong-value="dinersError"
          :allow-remove="!seatsRequired"
          :placeholder="$t('new-delivery.client-number-label')"
        />
      </l-field>
    </div>

    <l-field class="flex-1" :label="$t('new-delivery.customer-notes')">
      <l-input
        v-model="internalTab.customerNote"
        type="textarea"
        :max-length="255"
      />
    </l-field>
    <l-field class="flex-1" :label="$t('new-delivery.kitchen-notes')">
      <l-input
        v-model="internalTab.kitchenNote"
        type="textarea"
        :max-length="255"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { addMinutes, format, roundToNearestMinutes, subMinutes } from 'date-fns'
import { storeToRefs } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import {
  LDatePicker,
  LField,
  LInput,
  LModal,
  LSelect,
  LTimePicker
} from '@last/core-ui/paprika'

import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import sync from '@/sync/service'
import { Address, CustomerInfo, Tab } from '@/types'

const props = withDefaults(
  defineProps<{
    tab: any
    isEdit?: boolean
    customer: CustomerInfo
    address?: Partial<Address>
  }>(),
  {
    isEdit: false,
    address: undefined
  }
)
const emit = defineEmits(['close', 'create', 'update'])

onMounted(() => {
  internalTab.value = props.tab
})

const internalTab = ref<Partial<Tab>>({})
const dinersError = ref(false)
const schedulingTimeEdited = ref(false)

const { t } = useI18n()
const configStore = useConfigStore()
const tabsStore = useTabsStore()

const { config } = storeToRefs(configStore)
const { getTabPreparationMinutes } = storeToRefs(tabsStore)

const isInvalidDeliveryForm = computed(() => {
  return seatsRequired.value && !internalTab.value.seats
})

const isOwnDelivery = computed(() => {
  return internalTab.value.pickupType === 'ownDelivery'
})

const seatsRequired = computed(() => {
  return isOwnDelivery.value && !!config.value.organizationConfig.seatsRequired
})

const pickupTime = computed(() => {
  const preparationMinutes = getTabPreparationMinutes.value(
    internalTab.value.virtualBrandId
  )
  const asap = addMinutes(new Date(), preparationMinutes || 15)
  let pickupTime = internalTab.value.schedulingTime
    ? ['delivery', 'ownDelivery'].includes(internalTab.value.pickupType!)
      ? subMinutes(new Date(internalTab.value.schedulingTime), 15)
      : new Date(internalTab.value.schedulingTime)
    : asap
  if (asap > pickupTime) {
    pickupTime = asap
  }
  return pickupTime.toISOString()
})

const numberOfPeople = computed(() => {
  const numberOfPeople = []
  for (let i = 0; i < 20; i++) {
    numberOfPeople[i] = {
      label: `${i < 9 ? '0' : ''}${i + 1} ${t('new-delivery.client-number')}`,
      value: i + 1
    }
  }
  return numberOfPeople
})

const defaultTime = computed(() => {
  return format(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 'HH:mm')
})

function createNewTabAndUpdateUserIfNeeded() {
  if (isInvalidDeliveryForm.value) {
    checkDeliveryDetails()
    return
  }
  const internalDelivery = props.address ?? ({} as Address)
  const { addresses, points, ...infoCustomer } = props.customer
  let customer = { ...infoCustomer }
  const { address, details, postalCode } = internalDelivery

  if (!props.isEdit) customer.addresses = addresses

  customer.source = internalTab.value.source
  customer = {
    ...customer,
    address,
    addressDetails: details,
    postalCode
  }
  if (!customer.id) {
    customer.id = uuid()
  }

  internalTab.value.customerId = customer.id
  internalTab.value.name = internalTab.value.name || customer.name
  const tab = {
    ...internalTab.value,
    deliveryOrder: {
      ...internalTab.value.deliveryOrder,
      ...internalDelivery,
      addressDetails: internalDelivery.details,
      source: internalTab.value.source,
      status: 'CREATED',
      deliveryOrderStatuses: [
        {
          status: 'CREATED',
          creationTime: new Date()
        }
      ],
      pickupTime: pickupTime.value
    },
    customerInfo: props.customer
  }
  emit('create', {
    tableId: null,
    tab,
    customer
  })
}

function updateTabAndUpdateUserIfNeeded() {
  if (isInvalidDeliveryForm.value) {
    checkDeliveryDetails()
    return
  }
  const internalDelivery = props.address ?? ({} as Address)

  internalTab.value.customerInfo = props.customer
  internalTab.value.deliveryOrder = {
    ...internalTab.value.deliveryOrder,
    ...internalDelivery,
    addressDetails: internalDelivery.details
  }

  if (schedulingTimeEdited.value) {
    sync.record('deliveryOrderStatusUpdated', {
      tabId: internalTab.value.id,
      newStatus: 'CREATED',
      date: new Date()
    })
    internalTab.value = {
      ...internalTab.value,
      activationTime: null,
      deliveryOrder: {
        ...internalTab.value.deliveryOrder,
        pickupTime: pickupTime.value
      }
    }
  }

  emit('update', {
    tab: internalTab.value
  })
}

function checkDeliveryDetails() {
  dinersError.value = false
  if (seatsRequired.value && !internalTab.value.seats) {
    dinersError.value = false
  }
}
</script>
