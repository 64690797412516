import { RouteRecordRaw } from 'vue-router'

import AboutPopup from '@/components/AboutPopup.vue'
import EmployeeSelector from '@/components/EmployeeSelector.vue'
import HardwareSelector from '@/components/HardwareSelector/HardwareSelector.vue'
import DemoPos from '@/components/onboarding/DemoPos.vue'
import FreeTrialExpired from '@/components/onboarding/FreeTrialExpired.vue'
import GracePeriodExpired from '@/components/onboarding/GracePeriodExpired.vue'
import LocationSelector from '@/components/onboarding/LocationSelector.vue'
import Login from '@/components/onboarding/Login.vue'
import OverTheLimit from '@/components/onboarding/OverTheLimit.vue'
import LinkReservations from '@/components/reservations/LinkReservations.vue'
import Reservations from '@/components/reservations/Reservations.vue'
import EndShift from '@/components/shift/EndShift.vue'
import StartShift from '@/components/shift/StartShift.vue'
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import WrongTime from '@/components/WrongTime.vue'
import { useHome } from '@/composables/useHome'
import Checkout from '@/mobile/views/Checkout.vue'
import Home from '@/mobile/views/Home.vue'
import OrderManagement from '@/mobile/views/OrderManagement.vue'

export const mobileRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'employees' }
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'pos',
    path: '/pos',
    component: Home,
    redirect() {
      const { checkRedirect } = useHome()
      return checkRedirect()
    },
    children: [
      {
        name: 'floorplan',
        path: 'floorplan',
        component: TableTabs
      },
      {
        name: 'delivery',
        path: 'delivery',
        component: TabBrowser,
        props: {
          tabsType: 'delivery'
        }
      },
      {
        name: 'takeAway',
        path: 'takeAway',
        component: TabBrowser,
        props: {
          tabsType: 'takeAway'
        }
      },
      {
        name: 'tabs',
        path: 'tabs',
        component: TabBrowser,
        props: {
          tabsType: 'all'
        }
      },
      {
        name: 'fastMode',
        path: 'fast-mode/:tabId?',
        component: OrderManagement
      },
      {
        name: 'reservations',
        path: 'reservations',
        component: Reservations
      }
    ]
  },
  {
    name: 'linkReservations',
    path: '/reservations',
    component: LinkReservations
  },
  {
    name: 'demoPos',
    path: '/demo-pos',
    component: DemoPos
  },
  {
    name: 'locations',
    path: '/locations',
    component: LocationSelector
  },
  {
    name: 'hardwareSelector',
    path: '/hardware-selector',
    component: HardwareSelector
  },
  {
    name: 'employees',
    path: '/employees',
    component: EmployeeSelector
  },
  {
    name: 'startShift',
    path: '/start-shift',
    component: StartShift
  },
  {
    name: 'endShift',
    path: '/end-shift',
    component: EndShift
  },
  {
    name: 'orderManagement',
    path: '/pos/order-management/:tabId',
    component: OrderManagement
  },
  {
    name: 'fastCheckout',
    path: '/pos/order-management/:tabId/checkout',
    component: Checkout
  },
  {
    name: 'overTheLimit',
    path: '/over-the-limit',
    component: OverTheLimit
  },
  {
    name: 'freeTrialExpired',
    path: '/free-trial-expired',
    component: FreeTrialExpired
  },
  {
    name: 'gracePeriodExpired',
    path: '/grace-period-expired',
    component: GracePeriodExpired
  },
  {
    name: 'about',
    path: '/about',
    component: AboutPopup
  },
  {
    name: 'wrongTime',
    path: '/wrong-time',
    component: WrongTime
  }
]
