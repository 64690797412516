<template>
  <l-modal
    v-model:active-screen="currentStepIndex"
    :screens="screens"
    :button-enabled="selectedCompanyIsValid"
    :full-height="false"
    @action="performAction"
    @close="$emit('close')"
  >
    <template #0>
      <companies-list
        @modify="company => editCompany(company)"
        @select="company => selectCompany(company)"
      />
    </template>
    <template #1>
      <company-editor v-model="selectedCompany" />
    </template>
  </l-modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { taxIdIsValid } from '@last/core'
import { LModal } from '@last/core-ui/paprika'

import { useTabsStore } from '@/store/tabs'
import { type Company } from '@/types'

import CompaniesList from './CompaniesList.vue'
import CompanyEditor from './CompanyEditor.vue'

const { regenerateBill } = useTabsStore()
const { t } = useI18n()

const emit = defineEmits<{
  close: []
  newBillId: [string]
}>()

const props = defineProps<{
  tabId: string
  billId: string
}>()

type Screen = {
  name: string
  buttonText?: string
  secondaryButtonText?: string
  secondaryButton?: boolean
  sectionTitle?: string
  title?: string
  description?: string
}

const screens: Screen[] = [
  {
    name: '0',
    title: t('generate-invoice.title'),
    sectionTitle: t('generate-invoice.bills')
  },
  {
    name: '1',
    title: t('generate-invoice.new-company'),
    sectionTitle: t('generate-invoice.title'),
    buttonText: t('ctas.save')
  }
] as const

const currentStepIndex = ref(0)
const selectedCompany = ref<Company>()

const selectedCompanyIsValid = computed(() => {
  return !!(
    selectedCompany.value &&
    selectedCompany.value.taxId &&
    selectedCompany.value.name &&
    selectedCompany.value.address &&
    taxIdIsValid(selectedCompany.value.taxId)
  )
})

function selectCompany(company: Company) {
  selectedCompany.value = company
  generateInvoice()
}

function editCompany(company: Company) {
  selectedCompany.value = company
  currentStepIndex.value = 1
}

function performAction() {
  generateInvoice()
}

function generateInvoice() {
  const billId = regenerateBill({
    tabId: props.tabId,
    billId: props.billId,
    customerCompany: selectedCompany.value!
  })
  emit('newBillId', billId)
  emit('close')
}
</script>
