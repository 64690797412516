<template>
  <teleport v-if="isActive" to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <full-screen
        :title="
          currentTabsTables.length > 0
            ? $t('floorplan.move-account')
            : $t('tabs.assign-to-table')
        "
        @close="isActive = false"
      >
        <floor-plan-with-navigation
          class="h-full"
          :wiggle-enabled="true"
          :selected-tables="selectedTables"
          @table-selected="tableSelected"
        />
        <template #footer>
          <l-button :disabled="!isButtonActive" class="min-w-56" @click="save">
            {{
              currentTabsTables.length > 0
                ? $t('floorplan.move-to', {
                    currentTable: currentTablesNames.join(', '),
                    newTable:
                      selectedTableNames.length > 0
                        ? selectedTableNames.join(', ')
                        : '-'
                  })
                : $t('ctas.confirm')
            }}
          </l-button>
        </template>
      </full-screen>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

import { LButton } from '@last/core-ui/paprika'

import FullScreen from '@/components/core/FullScreen.vue'
import FloorPlanWithNavigation from '@/components/tables/FloorPlanWithNavigation.vue'
import { useTablesStore } from '@/store/tables'
import { useTabsStore } from '@/store/tabs'
import { Table } from '@/types'

const props = defineProps<{
  tabIds: string[]
}>()

const isActive = defineModel<boolean>('isActive')

const tabsStore = useTabsStore()
const tablesStore = useTablesStore()
const { tables } = storeToRefs(tablesStore)
const { tabs } = storeToRefs(tabsStore)
const { assignTables } = tabsStore

const selectedTables = ref<string[]>([])

const isButtonActive = computed(() => {
  return selectedTables.value.length > 0
})

const currentTabsTables = computed(() =>
  props.tabIds.map(tabId => tabs.value[tabId].tables).flat()
)

const currentTablesNames = computed(() =>
  currentTabsTables.value.map(tableId => tables.value[tableId].name)
)

const selectedTableNames = computed(() =>
  selectedTables.value.map(tableId => tables.value[tableId].name)
)

watch(isActive, value => {
  if (value) {
    selectedTables.value = []
  }
})

function save() {
  if (props.tabIds.length === 0 || selectedTables.value.length === 0) return
  props.tabIds.forEach(tabId => {
    assignTables({ tabId, tables: selectedTables.value })
  })
  isActive.value = false
}

function tableSelected(table: Table) {
  if (selectedTables.value.includes(table.id)) {
    selectedTables.value = selectedTables.value.filter(id => id !== table.id)
  } else {
    selectedTables.value = [...selectedTables.value, table.id]
  }
}
</script>
