import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'

import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { CatalogCombo, CatalogProduct, Category, Combo, Product } from '@/types'
import { isProductEnabled } from '@/utils/isProductEnabled'

export function useComboEditor(
  catalogCombo: CatalogCombo,
  tabId: string,
  editingCombo: Combo | null,
  seat: number | null
) {
  const tabsStore = useTabsStore()
  const configStore = useConfigStore()
  const { tabs } = storeToRefs(tabsStore)

  const selectedProducts = ref<{ [key: number]: { [key: string]: Product } }>(
    {}
  )
  const savedCatalogCombo = ref<{
    id: string
    name: string
    price: number
    pointsExpense: number
    categories: Category[]
  } | null>(null)
  const savedCombos = ref<Combo[]>([])
  const savedEditingCombo = ref<Combo | null>(null)
  const quantity = ref(1)
  const selectedSeat = ref<any>(null)

  const selectedCategoryIndex = ref(0)

  function preselectSelectedProducts() {
    selectedProducts.value[0] = editingCombo!.comboProducts.reduce(
      (res: { [key: string]: Product }, product: Product) => {
        const categoryProduct = catalogCombo.categories
          .flatMap(category => category.products)
          .find(categoryProduct => categoryProduct.id === product.parentProduct)
        res[product.id] = {
          ...product,
          categoryId: categoryProduct?.categoryId,
          modifierGroups: categoryProduct?.modifierGroups
        }
        return res
      },
      {}
    )
  }

  function deleteProduct(comboIndex: number, productId: string) {
    delete selectedProducts.value[comboIndex][productId]
    selectedProducts.value = {
      ...selectedProducts.value
    }
  }

  function getCategory(categoryIndex: number): Category | null {
    if (!savedCatalogCombo.value) {
      return null
    }
    return savedCatalogCombo.value.categories[categoryIndex]
  }

  function updateProductQuantity(
    productId: string,
    newQuantity: number,
    comboIndex: number,
    categoryIndex: number
  ) {
    if (newQuantity === 0) {
      deleteProduct(comboIndex, productId)
      return
    }
    const oldProductQuantity =
      selectedProducts.value[comboIndex][productId].quantity
    selectedProducts.value[comboIndex][productId].quantity = newQuantity

    const newTotalProductsQuantity = categorySelectedProductsQuantity(
      comboIndex,
      categoryIndex
    )
    if (newTotalProductsQuantity > categoryMax(categoryIndex)) {
      selectedProducts.value[comboIndex][productId].quantity =
        oldProductQuantity
    }
  }

  function saveCombo() {
    Object.values(selectedProducts.value).forEach(
      (comboSelectedProducts: { [key: string]: Product }, index: number) => {
        const saveCombo = savedCatalogCombo.value!
        let combo = {
          parentProduct: saveCombo.id,
          name: saveCombo.name,
          price: saveCombo.price,
          quantity: quantity.value,
          combine: savedCombos.value.length > 1,
          pointsExpense: saveCombo.pointsExpense,
          comboProducts: Object.values(comboSelectedProducts)
            .sort((a, b) => {
              return (
                catalogProductsPosition.value[a.parentProduct!] -
                catalogProductsPosition.value[b.parentProduct!]
              )
            })
            .map(comboProduct => {
              const { categoryId, modifierGroups, ...product } = comboProduct
              return product
            }),
          modifiers: [],
          seat: seat ?? selectedSeat.value
        }

        const productPricing =
          ProductPriceCalculator.calculateProductPricing(combo)
        if (savedEditingCombo.value && index == 0) {
          tabsStore.updateComboProducts({
            comboId: savedEditingCombo.value.id,
            products: combo.comboProducts,
            productPricing
          })
        } else {
          combo = {
            ...combo,
            ...productPricing
          }
          tabsStore.addProduct({
            tabId: tabId,
            seat: seat ?? selectedSeat.value,
            product: combo
          })
        }
      }
    )
  }

  function productTotalSelected(comboIndex: number, parentProductId: string) {
    if (!selectedProducts.value[comboIndex]) return 0
    const result = Object.values(selectedProducts.value[comboIndex])
      .filter(product => product.parentProduct === parentProductId)
      .reduce((total, product) => {
        total += product.quantity
        return total
      }, 0)
    return result
  }

  function categorySelectedProducts(comboIndex: number, categoryIndex: number) {
    if (!selectedProducts.value[comboIndex]) return []
    return Object.values(selectedProducts.value[comboIndex]).filter(
      product => product.categoryId === getCategory(categoryIndex)?.id
    )
  }

  function categorySelectedProductsQuantity(
    catalogIndex: number,
    categoryIndex: number
  ) {
    return categorySelectedProducts(catalogIndex, categoryIndex).reduce(
      (total, product) => {
        total += product.quantity
        return total
      },
      0
    )
  }

  function categoryMax(categoryIndex: number) {
    const category = getCategory(categoryIndex)
    if (!category) {
      return 0
    }
    return category.max
  }

  function categoryMin(categoryIndex: number) {
    const category = getCategory(categoryIndex)
    if (!category) {
      return 0
    }
    return category.min
  }

  function categoryIsMaxed(comboIndex: number, categoryIndex: number) {
    return (
      categorySelectedProductsQuantity(comboIndex, categoryIndex) >=
      categoryMax(categoryIndex)
    )
  }

  function allCategoriesReachesMin(comboIndex: number) {
    return !savedCatalogCombo.value?.categories.some(
      (_, categoryIndex) =>
        categorySelectedProductsQuantity(comboIndex, categoryIndex) <
        categoryMin(categoryIndex)
    )
  }

  function isAvailableOnSchedule(product: CatalogProduct) {
    const maybeSchedulingTime = tabs.value[tabId]?.schedulingTime
    return isProductEnabled(
      product,
      maybeSchedulingTime ? new Date(maybeSchedulingTime) : new Date(),
      configStore.config.workingTime?.timezone
    )
  }

  const products = computed(() => {
    return getCategory(selectedCategoryIndex.value)?.products
  })

  const filteredProducts = computed(() => {
    return (products.value || [])
      .filter(p => p.enabled)
      .filter(p => isAvailableOnSchedule(p))
  })

  const catalogProductsPosition = computed(() => {
    return savedCatalogCombo.value!.categories.reduce(
      (res: { [key: string]: number }, category: Category) => {
        category.products.forEach((product, index) => {
          res[product.id] = category.position * 100 + index
        })
        return res
      },
      {}
    )
  })

  return {
    selectedCategoryIndex,
    selectedProducts,
    editingCombo,
    savedCatalogCombo,
    savedCombos,
    savedEditingCombo,
    quantity,
    selectedSeat,
    products,
    filteredProducts,
    preselectSelectedProducts,
    deleteProduct,
    getCategory,
    updateProductQuantity,
    saveCombo,
    productTotalSelected,
    categorySelectedProducts,
    categorySelectedProductsQuantity,
    categoryMax,
    categoryMin,
    categoryIsMaxed,
    allCategoriesReachesMin,
    isAvailableOnSchedule,
    catalogProductsPosition
  }
}
