import { createSharedComposable } from '@vueuse/core'
import { ref } from 'vue'

import app from '@/app'
import { useConfigStore } from '@/store/config'
import { useTablesStore } from '@/store/tables'
import { useTillStore } from '@/store/till'

export const useHome = createSharedComposable(() => {
  const { config } = useConfigStore()
  const { floorplanIds } = useTablesStore()
  const { shiftsEnabled, selectedCashTillIsStarted } = useTillStore()

  const activeMenu = ref<string>()

  function checkRedirect() {
    if (shiftsEnabled && !selectedCashTillIsStarted) {
      return { name: 'employees' }
    } else if (activeMenu.value) {
      return { name: activeMenu.value }
    } else if (config.fastMode && config.fastModeHome && !app.isMobile) {
      activeMenu.value = 'fastMode'
      return {
        name: 'fastMode'
      }
    } else if (floorplanIds.length > 0) {
      activeMenu.value = 'floorplan'
      return { name: 'floorplan' }
    } else {
      activeMenu.value = 'delivery'
      return { name: 'delivery' }
    }
  }

  return {
    activeMenu,
    checkRedirect
  }
})
