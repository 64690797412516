import api from '@/api/fetch'
import { Employee } from '@/types'

export function loginSupport(accessToken: string) {
  return api
    .get<boolean>(`/users/token/${accessToken}/checkSupport`)
    .then(res => res.data)
}

export function verificationCode(email: string) {
  return api
    .post<string>('/users/verification-code', { email })
    .then(res => res.data)
}

export function checkVerificationCode(id: string, code: string) {
  return api
    .get<{ result: boolean }>('/users/verification-code/validate', {
      id,
      code
    })
    .then(res => res.data)
}

export function requestResetPassword(id: string, email: string) {
  return api.put('/users/reset-password', { id, email }).then(res => res.data)
}

export function getEmployees() {
  return api.get<Employee[]>('/employees').then(res => res.data)
}

export function getBillingStatus() {
  return api.get('/billing/status').then(res => res.data)
}

export function getDemoAuth() {
  return api.get('/demoAuthData').then(res => res.data)
}
