<template>
  <div class="flex flex-col gap-2">
    <slot
      v-bind="{
        errorMessage: errorMessage,
        field: {
          name: name,
          wrongValue: !!errorMessage,
          modelValue: value,
          'onUpdate:modelValue': (v: any) => (value = v),
          onBlur: (e?: Event) => handleBlur(e)
        }
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import { provide } from 'vue'

interface Props {
  name: string
  initialValue?: string | number
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  description: undefined,
  mandatory: false,
  initialValue: undefined
})

const { value, errorMessage, name, handleBlur, handleChange } = useField<any>(
  () => props.name,
  undefined,
  {
    syncVModel: true,
    keepValueOnUnmount: false,
    initialValue: props.initialValue
  }
)

provide('field', { value, errorMessage, name, handleBlur, handleChange })
</script>
