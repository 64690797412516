<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 font-body text-3 transition bg-n-900/80 backdrop-blur-sm absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden dark"
        @click="$emit('close')"
      >
        <div
          :class="{ 'translate-x-0': open, 'translate-x-80': !open }"
          class="transition menu bg-n-800 text-n-0 w-80 h-full flex flex-col"
          @click.stop
        >
          <div class="relative bg-n-900 flex flex-col">
            <div class="flex justify-end px-2 py-1">
              <l-button
                size="small"
                icon="close"
                type="text"
                color="white"
                @click="$emit('close')"
              />
            </div>
            <div class="flex items-center justify-between px-5 mb-3">
              <div>
                <div class="mb-2 font-heading text-4">
                  {{ currentEmployee?.name }}
                </div>
                <router-link v-slot="{ navigate }" :to="{ name: 'employees' }">
                  <button
                    class="text-v-300"
                    @click="
                      () => {
                        emit('close')
                        navigate
                      }
                    "
                  >
                    {{ t('sidemenu.change-employee') }}
                  </button>
                </router-link>
              </div>
              <div class="">
                <div
                  class="transition-colors duration-1000 rounded-full flex items-center justify-center w-8 h-8 cursor-pointer"
                  :class="{ 'bg-n-800': !mute, 'bg-v-300': mute }"
                >
                  <div class="w-6 h-6">
                    <l-lottie
                      ref="volumeAnim"
                      width="16"
                      height="16"
                      :animation-data="VolumeAnimation"
                      :loop="false"
                      :auto-play="false"
                      @click="() => changeMute()"
                      @on-animation-loaded="handleAnimationLoaded"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 flex flex-col px-5 overflow-hidden">
            <div
              v-if="shipmentProvidersEnabled.length > 0"
              class="border-b border-n-700 flex-none mt-5"
            >
              <l-option-selector
                v-if="shouldShowManualShipmentSwitch"
                v-model="isManualShipment"
                :options="shipmentOptions"
                class="w-full mb-4"
              />
              <div v-if="shipmentProvidersEnabled.length > 1" class="mb-5">
                <l-select
                  v-model="selectedShipmentProvider"
                  :options="shipmentProvidersEnabled"
                />
              </div>
              <div
                v-if="shouldShowOwnFleetSwitch"
                class="flex justify-between items-center mb-5"
              >
                <div>{{ t('sidemenu.use-own-fleet') }}</div>
                <l-switch
                  :model-value="ownFleetEnabled"
                  @update:model-value="updateOwnFleet"
                />
              </div>
            </div>
            <div class="overflow-y-scroll flex-1">
              <div
                v-if="startedShifts.length > 0"
                class="flex items-center cursor-pointer py-3"
                @click="endShift()"
              >
                <l-icon name="inactive-2" class="mr-2" />
                {{ t('sidemenu.end-shift') }}
              </div>
              <div
                v-if="hasDeliveries"
                class="py-3 flex items-center border-b border-n-700 cursor-pointer"
                @click="closeDelivery()"
              >
                <l-icon name="store-closed" class="mr-2" />
                {{ t('sidemenu.close-delivery') }}
              </div>
              <router-link
                :to="{ name: 'hardwareSelector' }"
                class="py-3 flex items-center cursor-pointer"
                @click="$emit('close')"
              >
                <l-icon name="printer" class="mr-2" />
                {{ $t('sidemenu.hardware-management') }}
              </router-link>
              <div
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('REAL_TIME_REPORTER', 'realTimeReport')"
              >
                <l-icon name="columns" class="mr-2" />
                {{ t('sidemenu.real-time-report') }}
              </div>
              <router-link
                v-if="Object.keys(couriers).length > 0"
                :to="{ name: 'couriersReport' }"
                class="py-3 flex items-center cursor-pointer"
                @click="$emit('close')"
              >
                <l-icon name="bike" class="mr-2 e" />
                {{ t('sidemenu.couriers-report') }}
              </router-link>
              <div
                v-if="selectedCashTill || currentEmployee?.tillId"
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('TILL_MANAGER', 'payInPayOut')"
              >
                <l-icon name="double-arrow-sides" class="mr-2" />
                {{ t('sidemenu.pay-in-pay-out') }}
              </div>
              <div
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('PRODUCT_MANAGER', 'products')"
              >
                <l-icon name="product" class="mr-2" />
                {{ t('sidemenu.products') }}
              </div>
              <div
                v-if="showBackofficeOption"
                class="py-3 flex items-center cursor-pointer"
                @click="openBackoffice()"
              >
                <l-icon name="cash" class="mr-2" />
                {{ t('sidemenu.open-cashlogy') }}
              </div>
              <div
                v-if="showMinimizeAndClose"
                class="py-3 gap-3 flex flex-col border-t border-n-700"
              >
                <div
                  class="flex items-center cursor-pointer"
                  @click="minimizeApp()"
                >
                  <l-icon name="minus" class="mr-2" />
                  {{ t('sidemenu.minimize-screen') }}
                </div>
                <div
                  class="flex items-center cursor-pointer"
                  @click="closeApp()"
                >
                  <l-icon name="power" class="mr-2" />
                  {{ t('sidemenu.close-application') }}
                </div>
              </div>
            </div>
            <div class="flex-none flex-col w-full mb-2 bg-n-800">
              <l-button
                class="mb-2 w-full"
                size="small"
                @click="openCashDrawer"
                >{{ t('topbar.open-register') }}</l-button
              >
              <div
                class="flex flex-row items-center justify-center"
                @click="openAbout()"
              >
                <div class="text-xs">
                  {{ t('sidemenu.version') }}: {{ appVersion }}
                </div>
                <l-icon class="px-1" name="question-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, useTemplateRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import {
  LButton,
  LIcon,
  LLottie,
  LOptionSelector,
  LSelect,
  LSwitch,
  useDialog
} from '@last/core-ui/paprika'
import VolumeAnimation from '@last/core-ui/paprika/assets/lottie/volume.json'

import app from '@/app'
import { useNotifications } from '@/composables/useNotifications'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import Printer from '@/integrations/printer/printer'
import { managerConfirmation } from '@/plugins/managerConfirmation'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { useCouriersStore } from '@/store/couriers'
import { useDeliveryCompaniesStore } from '@/store/deliveryCompanies'
import { useTabsStore } from '@/store/tabs'
import { useTillStore } from '@/store/till'

type Props = {
  open: boolean
}

defineProps<Props>()

const emit = defineEmits(['close'])
const router = useRouter()
const dialog = useDialog()

const authStore = useAuthStore()
const configStore = useConfigStore()
const tabsStore = useTabsStore()
const couriersStore = useCouriersStore()
const tillStore = useTillStore()
const deliveryStore = useDeliveryCompaniesStore()

const { currentEmployee, listEmployees } = storeToRefs(authStore)
const { tills, shipmentProviders, config, mute, device, appVersion } =
  storeToRefs(configStore)
const { toggleMute, updateShipmentProvider, updateManualShipment } = configStore
const { tabs } = storeToRefs(tabsStore)
const { couriers } = storeToRefs(couriersStore)
const { selectedCashTill, startedShifts } = storeToRefs(tillStore)
const { notifyInfo } = useNotifications()
const { hasDeliveries } = storeToRefs(deliveryStore)

const { t } = useI18n()

const volumeAnim = useTemplateRef('volumeAnim')

function handleAnimationLoaded(): void {
  if (mute.value) {
    volumeAnim.value?.goToAndStop(29, true)
  }
}

function changeMute() {
  toggleMute()
  if (mute.value) {
    volumeAnim.value?.playSegments([0, 30], true)
  } else {
    volumeAnim.value?.playSegments([30, 0], true)
  }
}

const showBackofficeOption = computed(() => CashMachine.method === 'cashlogy')
const showMinimizeAndClose = computed(
  () => device.value?.platform === 'electron'
)

const shipmentOptions = [
  { label: t('sidemenu.automatic-shipment'), value: false },
  { label: t('sidemenu.manual-shipment'), value: true }
]

const shipmentProvidersEnabled = computed<{ label: string; value: string }[]>(
  () => {
    if (!shipmentProviders.value || !shipmentProviders.value.providers)
      return []
    return shipmentProviders.value.providers.reduce(
      (res: { label: string; value: string }[], provider) => {
        if (Object.keys(provider).length > 1) {
          res.push({ label: provider.name, value: provider.name })
        }
        return res
      },
      []
    )
  }
)

const selectedShipmentProvider = computed({
  get: () => {
    if (!shipmentProviders.value) return null
    return shipmentProviders.value.selected
  },
  set: (value: string | null) =>
    updateShipmentProvider({
      provider: value,
      ownFleetEnabled: ownFleetEnabled.value
    })
})

const shouldShowManualShipmentSwitch = computed<boolean>(() => {
  return (
    !!selectedShipmentProvider.value &&
    config.value.organizationConfig.showManualShipment
  )
})

const stuartProvider = computed(() => {
  if (!shipmentProviders.value || !shipmentProviders.value.providers)
    return null
  return shipmentProviders.value.providers.find(
    p => p.name.toUpperCase() === 'STUART'
  )
})

const ownFleetAllowed = computed<boolean>(() => {
  if (!stuartProvider.value) return false
  return !!stuartProvider.value.config?.metadata?.fleetId
})

const shouldShowOwnFleetSwitch = computed<boolean>(() => {
  return (
    selectedShipmentProvider.value?.toUpperCase() === 'STUART' &&
    ownFleetAllowed.value
  )
})

const ownFleetEnabled = computed<boolean>(() => {
  if (!stuartProvider.value) return false
  return !!stuartProvider.value.config?.metadata?.ownFleetEnabled
})

const isManualShipment = computed({
  get: () => !!config.value.manualShipment,
  set: (value: boolean) => updateManualShipment(value)
})

function updateOwnFleet(ownFleetEnabled: boolean) {
  updateShipmentProvider({
    provider: selectedShipmentProvider.value,
    ownFleetEnabled: ownFleetEnabled
  })
}

async function closeDelivery() {
  if (await performActionAskPinIfNeeded('DELIVERY_MANAGER')) {
    emit('close')
    router.push({ name: 'closeDelivery' })
  }
}

async function routerPrompt(privilege: string, route: string) {
  if (await performActionAskPinIfNeeded(privilege)) {
    router.push({ name: route })
  }
}

function openAbout() {
  router.push({ name: 'about' })
}

async function performActionAskPinIfNeeded(privilege: string) {
  const hasPrivilege = await managerConfirmation(privilege)
  if (hasPrivilege) {
    return true
  } else {
    emit('close')
    return false
  }
}

const employeeTills = computed(() => {
  return listEmployees.value
    .filter(employee => !!employee.tillId && employee.tillEnabled)
    .map(employee => employee.tillId!)
})

async function endShift() {
  if (!(await performActionAskPinIfNeeded('SHIFT_MANAGER'))) return
  const openTabs =
    Object.values(tabs.value).filter(tab => tab.open && !!tab.activationTime)
      .length > 0
  const cashTillIds: string[] = [
    ...tills.value.cash.map(till => till.id),
    ...employeeTills.value
  ]
  const cashShifts: string[] = startedShifts.value.filter(tillId =>
    cashTillIds.includes(tillId)
  )
  if (openTabs && cashShifts.length > 1) {
    dialog({
      title: t('open-tabs-dialog.title'),
      content: t('open-tabs-dialog.message'),
      onConfirm: () => {
        router.push({ name: 'endShift' })
      }
    })
  } else if (openTabs) {
    notifyInfo({
      title: t('open-tabs-dialog.title')
    })
  } else {
    router.push({ name: 'endShift' })
  }
  emit('close')
}

async function openBackoffice() {
  if (!(await performActionAskPinIfNeeded('TILL_MANAGER'))) return
  await CashMachine.openBackoffice()
}

async function openCashDrawer() {
  if (await performActionAskPinIfNeeded('CASH_DRAWER')) {
    Printer.openCashDrawer()
  }
}

function minimizeApp() {
  app.minimize()
}

async function closeApp() {
  if (CashMachine.isEnabled()) {
    await CashMachine.close()
  }
  app.quit()
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.transition {
  transition: all 0.3s;
}
</style>
