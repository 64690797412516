/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequestOneOf1
 */
export interface LoginRequestOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOf1
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOf1
     */
    password: string;
}

/**
 * Check if a given object implements the LoginRequestOneOf1 interface.
 */
export function instanceOfLoginRequestOneOf1(value: object): value is LoginRequestOneOf1 {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function LoginRequestOneOf1FromJSON(json: any): LoginRequestOneOf1 {
    return LoginRequestOneOf1FromJSONTyped(json, false);
}

export function LoginRequestOneOf1FromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequestOneOf1 {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function LoginRequestOneOf1ToJSON(json: any): LoginRequestOneOf1 {
    return LoginRequestOneOf1ToJSONTyped(json, false);
}

export function LoginRequestOneOf1ToJSONTyped(value?: LoginRequestOneOf1 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

