<template>
  <div class="flex flex-col w-screen h-full overflow-hidden">
    <top-bar v-if="!showingFloorPlan" show-back @back="$emit('close')">
      <template #center>
        <div class="text-xl flex items-center">
          {{ title }}
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full overflow-hidden">
      <div
        v-if="!showingFloorPlan"
        class="flex flex-col gap-4 px-4 justify-center items-center text-center flex-1 w-0 bg-n-800"
      >
        <img src="@/assets/select-product.svg" class="w-40 h-40" />
        <div class="font-heading text-2xl text-n-0 font-bold">
          {{ $t('ordering.move-products-title') }}
        </div>
        <div class="font-body text-base text-n-0">
          {{ $t('ordering.move-products-description') }}
        </div>
      </div>
      <div
        v-if="!showingFloorPlan"
        class="w-80 flex flex-col px-4 pt-6 pb-3 h-full bg-n-700"
      >
        <div class="flex flex-col gap-3 overflow-y-auto flex-1">
          <div v-for="(seat, index) in tabSeats" :key="index">
            <div
              v-if="seat.length"
              class="font-body text-n-0 border-b border-n-700"
            >
              <div class="cursor-pointer" @click="selectProducts(index, seat)">
                <div v-if="index === 0">
                  {{ $t('ordering.shared') }}
                </div>
                <div v-else>{{ $t('ordering.seat') }} {{ index }}</div>
              </div>
              <div
                v-for="product in seat"
                :key="product.uniqueId"
                class="flex items-center"
              >
                <l-checkbox
                  :class="{ 'opacity-50': product.notBilledQuantity === 0 }"
                  theme="dark"
                  :model-value="
                    selectedProducts.some(
                      selectedProduct => selectedProduct.id === product.id
                    )
                  "
                  @update:model-value="value => selectProduct(product, value)"
                />
                <product-component
                  class="w-full"
                  :product="product"
                  :show-price="false"
                />
              </div>
            </div>
          </div>
        </div>
        <l-button
          class="w-full shrink-0"
          :disabled="selectedProducts.length === 0"
          @click="showingFloorPlan = true"
          >{{
            $t('ordering.move-products-cta', {
              productsNumber: selectedProducts.length
            })
          }}
        </l-button>
      </div>
      <table-selector
        v-model:is-active="showingFloorPlan"
        :title="title"
        :blocked-tables="blockedTables"
        @close="showingFloorPlan = false"
        @table-selected="tableId => selectTable(tableId)"
      />
      <tab-selector
        v-if="showTabSelector"
        :tab-ids-to-select="toTable.tabIds"
        @tab-selected="tabSelected"
        @close="showTabSelector = false"
      />
      <move-products-confirmation-modal
        v-if="openConfirmationModal"
        :to-table="toTable"
        :to-tab="toTab"
        :from-tab="tabs[fromTabId]"
        :products="selectedProducts"
        @confirm="moveProducts"
        @close="handleCloseModal"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { LButton, LCheckbox } from '@last/core-ui/paprika'

import ProductComponent from '@/components/core/Product.vue'
import TopBar from '@/components/core/TopBar.vue'
import MoveProductsConfirmationModal from '@/components/ordering/MoveProductsConfirmationModal.vue'
import TableSelector from '@/components/tables/TableSelector.vue'
import TabSelector from '@/components/tables/TabSelector.vue'
import { useKitchenOrders } from '@/composables/useKitchenOrders'
import { useTabs } from '@/composables/useTabs'
import { useReservationsStore } from '@/store/reservations'
import { useTablesStore } from '@/store/tables'
import { useTabsStore } from '@/store/tabs'

const props = defineProps<{
  fromTabId: string
}>()

const emit = defineEmits(['productsMoved', 'close'])

const { t } = useI18n()

const tablesStore = useTablesStore()
const tabsStore = useTabsStore()
const reservationsStore = useReservationsStore()

const { searchReservation } = storeToRefs(reservationsStore)
const { moveProductTab, openTab, startBilling } = tabsStore
const { tabs, getSentToKitchenTime } = storeToRefs(tabsStore)

const { seatProducts, sharedProducts } = useTabs(props.fromTabId)
const { sendKitchenOrderModifications } = useKitchenOrders(props.fromTabId)
const { allTables } = storeToRefs(tablesStore)

const selectedSeat = ref<number | null>(null)
const selectedProducts = ref<any[]>([])
const showingFloorPlan = ref(false)
const fromTable = ref<{ id: string | null }>({ id: null })
const toTable = ref<any>(null)
const toTab = ref<any>(null)
const showTabSelector = ref(false)
const tableWithoutTabs = ref(false)
const tabId = ref<string | null>(null)

const title = computed(() => t('ordering.move-products'))
const tabSeats = computed(() => [
  tabSharedProducts.value,
  ...seatProducts.value
])
const tabSharedProducts = computed(() => sharedProducts.value)
const openConfirmationModal = computed(
  () => fromTable.value && (toTab.value || tableWithoutTabs.value)
)
const blockedTables = computed(() =>
  allTables.value
    .filter(
      table =>
        table.id === fromTable.value.id ||
        !!searchReservation.value(table.id, new Date())
    )
    .map(table => table.id)
)

onMounted(() => {
  fromTable.value = allTables.value.find(table =>
    table.tabIds.includes(props.fromTabId)
  ) || { id: null }
})

function handleCloseModal() {
  toTab.value = null
  tableWithoutTabs.value = false
}

function tabSelected(tabId: string) {
  toTab.value = tabs.value[tabId]
}

function selectProduct(product: any, value: boolean) {
  if (product.notBilledQuantity === 0) return
  if (value) selectedProducts.value.push(product)
  else
    selectedProducts.value.splice(
      selectedProducts.value.findIndex(
        selectedProduct => selectedProduct.id === product.id
      ),
      1
    )
}

function selectProducts(index: number, seat: any[]) {
  if (selectedSeat.value !== index) {
    selectedSeat.value = index
    selectedProducts.value = [
      ...seat.filter(product => product.notBilledQuantity),
      ...selectedProducts.value.filter(
        product => !seat.some(seatProduct => seatProduct.id === product.id)
      )
    ]
  } else {
    selectedSeat.value = null
    selectedProducts.value = selectedProducts.value.filter(
      product => !seat.some(seatProduct => seatProduct.id === product.id)
    )
  }
}

function selectTable(selectedTableId: string): void {
  toTable.value =
    allTables.value.find(table => table.id === selectedTableId) || null
  if (toTable.value?.tabIds?.length > 1) {
    showTabSelector.value = true
  } else if (toTable.value?.tabIds?.length === 1) {
    toTab.value = tabs.value[toTable.value.tabIds[0]]
  } else {
    tableWithoutTabs.value = true
  }
}

function getTabId() {
  if (toTab.value) return toTab.value.id
  else {
    const toTabId = openTab({ tableId: toTable.value.id, tab: {} })
    return toTabId
  }
}

async function moveProducts() {
  const toTabId = await getTabId()

  const alreadyOrderedProducts = selectedProducts.value.filter(
    product => !!getSentToKitchenTime.value(product.id)
  )

  selectedProducts.value.map(product => {
    moveProductTab({
      productId: product.id,
      fromTabId: props.fromTabId,
      toTabId: toTabId
    })
  })

  tabId.value = props.fromTabId
  sendKitchenOrderModifications(false)

  tabId.value = toTabId
  const { sendKitchenOrderByProducts } = useKitchenOrders(toTabId)
  sendKitchenOrderByProducts(alreadyOrderedProducts, false)

  emit('productsMoved', toTabId)
  // Start billing destination tab if origin tab already has some bill to avoid fraud
  if (
    !tabs.value[toTabId].billingStartedTime &&
    tabs.value[props.fromTabId].billingStartedTime
  ) {
    startBilling(toTabId)
  }
}
</script>
