import { Capacitor } from '@capacitor/core'
import { datadogRum } from '@datadog/browser-rum'
import type { Router } from 'vue-router'

import logger from './logger'

interface RUMLocation {
  id: string
  name: string
}

interface RUM {
  identify(location: RUMLocation): void
  setRouter(router: Router): void
  addError(error: unknown): void
}

class DatadogRUM implements RUM {
  constructor() {
    if (
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN &&
      Capacitor.getPlatform() !== 'web'
    ) {
      const appVersion = import.meta.env.VITE_APP_VERSION || 'unknown'
      try {
        datadogRum.init({
          applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
          clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
          site: 'datadoghq.com',
          service: 'pos',
          env: 'prod',
          version: appVersion,
          allowedTracingUrls: [import.meta.env.VITE_APP_BASE_URL],
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: false,
          trackViewsManually: true,
          defaultPrivacyLevel: 'mask-user-input',
          allowFallbackToLocalStorage: true,
          beforeSend: event => {
            if (event.type === 'error' && event.error.type === 'NetworkError') {
              return false
            }
            return true
          }
        })
      } catch {
        logger.error('Failed to initialize Datadog RUM')
      }
    }
  }

  identify(location: RUMLocation) {
    datadogRum.setUser({
      id: location.id,
      name: location.name
    })
  }

  setRouter(router: Router) {
    router.afterEach(to => {
      datadogRum.startView({ name: to.name?.toString() })
    })
  }

  addError(error: unknown) {
    datadogRum.addError(error)
  }
}

const rum: RUM = new DatadogRUM()

export default rum
