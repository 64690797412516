import { sleep } from '@last/core'

import HttpFactory from '@/drivers/http'

class Cashdro {
  constructor(ip, name, password, inputAmountListener, totalAmountListener) {
    this.ip = ip
    this.name = name
    this.password = password
    this.inputAmountListener = inputAmountListener
    this.totalAmountListener = totalAmountListener
    this.http = HttpFactory.getHttp()
    this.protocol = 'https'
  }

  // just a dumb operation to check if https protocol is working on the cashdro machine
  async setHttpProtocol() {
    try {
      let response = await this.http.get(
        `https://${this.ip}/Cashdro3WS/index.php`,
        undefined,
        {
          operation: 'getAlerts'
        }
      )
      if (response.data.code !== 1) {
        throw new Error("Can't check Cashdro alerts")
      }
      this.protocol = 'https'
    } catch {
      this.protocol = 'http'
    }
  }

  async startOperation(amount, type) {
    let response = await this.http.get(
      `${this.protocol}://${this.ip}/Cashdro3WS/index.php`,
      undefined,
      {
        operation: 'startOperation',
        name: this.name,
        password: this.password,
        type,
        posid: 'lastpos',
        posuser: 'lastpos',
        parameters: JSON.stringify({ amount })
      }
    )
    if (response.data.code !== 1) {
      throw new Error(
        `Cashdro error: [${response.data.code}] ${response.data.data}`
      )
    }
    return response.data.data
  }

  async runOperation(operationId) {
    let response = await this.http.get(
      `${this.protocol}://${this.ip}/Cashdro3WS/index.php`,
      undefined,
      {
        operation: 'acknowledgeOperationId',
        name: this.name,
        password: this.password,
        operationId
      }
    )
    if (response.data.code !== 1) {
      throw new Error(
        `Cashdro error: [${response.data.code}] ${response.data.data}`
      )
    }
    return response.data.data
  }

  async checkOperation(operationId) {
    let response = await this.http.get(
      `${this.protocol}://${this.ip}/Cashdro3WS/index.php`,
      undefined,
      {
        operation: 'askOperation',
        name: this.name,
        password: this.password,
        operationId
      }
    )
    let data = JSON.parse(response.data.data)
    this.inputAmountListener(parseInt(data.operation.totalin))
    return {
      operationFinished: data.operation.state === 'F',
      total:
        parseInt(data.operation.totalin) + parseInt(data.operation.totalout)
    }
  }

  async cancelOperation(operationId) {
    let cancelType = 3
    await this.http.get(
      `${this.protocol}://${this.ip}/Cashdro3WS/index.php`,
      undefined,
      {
        operation: 'finishOperation',
        name: this.name,
        password: this.password,
        operationId,
        type: cancelType
      }
    )
  }

  async importOperation(operationId) {
    let response = await this.http.get(
      `${this.protocol}://${this.ip}/Cashdro3WS/index.php`,
      undefined,
      {
        operation: 'setOperationImported',
        name: this.name,
        password: this.password,
        operationId
      }
    )
    if (response.data.code !== 1) {
      throw new Error(
        `Cashdro error: [${response.data.code}] ${response.data.data}`
      )
    }
  }
}

let cashdro = null
const operations = {
  CHARGE: 4,
  PAY: 3
}

function init({
  ip,
  name,
  password,
  inputAmountListener,
  totalAmountListener
}) {
  if (ip && name && password) {
    cashdro = new Cashdro(
      ip,
      name,
      password,
      inputAmountListener,
      totalAmountListener
    )
    cashdro.setHttpProtocol()
  }
}

let currentOperationId = null

async function runOperation(amount, type) {
  if (!cashdro) {
    throw new Error('Cashdro not initialized')
  }
  let operationId = await cashdro.startOperation(amount, type)
  currentOperationId = operationId
  await cashdro.runOperation(operationId)
  let finished = false
  let operationAmount = 0
  while (!finished) {
    let { operationFinished, total } = await cashdro.checkOperation(operationId)
    operationAmount = total
    finished = operationFinished
    await sleep(500)
  }
  await cashdro.importOperation(operationId)
  return operationAmount
}

async function charge(amount) {
  return await runOperation(amount, operations.CHARGE)
}

async function payOut(amount) {
  return await runOperation(amount, operations.PAY)
}

async function payIn(amount) {
  return await runOperation(amount, operations.CHARGE)
}

async function cancel() {
  await cashdro.cancelOperation(currentOperationId)
}

export default { init, charge, payOut, payIn, cancel }
