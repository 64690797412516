import { addMinutes, isWithinInterval, subHours, subMinutes } from 'date-fns'
import { normalize, schema } from 'normalizr'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

import sync from '@/sync/service'
import { Reservation, Reservations } from '@/types'

import { useConfigStore } from './config'

const reservationSchema = new schema.Entity('reservations')

export const useReservationsStore = defineStore(
  'reservations',
  () => {
    const config = useConfigStore()

    const reservations = ref<Reservations>({})

    const nearReservations = computed(() => {
      return Object.values(reservations.value).filter(reservation => {
        return isWithinInterval(new Date(reservation.dateTime), {
          start: subMinutes(new Date(), 240),
          end: addMinutes(new Date(), 240)
        })
      })
    })

    const sortedReservations = computed(() => {
      return Object.values(reservations.value)
        .sort(
          (a, b) =>
            new Date(a.dateTime).valueOf() - new Date(b.dateTime).valueOf()
        )
        .filter(
          reservation =>
            new Date(reservation.dateTime).valueOf() >
              subHours(new Date(), 2).getTime() && !reservation.cancelled
        )
    })

    const searchReservation = computed(() => (tableId: string, date?: Date) => {
      return Object.values(reservations.value).find(reservation => {
        const inRange = isWithinInterval(new Date(reservation.dateTime), {
          start: subMinutes(date ?? new Date(), 15),
          end: addMinutes(date ?? new Date(), config.reservations.duration)
        })
        const hasTab = reservation.tabId
        const rightTable = reservation.tables?.includes(tableId)

        return !hasTab && rightTable && inRange
      })
    })

    function createReservation(reservation: Reservation): void {
      sync.record('reservationCreated', { ...reservation, source: 'pos' })
    }

    function editReservation(reservation: Reservation): void {
      sync.record('reservationChanged', reservation)
    }

    function cancelReservation(reservationId: string): void {
      sync.record('reservationCancelled', { reservationId })
    }

    function refreshReservations(inputReservations: Reservations): void {
      const entities = normalize(inputReservations, [
        reservationSchema
      ]).entities
      reservations.value = entities.reservations || {}
    }

    return {
      reservations,
      nearReservations,
      sortedReservations,
      searchReservation,
      createReservation,
      editReservation,
      cancelReservation,
      refreshReservations
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReservationsStore, import.meta.hot))
}
