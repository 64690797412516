<template>
  <div v-if="internalReservation">
    <table-selector
      v-model:is-active="selectingTable"
      :title="$t('reservations.title')"
      :button-text="$t('reservations.accept')"
      :blocked-tables="blockedTables"
      :multiple-select="true"
      :selected-tables="internalReservation.tables"
      @close="selectingTable = false"
      @tables-selected="selectTables"
    />
    <reservation-details
      v-model="internalReservation"
      :open="reservationDetailsOpen"
      @close="$emit('close')"
      @select-table="selectingTable = true"
    />
  </div>
</template>

<script setup lang="ts">
import { addMinutes, isWithinInterval, subMinutes } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import ReservationDetails from '@/components/reservations/ReservationForm.vue'
import TableSelector from '@/components/tables/TableSelector.vue'
import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { useTabsStore } from '@/store/tabs'
import { Reservation } from '@/types'

const props = defineProps<{
  reservation: Reservation | null
}>()

defineEmits<{
  close: []
}>()

const route = useRoute()
const configStore = useConfigStore()
const reservationsStore = useReservationsStore()
const tabsStore = useTabsStore()
const { tabs } = storeToRefs(tabsStore)

const { sortedReservations } = storeToRefs(reservationsStore)

const internalReservation = ref<Partial<Reservation>>({})
const selectingTable = ref(false)

const reservationDetailsOpen = computed(() => {
  return !!internalReservation.value && !selectingTable.value
})

onMounted(() => {
  if (route.query.showSelector) {
    nextTick(() => {
      selectingTable.value = true
    })
  }
  internalReservation.value = { ...props.reservation } as Reservation
})

watch(
  () => internalReservation.value.dateTime,
  () => {
    const tables = internalReservation.value.tables
    internalReservation.value.tables = tables?.filter(
      table => !blockedTables.value.includes(table)
    )
  }
)

const blockedTables = computed(() => {
  if (!internalReservation.value) return []

  const date = internalReservation.value.dateTime
    ? new Date(internalReservation.value.dateTime)
    : new Date()
  const start = subMinutes(date, configStore.config.reservations.duration)
  const end = addMinutes(date, configStore.config.reservations.duration - 1)

  const bookedTables = Object.values(sortedReservations.value)
    .filter(reservation => {
      const inRange = isWithinInterval(new Date(reservation.dateTime), {
        start,
        end
      })
      const current = reservation.id === internalReservation.value.id
      return inRange && !current && !reservation.tabId
    })
    .flatMap(reservation => reservation.tables ?? [])

  const currentTabsTables = Object.values(tabs.value)
    .filter(
      tab =>
        tab.open &&
        isWithinInterval(new Date(tab.creationTime), {
          start,
          end
        })
    )
    .flatMap(tab => tab.tables)

  return [...bookedTables, ...currentTabsTables]
})

function selectTables(tables: string[]) {
  if (!internalReservation.value) return
  internalReservation.value.tables = tables
  selectingTable.value = false
}
</script>
