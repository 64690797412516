<template>
  <div class="w-full h-screen flex justify-center items-center">
    <div class="flex w-full flex-col gap-8">
      <h1 class="text-3xl text-n-800 dark:text-n-0 font-bold font-poppins">
        {{ $t('new-password.new-password.not-found.title') }}
      </h1>
      <l-empty-case type="empty">
        {{ $t('new-password.new-password.not-found.description') }}
      </l-empty-case>
      <l-button class="w-full" @click="emit('login')">
        {{ $t('ctas.back') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LButton, LEmptyCase } from '@last/core-ui/paprika'

const emit = defineEmits<{
  login: []
}>()
</script>
