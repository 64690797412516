import { computed } from 'vue'

import { notification, NotificationConstructor } from '@last/core-ui/paprika'

import i18n from '@/i18n'

type POSNotification = Omit<NotificationConstructor, 'type'>

export const useNotifications = () => {
  const { t } = i18n.global

  const notify = notification.create

  const notificationsAmount = computed(
    () => notification.fixedNotifications.length
  )

  function openNotificationsSideBar() {
    notification.openFixedComponent()
  }

  function closeNotificationsSideBar(): void {
    notification.closeFixedComponent()
  }

  function notifySuccess(constructor: POSNotification) {
    notify({
      ...constructor,
      type: 'success'
    })
  }

  function notifyError(constructor: POSNotification) {
    notify({
      ...constructor,
      type: 'error'
    })
  }

  function notifyInfo(constructor: POSNotification) {
    notify({
      ...constructor,
      type: 'info'
    })
  }

  function notifyPaymentFailed(message?: string) {
    const errorMessage = message
      ? `${t('notifications.payment-failed')}: ${message}`
      : t('notifications.payment-failed')

    notifyError({
      title: errorMessage
    })
  }

  return {
    notificationsAmount,
    openNotificationsSideBar,
    closeNotificationsSideBar,
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyPaymentFailed
  }
}
