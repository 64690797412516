<template>
  <div class="h-auto w-full" @click="focusInput">
    <div
      :class="[
        ...errorClasses,
        ...focusClasses,
        ...disabledClasses,
        ...backgroundColors,
        ...textColors,
        ...sizeClasses,
        'rounded',
        size === 'x-small' ? '!h-auto' : ''
      ]"
      class="w-full flex items-center overflow-hidden box-border border border-n-50 dark:border-n-700"
    >
      <slot name="left"></slot>
      <textarea
        v-if="type === 'textarea'"
        v-bind="$attrs"
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none px-4 py-3 w-full leading-tight bg-transparent font-body"
        :class="[...placeholderClasses, ...sizeClasses]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
      />
      <input
        v-else
        v-bind="$attrs"
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none w-full leading-tight bg-transparent font-body"
        :class="[
          ...placeholderClasses,
          ...sizeClasses,
          size === 'x-small' ? 'px-2 h-6' : 'px-4 h-full'
        ]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
        :type="type"
      />
      <slot name="right"></slot>
    </div>
    <div v-if="errorMessage && wrongValue" class="text-sm text-r-500">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef, type InputHTMLAttributes } from 'vue'

import {
  useBackgroundColorsClasses,
  useDisabledClasses,
  useErrorClasses,
  useFocusClasses,
  usePlaceholderClasses,
  useSizeClasses,
  useTextColorsClasses
} from '../classes'
import type { Size } from '../types'
import { type InputProps } from './inputTypes'

const props = withDefaults(
  defineProps<InputProps & /* @vue-ignore */ InputHTMLAttributes>(),
  {
    placeholder: '',
    errorMessage: '',
    size: 'small',
    maxLength: 255,
    autocomplete: 'off',
    wrongValue: false,
    readOnly: false
  }
)

const inputRef = useTemplateRef('input')

const errorClasses = computed(() => useErrorClasses(props.wrongValue))
const placeholderClasses = computed(() => usePlaceholderClasses(props.size))
const focusClasses = computed(() => useFocusClasses())
const disabledClasses = computed(() => useDisabledClasses(props.disabled))
const backgroundColors = computed(() => useBackgroundColorsClasses())
const textColors = computed(() => useTextColorsClasses())
const sizeClasses = computed(() => {
  return props.type === 'textarea'
    ? useTextAreaSizeClasses(props.size)
    : useSizeClasses(props.size)
})

const modelValue = defineModel<any>({ default: null })

function useTextAreaSizeClasses(size: Size) {
  switch (size) {
    case 'small':
      return ['text-xs', 'h-16']
    case 'medium':
      return ['text-sm', 'h-20']
    case 'large':
      return ['text-base', 'h-24']
    default:
      return []
  }
}

function focusInput() {
  inputRef.value?.focus()
}

defineExpose({ focusInput, inputRef })
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
