<template>
  <div
    class="flex flex-row items-center px-4 h-16 font-body text-base relative cursor-pointer text-n-0"
    :class="{
      'opacity-60': status === 'closed',
      'bg-n-700': isSelected
    }"
  >
    <div class="flex items-center w-1/5 xl:w-1/6">
      <l-checkbox
        v-if="choosingForShipment"
        :model-value="pickedForShipment"
        class="mr-3"
        @update:model-value="value => $emit('shipmentPickToggled', value)"
      />
      <tab-title :tab-id="tab.id" :class="{ 'ml-8': offset }" />
    </div>
    <div class="text-lg w-1/5">
      <div v-if="isCancelled || hasAlert" class="pr-4">
        <l-status v-if="isCancelled" status="error">
          {{ t('tabs.cancelled') }}
        </l-status>
        <l-status v-if="hasAlert" status="pending" class="cursor-pointer">
          {{ t('tabs.alert') }}
        </l-status>
      </div>
      <div
        v-else-if="!tab.open"
        class="text-xl font-text flex items-center"
        :class="[wrongClosedState ? 'text-r-300' : 'text-g-500']"
      >
        {{ tabDuration }}
        <span class="text-n-0 text-lg ml-4">{{ t('tabs.closed') }}</span>
      </div>
      <div v-else-if="!tab.activationTime" class="uppercase">
        {{ t('tabs.scheduled') }}
      </div>
      <div
        v-else
        class="text-xl font-text flex gap-2 items-center justify-between pr-4"
        :class="stateColor"
      >
        <div>{{ timeFromTab }}</div>
        <delivery-status
          v-if="tab.deliveryOrder"
          :tab-id="tab.id"
          class="ml-3"
        />
      </div>
    </div>
    <div v-if="rowSize !== 'small'" class="flex-1 flex flex-col">
      <div>
        {{ d(tab.activationTime ?? tab.schedulingTime!, 'day') }}
      </div>
      <div class="text-n-200">
        {{ d(tab.activationTime ?? tab.schedulingTime!, 'time') }}
      </div>
    </div>
    <div class="flex-1 flex-col">
      <div>
        {{ d(deliveryTime, 'day') }}
      </div>
      <div class="text-n-200">{{ d(deliveryTime, 'time') }}</div>
    </div>
    <div class="w-1/4 xl:w-1/5">
      <div class="w-10/12 line-clamp-2">
        {{ longAddressText }}
      </div>
    </div>
    <div
      v-if="tab.deliveryOrder && tab.deliveryOrder.courierName"
      class="flex-1"
    >
      {{ tab.deliveryOrder.courierName }}
    </div>
    <div
      v-else-if="
        tab.deliveryOrder &&
        tab.deliveryOrder.shipmentId &&
        tab.pickupType === 'ownDelivery'
      "
      class="flex-1"
    >
      {{ tab.deliveryOrder.shipment.provider || '' }}
    </div>
    <div
      v-else-if="tab.deliveryOrder && tab.deliveryOrder.shipmentCancelled"
      class="flex-1 text-r-300"
    >
      {{ t('tabs.shipment-cancelled') }}
    </div>
    <div v-else class="flex-1" />
    <div v-if="rowSize === 'large'" class="flex-1">
      {{ tab.customerInfo?.phoneNumber ?? '' }}
    </div>
    <div
      v-if="
        isSelected && tab.pickupType === 'ownDelivery' && !choosingForShipment
      "
      class="absolute inset-y-0 right-0 items-center flex mr-3"
    >
      <l-icon
        name="external"
        class="text-v-300"
        @click="$emit('goToDetails'), tracker.track('tab_to_details')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  addMinutes,
  differenceInSeconds,
  isAfter,
  isBefore,
  subMinutes
} from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { LCheckbox, LIcon, LStatus, useTracker } from '@last/core-ui/paprika'

import { useTabs } from '@/composables/useTabs'

import DeliveryStatus from './DeliveryStatus.vue'
import TabTitle from './TabTitle.vue'

const { d, t } = useI18n()
const tracker = useTracker()

interface Props {
  tabId: string
  rowSize: string
  currentTime: Date
  isSelected?: boolean
  choosingForShipment?: boolean
  pickedForShipment?: boolean
  offset?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSelected: false,
  choosingForShipment: false,
  pickedForShipment: false,
  offset: false
})

defineEmits<{
  shipmentPickToggled: [boolean]
  goToDetails: []
}>()

const { tab, status, isCancelled, hasAlert } = useTabs(() => props.tabId)

const longAddressText = computed(() => {
  if (tab.value.deliveryOrder?.address && tab.value.deliveryOrder?.postalCode) {
    return `${tab.value.deliveryOrder.address} (${tab.value.deliveryOrder.postalCode})`
  } else if (tab.value.deliveryOrder?.address) {
    return tab.value.deliveryOrder.address
  }
  return ''
})

const deliveryTime = computed(() => {
  if (tab.value.schedulingTime) {
    return new Date(tab.value.schedulingTime)
  } else {
    return addMinutes(new Date(tab.value.activationTime!), 30)
  }
})

const tabDuration = computed(() => {
  const secondsDiff = differenceInSeconds(
    new Date(tab.value.closeTime!),
    new Date(tab.value.activationTime!)
  )

  return `${addZero(Math.round(secondsDiff / 60))}:${addZero(secondsDiff % 60)}`
})

const stateColor = computed(() => {
  const activationTime = tab.value.activationTime
  const now = props.currentTime
  const status = tab.value.deliveryOrder?.status
  let statusTimes
  switch (status) {
    case 'KITCHEN':
      statusTimes = [10, 15]
      break
    case 'READY_TO_PICKUP':
      statusTimes = [13, 20]
      break
    case 'ON_DELIVERY':
      statusTimes = [28, 40]
      break
  }
  if (!statusTimes || !activationTime) {
    return 'text-v-300'
  } else {
    if (isBefore(subMinutes(now, statusTimes[0]), activationTime)) {
      return 'text-g-500'
    } else if (isBefore(subMinutes(now, statusTimes[1]), activationTime)) {
      return 'text-y-500'
    } else {
      return 'text-r-500'
    }
  }
})

const wrongClosedState = computed(() => {
  if (!tab.value.activationTime || !tab.value.closeTime) {
    return false
  }
  return isAfter(
    subMinutes(new Date(tab.value.closeTime), 30),
    new Date(tab.value.activationTime)
  )
})

const timeFromTab = computed(() => {
  const secondsDiff = differenceInSeconds(
    props.currentTime,
    new Date(tab.value.activationTime!)
  )

  return `${addZero(Math.round(secondsDiff / 60))}:${addZero(secondsDiff % 60)}`
})

function addZero(number: number) {
  return number.toString().padStart(2, '0')
}
</script>
