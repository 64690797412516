<template>
  <input-wrapper
    ref="inputWrapperRef"
    v-bind="{ ...$props, ...$attrs }"
    type="text"
    :inputmode="props.format === 'integer' ? 'numeric' : 'decimal'"
    v-model="model"
    @beforeinput="onBeforeInput"
    @click="focus"
  >
    <template #left>
      <l-icon
        v-if="icon && iconPosition == 'left'"
        :name="icon"
        class="text-n-800 dark:text-n-200"
        :class="[size === 'x-small' ? 'ml-2' : 'ml-4']"
      />
    </template>
    <template #right>
      <l-icon
        v-if="icon && iconPosition == 'right'"
        :name="icon"
        class="text-n-800 dark:text-n-200"
        :class="[size === 'x-small' ? 'mr-2' : 'mr-4']"
      />
    </template>
  </input-wrapper>
</template>

<script setup lang="ts">
import { computed, useTemplateRef, type InputHTMLAttributes } from 'vue'

import type { LIconName } from '../icon.type'
import LIcon from '../LIcon.vue'
import { type InputProps } from './inputTypes'
import InputWrapper from './InputWrapper.vue'

const DEFAULT_MAX_NUMBER = 999_999_999

type Props = InputProps & {
  icon?: LIconName
  iconPosition?: 'left' | 'right'
  /**
   * Text input as number
   * * Decimal: A number with max to two decimals
   * * Currency: Last two digits are the decimal part
   * * Integer: No decimal part
   * @default 'decimal'
   */
  format?: 'decimal' | 'currency' | 'integer'
  /**
   * Minimum value for the input
   * @default -999_999_999
   */
  min?: number
  /**
   * Maximum value for the input
   * @default 999_999_999
   */
  max?: number
} & /* @vue-ignore */ InputHTMLAttributes

const props = withDefaults(defineProps<Props>(), {
  format: 'decimal',
  icon: undefined,
  iconPosition: 'right',
  min: -DEFAULT_MAX_NUMBER,
  max: DEFAULT_MAX_NUMBER
})

const inputWrapperRef = useTemplateRef('inputWrapperRef')

/**
 * Prevent the user from typing invalid characters for a number input
 */
function onBeforeInput(event: Event) {
  const target = event.target as HTMLInputElement
  const nextValue =
    target.value.slice(0, target.selectionStart ?? undefined) +
    ((event as InputEvent).data ?? '') +
    target.value.slice(target.selectionEnd ?? undefined)

  /**
   * Only allow numbers, including negative, one comma or one dot and at most two decimal places
   */
  if (!/^(-)?\d*[.,]?\d{0,2}$/.test(nextValue)) {
    event.preventDefault()
  }
}

const model = defineModel<number | null, never, string | number | null>({
  default: null,
  get: value => {
    if (value === null) return null
    let formattedValue = +value
    if (props.format === 'currency') {
      formattedValue = value / 100
    }
    return new Intl.NumberFormat('es', {
      style: 'decimal',
      useGrouping: false
    }).format(formattedValue)
  },
  set: value => {
    if (value === null) return null

    const parsedValue = value.toString().replace(',', '.')
    let numberValue = parseFloat(parsedValue)

    if (isNaN(numberValue)) return null

    if (numberValue > props.max) {
      numberValue = props.max
    }

    if (numberValue < props.min) {
      numberValue = props.min
    }

    if (props.format === 'integer') {
      numberValue = Math.round(numberValue)
    } else {
      // Round to 2 decimal places
      const roundedNumber = Math.round(numberValue * 100) / 100
      if (roundedNumber !== numberValue) {
        numberValue = roundedNumber
      }
    }

    if (props.format === 'currency') {
      numberValue = Math.round(numberValue * 100)
    }

    return numberValue
  }
})

function focus(): void {
  inputWrapperRef.value?.focusInput()
  setTimeout(() => {
    const target = inputWrapperRef.value
    target?.inputRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, 200)
}

const inputRef = computed(() => inputWrapperRef.value!.inputRef!)

defineExpose({ focus, inputRef })
</script>
