import { sleep } from '@last/core'

import { type IDataphone } from './dataphone'

export class DevDataphone implements IDataphone {
  public initialized = true
  public canCancel = true

  constructor() {}

  async init() {
    return true
  }

  async charge() {
    await sleep(5000)
    return true
  }

  async cancel() {
    await sleep(2000)
    return
  }

  async refund() {
    await sleep(2000)
    return { error: null }
  }
}
