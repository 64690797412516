import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n, type I18nOptions } from 'vue-i18n'

import en from '@/locales/en.json'

const locales = ['en', 'es', 'ca', 'de']

const options: I18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: 'es',
  availableLocales: locales,
  fallbackLocale: 'en',
  messages,
  numberFormats: {
    es: {
      decimal: {
        style: 'decimal',
        useGrouping: true
      }
    }
  },
  datetimeFormats: {
    es: {
      date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      },
      time: {
        hour: 'numeric',
        minute: 'numeric'
      },
      day: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    }
  }
}

export default createI18n<false, typeof options>(options)

declare module 'vue-i18n' {
  type DefaultMessage = typeof en
  export interface DefineLocaleMessage extends DefaultMessage {}
}
