<template>
  <div class="flex flex-row h-screen pt-safe pb-safe">
    <div
      class="hidden w-1/2 md:flex flex-col justify-center items-center bg-n-700 text-n-0"
    >
      <div class="w-3/4 md:w-2/3 flex flex-col max-w-sm">
        <div
          class="w-full text-left text-[2.813rem] leading-[3.438rem] font-heading font-bold mb-6"
        >
          {{ $t('login.welcome-title') }}
        </div>
        <div class="w-full text-left text-xl leading-5 pb-52">
          {{ $t('login.welcome-baseline') }}
        </div>
        <div class="w-full text-left text-xl leading-5">
          <img class="w-52" src="/logo.png" />
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-1/2 flex justify-center mt-20 md:mt-0 sm:pt-0 items-center flex-col"
    >
      <div
        class="sm:p-0 md:w-2/3 max-w-sm flex flex-col shrink-0 flex-1 md:max-h-96 w-72"
      >
        <LoginForm
          v-if="componentState === ComponentStates.LOGIN"
          :show-p-i-n-option="showPINOption"
          @activate-p-i-n="componentState = ComponentStates.PIN"
          @forgot-password="newPassword"
        />

        <LoginPIN
          v-if="componentState === ComponentStates.PIN"
          @disable-p-i-n="componentState = ComponentStates.LOGIN"
        />
      </div>
      <div class="md:hidden flex items-end mt-auto mb-12">
        <img class="w-52" src="/logo.png" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useAuthStore } from '@/store/auth'

import LoginForm from './LoginForm.vue'
import LoginPIN from './LoginPIN.vue'

const ComponentStates = {
  LOGIN: 'login',
  PIN: 'pin'
} as const

const componentState = ref<
  (typeof ComponentStates)[keyof typeof ComponentStates]
>(ComponentStates.LOGIN)

const authStore = useAuthStore()
const { isAuthenticated } = storeToRefs(authStore)
const showPINOption = ref<boolean>(false)

const router = useRouter()

function newPassword(): void {
  router.push({ name: 'newPassword' })
}

onMounted(() => {
  if (isAuthenticated.value) {
    router.push({ name: 'employees' })
  }
  const platform = Capacitor.getPlatform()
  showPINOption.value = platform !== 'web'
})
</script>
