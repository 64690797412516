import { Buffer } from 'buffer'

import SocketFactory from '@/drivers/socket'

import { PrinterConfig, PrinterDriver } from '../types'
import zpl from './jszpl/jszpl.js'

export default class ZPL implements PrinterDriver {
  private config: PrinterConfig

  constructor(config: PrinterConfig) {
    this.config = config
  }

  printImage(imageDataUrl: string) {
    return new Promise<void>((resolve, reject) => {
      const socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          const graphic = new zpl.Graphic()
          const label = new zpl.Label()
          const width = this.config.ticketWidth || 380
          const height = this.config.ticketHeight || 350
          const paddingLeft = this.config.ticketPaddingLeft || 220
          label.printDensity = new zpl.PrintDensity(
            zpl.PrintDensityName['8dpmm']
          )
          label.width = width
          label.height = height
          const img = new Image()

          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height

            const context = canvas.getContext('2d')
            context!.drawImage(img, 0, 0, width, height)

            const imageData = context!.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            )

            let index = 0
            const imageBits = []

            for (let y = 0; y < imageData.height; y++) {
              for (let x = 0; x < imageData.width; x++) {
                const red = imageData.data[index++]
                const green = imageData.data[index++]
                const blue = imageData.data[index++]
                const opacity = imageData.data[index++]

                let value = 0
                if (opacity != 0) {
                  value = (red + green + blue) / 3 < 180 ? 1 : 0
                }

                imageBits.push(value)
              }
            }
            graphic.width = new zpl.Size(width)
            graphic.height = new zpl.Size(height)
            graphic.data = new zpl.GraphicData(width, height, imageBits)

            label.padding = new zpl.Spacing(paddingLeft, 0, 0, 0)
            label.content.push(graphic)
            socket.write(
              Buffer.from(label.generateZPL()),
              () => {
                socket.shutdownWrite(() => {
                  resolve()
                }, reject)
              },
              reject
            )
          }
          img.src = imageDataUrl
        },
        reject
      )
    })
  }

  openCashDrawer() {
    // NO_OP
  }
}
