<template>
  <l-dialog
    v-if="showDialog"
    v-bind="dialogProps"
    @close="showDialog = false"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'

import LDialog, { type Props } from '../../components/LDialog.vue'
import { init } from './dialog'

const showDialog = ref(false)
const dialogProps = ref()

function openDialog(props: Props) {
  showDialog.value = true
  dialogProps.value = props

  return {
    close: () => {
      showDialog.value = false
    }
  }
}

init(openDialog)
</script>
