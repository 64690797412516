<template>
  <div
    class="bg-n-800 text-n-0 px-8 h-screen pb-safe flex items-center justify-center"
  >
    <div class="flex flex-col items-center text-center gap-4">
      <div v-if="loadedImage" class="w-40 h-40" v-html="loadedImage" />
      <div class="font-heading text-2xl font-bold">
        {{ title }}
      </div>
      <div>{{ message }}</div>
      <l-button
        v-if="confirmText"
        size="medium"
        class="w-full max-w-72 mt-6"
        @click="emit('confirm')"
      >
        {{ confirmText }}
      </l-button>
    </div>
  </div>
</template>

<script lang="ts">
const modules = import.meta.glob('../../assets/*', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { LButton } from '@last/core-ui/paprika'

const props = defineProps({
  title: {
    type: String,
    default: null,
    required: true
  },
  message: {
    type: String,
    default: null,
    required: true
  },
  confirmText: {
    type: String,
    default: null
  },
  image: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['confirm'])

const loadedImage = computed(() => {
  return modules['../../assets/' + props.image]
})
</script>
