<template>
  <div
    class="z-10 flex flex-col bg-n-800 min-w-72"
    :class="{
      'w-full sm:w-1/5 sm:border-r border-n-700 box-content': !fullScreen,
      'w-full': fullScreen
    }"
  >
    <div class="flex flex-row justify-between items-center">
      <div
        class="flex flex-row items-center min-w-72 p-4 gap-4"
        :class="{
          'w-1/5': fullScreen,
          'w-full': !fullScreen
        }"
      >
        <l-option-selector
          v-model="viewMode"
          :options="viewModes"
          class="w-24 shrink-0 hidden sm:flex"
        />
        <l-select
          v-model="tabsFilter"
          :options="tabViewOptions"
          size="small"
          class="w-full"
        />
      </div>
      <l-button
        v-if="
          tabsType === 'delivery' &&
          fullScreen &&
          config.manualShipment &&
          !choosingForShipment
        "
        type="secondary"
        size="small"
        class="m-4"
        @click="choosingForShipment = !choosingForShipment"
      >
        {{ $t('tabs.request-shipments') }}
      </l-button>
    </div>
    <div v-if="fullScreen && choosingForShipment" class="flex flex-row px-4">
      <l-checkbox
        :model-value="
          filteredNowTabsPickableForShipment.length ===
          pickedTabsForShipment.length
        "
        :half="selectAllIsHalfed"
        @update:model-value="toggleSelectAll"
      />
      <div class="text-n-0 ml-3">
        {{ $t('tabs.select-all') }}
      </div>
    </div>
    <div v-if="fullScreen" class="flex flex-row items-center p-4">
      <div
        v-for="header in headers"
        :key="header"
        class="flex-1 text-sm text-n-200 font-body"
        :class="{
          'w-1/4 xl:w-1/5 flex-none': header === $t('tabs.address'),
          'w-1/5 flex-none xl:w-1/6': header === $t('tabs.tab'),
          'w-1/5 flex-none': header === $t('tabs.time')
        }"
      >
        {{ header }}
      </div>
    </div>
    <div class="flex-1 overflow-hidden">
      <tab-rows
        v-model:view-mode="viewMode"
        :tabs="filteredTabs"
        :picked-tabs-for-shipment="pickedTabsForShipment"
        :choosing-for-shipment="choosingForShipment"
        :full-screen="fullScreen"
        :selected-tab-id="selectedTabId"
        :row-size="rowSize"
        @select-tab="selectTab"
        @shipment-pick-toggled="shipmentPickToggled"
      />
    </div>
    <div
      v-if="fullScreen && choosingForShipment"
      class="flex flex-row justify-between absolute bottom-0 right-0 w-full p-3 bg-n-700"
    >
      <l-button
        type="text"
        size="small"
        icon="close"
        @click="choosingForShipment = false"
      >
        {{ $t('tabs.cancel') }}
      </l-button>
      <l-button
        :loading="requestShipmentLoading"
        size="small"
        class="w-32"
        @click="confirmRequestShipment"
      >
        {{ $t('tabs.request') }}
      </l-button>
    </div>
    <template v-if="!fullScreen && hasCatalog">
      <l-button
        v-if="showCreateTabButton"
        size="small"
        :type="filteredTabs.length > 0 ? 'secondary' : 'primary'"
        class="hidden sm:block mx-4 mb-6"
        @click="createNewTab"
      >
        <span v-if="tabsType === 'delivery'">
          {{ $t('tabs.new-delivery') }}
        </span>
        <span v-else-if="tabsType === 'takeAway'">
          {{ $t('tabs.new-take-away') }}
        </span>
        <span v-else>{{ $t('tabs.new') }}</span>
      </l-button>
      <l-button
        v-if="showCreateTabButton"
        size="medium"
        type="primary"
        class="block sm:hidden mx-4 mb-6"
        @click="createNewTab"
      >
        <span v-if="tabsType === 'delivery'">
          {{ $t('tabs.new-delivery') }}
        </span>
        <span v-else-if="tabsType === 'takeAway'">
          {{ $t('tabs.new-take-away') }}
        </span>
        <span v-else>{{ $t('tabs.new') }}</span>
      </l-button>
      <new-tab
        v-if="configuringTab.onSite && tabsType !== 'takeAway'"
        :is-active="configuringTab.onSite"
        @close="configuringTab.onSite = false"
        @tab-created="tabId => $emit('tabCreated', tabId)"
      />

      <new-delivery-tab
        v-if="
          (configuringTab.delivery || configuringTab.onSite) &&
          tabsType !== 'all'
        "
        :type="tabsType"
        @close="
          (configuringTab.delivery = false), (configuringTab.onSite = false)
        "
        @tab-created="tabId => $emit('tabCreated', tabId)"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { addSeconds, isAfter, isBefore, subSeconds } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import {
  LButton,
  LCheckbox,
  LIconName,
  LOptionSelector,
  LSelect,
  useDialog
} from '@last/core-ui/paprika'

import { requestShipment } from '@/api/shipment'
import app from '@/app'
import NewDeliveryTab from '@/components/tabs/new-tab/NewExternalTab.vue'
import NewTab from '@/components/tabs/NewTab.vue'
import TabRows from '@/components/tabs/TabRows.vue'
import { useNotifications } from '@/composables/useNotifications'
import barcodeScanner from '@/drivers/barcodeScanner'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { useCouriersStore } from '@/store/couriers'
import { useTabsStore } from '@/store/tabs'
import type { Courier, Tab } from '@/types'

const { t } = useI18n()
const dialog = useDialog()
const { notifyPaymentFailed, notifyError, notifySuccess } = useNotifications()
const router = useRouter()

interface Props {
  selectedTabId?: string
  tabs: Tab[]
  tabsType: 'all' | 'takeAway' | 'delivery'
}

const props = withDefaults(defineProps<Props>(), {
  selectedTabId: undefined,
  tabs: () => [],
  tabsType: 'all'
})

const emit = defineEmits([
  'tabCreated',
  'update:selectedTabId',
  'cancel',
  'fullScreen'
])

const viewMode = defineModel<'splitScreen' | 'fullScreen'>('viewMode')

const tabsStore = useTabsStore()
const configStore = useConfigStore()
const couriersStore = useCouriersStore()
const catalogStore = useCatalogStore()

const tabs = toRef(props, 'tabs')

const { openTab, closeTab, updateDeliveryOrderStatus, addPayment } = tabsStore
const { getBills, tabs: allTabs } = storeToRefs(tabsStore)
const { config } = storeToRefs(configStore)
const { couriers } = storeToRefs(couriersStore)
const { catalogs } = storeToRefs(catalogStore)

const tabViewOptions = [
  { label: t('tabs.in-progress'), value: 'not-closed' },
  { label: t('tabs.closed'), value: 'closed' }
]

const viewModes: { icon: LIconName; value: string }[] = [
  { icon: 'dots', value: 'splitScreen' },
  { icon: 'hamburger-4-lines', value: 'fullScreen' }
]

const { width: windowWidth } = useWindowSize()

const configuringTab = ref({
  delivery: false,
  onSite: false
})
const tabsFilter = ref(tabViewOptions[0].value)
const recentlyScanned = ref<Record<string, Date>>({})
const recentlyAssigned = ref<Record<string, Date>>({})
const choosingForShipment = ref(false)
const requestShipmentLoading = ref(false)
const pickedTabsForShipment = ref<string[]>([])

const selectAllIsHalfed = computed(() => {
  return (
    pickedTabsForShipment.value.length > 0 &&
    pickedTabsForShipment.value.length <
      filteredNowTabsPickableForShipment.value.length
  )
})

const codeToTabs = computed(() => {
  return openTabs.value.reduce<Record<string, Tab>>((res, tab) => {
    res[tab.code] = tab
    return res
  }, {})
})

const openTabs = computed(() => {
  return tabs.value.filter(tab => tab.open)
})

const filteredNowTabsPickableForShipment = computed(() => {
  return filteredTabs.value.filter(rowIsPickableForShipment)
})

const filteredTabs = computed(() => {
  if (tabsFilter.value === 'not-closed') {
    return filteredNotClosed.value
  }

  return filteredClosed.value
})

const filteredNotClosed = computed(() => {
  return tabs.value
    .filter(tab => {
      return tab.open
    })
    .sort((a, b) => {
      return (
        new Date(
          a.activationTime ? a.activationTime : a.schedulingTime!
        ).getTime() -
        new Date(
          b.activationTime ? b.activationTime : b.schedulingTime!
        ).getTime()
      )
    })
})

const filteredClosed = computed(() => {
  return tabs.value
    .filter(tab => {
      return !tab.open
    })
    .sort(
      (a, b) =>
        new Date(b.closeTime! ?? b.activationTime!).getTime() -
        new Date(a.closeTime! ?? b.activationTime!).getTime()
    )
})

const headers = computed(() => {
  const result = []
  result.push(t('tabs.tab'))
  result.push(t('tabs.time'))
  if (rowSize.value !== 'small') {
    result.push(t('tabs.ordering-time'))
  }
  result.push(t('tabs.delivery-time'))
  result.push(t('tabs.address'))
  result.push(t('tabs.courier'))
  if (rowSize.value === 'large') {
    result.push(t('tabs.phone-number'))
  }
  return result
})

const rowSize = computed(() => {
  let result = 'small'
  if ((windowWidth.value ?? 0) > 1224) {
    result = 'medium'
  }
  if ((windowWidth.value ?? 0) > 1366) {
    result = 'large'
  }
  return result
})

const showCreateTabButton = computed(() => {
  return config?.value?.lastProductPosEnabled
})

const hasCatalog = computed(() => {
  return Object.keys(catalogs.value).length > 0
})

watch(
  () => filteredTabs.value,
  tabs => {
    const tabIds = tabs.map(tab => tab.id)
    if (!props.selectedTabId || !tabIds.includes(props.selectedTabId)) {
      selectFirst()
    }
  }
)

const previousTabId = ref<string>('')

watch(
  () => props.selectedTabId,
  tabId => {
    if (!previousTabId.value) previousTabId.value = tabId || ''
    if (tabId && previousTabId.value !== tabId) {
      switchTabViewByTabId(tabId)
      previousTabId.value = tabId
    }
  },
  { immediate: true }
)

onMounted(() => {
  selectFirst()
  barcodeScanner.initialize()
  barcodeScanner.onInput(inputString => {
    updateStatus(inputString)
  })
})

onBeforeUnmount(() => {
  barcodeScanner.close()
})

function switchTabViewByTabId(tabId: string): void {
  if (filteredNotClosed.value.map(tab => tab.id).includes(tabId)) {
    tabsFilter.value = 'not-closed'
  } else if (filteredClosed.value.map(tab => tab.id).includes(tabId)) {
    tabsFilter.value = 'closed'
  }
}

function rowIsPickableForShipment(tab: Tab) {
  return (
    tab.pickupType === 'ownDelivery' &&
    tab.deliveryOrder &&
    !tab.deliveryOrder.shipmentId
  )
}

async function confirmRequestShipment() {
  if (pickedTabsForShipment.value.length === 0) return
  requestShipmentLoading.value = true

  try {
    await requestShipment(pickedTabsForShipment.value)
    pickedTabsForShipment.value = []
    choosingForShipment.value = false
    notifySuccess({
      title: t('tabs.shipment-request-done')
    })
  } catch (error) {
    notifyError({
      title: `${t('tabs.shipment-request-failed')}: ${error}`
    })
  }
  requestShipmentLoading.value = false
}

function toggleSelectAll(value: boolean) {
  if (value && !selectAllIsHalfed.value) {
    pickedTabsForShipment.value = filteredNowTabsPickableForShipment.value.map(
      tab => tab.id
    )
  } else if (selectAllIsHalfed.value) {
    pickedTabsForShipment.value = []
  } else {
    pickedTabsForShipment.value = []
  }
}

function shipmentPickToggled(tabId: string, value: boolean) {
  if (value) {
    pickedTabsForShipment.value.push(tabId)
  } else {
    const index = pickedTabsForShipment.value.indexOf(tabId)
    pickedTabsForShipment.value.splice(index, 1)
  }
}

function tabCodeIsNotRecentlyAssigned(tabCode: string) {
  if (!recentlyAssigned.value[tabCode]) {
    return true
  } else if (
    isAfter(new Date(), addSeconds(recentlyAssigned.value[tabCode], 60))
  ) {
    delete recentlyAssigned.value[tabCode]
    return true
  }
  return false
}

function courierHasOrdersOnDelivery(courier: Courier) {
  return openTabs.value.some(
    tab =>
      tab.deliveryOrder &&
      tab.deliveryOrder.status === 'ON_DELIVERY' &&
      tab.deliveryOrder.courierId === courier.id &&
      tabCodeIsNotRecentlyAssigned(tab.code)
  )
}

function tabIsPaid(tab: Tab) {
  const bills = getBills.value(tab.id)
  const pending = bills.reduce<number>((sum, bill) => sum + bill.pending, 0)
  return pending === 0
}

async function addPaymentIfNeeded(tab: Tab) {
  if (!tabIsPaid(tab)) {
    const amount = getBills
      .value(tab.id)
      .flatMap(bill => bill.pending)
      .reduce((sum, billTotal) => sum + billTotal, 0)
    if (
      CashMachine.methods.includes(tab.deliveryOrder!.preferredPaymentMethod)
    ) {
      const charged = await CashMachine.charge(amount)
      if (charged === 0) {
        notifyPaymentFailed()
        emit('cancel')
        return
      }
    }
    const paymentData = {
      billId: tab.bills[0],
      amount,
      type: tab.deliveryOrder!.preferredPaymentMethod
    }
    addPayment({ tabId: tab.id, ...paymentData })
  }
}

function handleAssignedOrders(courier: Courier) {
  openTabs.value
    .filter(tab => {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.status === 'ON_DELIVERY' &&
        tab.deliveryOrder.courierId === courier.id &&
        tabCodeIsNotRecentlyAssigned(tab.code)
      )
    })
    .forEach(tab => {
      finishOrder(tab)
    })
}

function handleRecentlyScannedForCourier(targetCourier: Courier) {
  Object.keys(recentlyScanned.value).forEach(tabCode => {
    if (isBefore(subSeconds(new Date(), 10), recentlyScanned.value[tabCode])) {
      updateDeliveryOrderStatus({
        tabId: codeToTabs.value[tabCode].id,
        newStatus: 'ON_DELIVERY',
        courier: targetCourier
      })
      recentlyAssigned.value[tabCode] = new Date()
    }
  })
  recentlyScanned.value = {}
}

async function finishOrder(tab: Tab) {
  const isPaid = tabIsPaid(tab)
  if (tab.deliveryOrder?.preferredPaymentMethod && !isPaid) {
    await addPaymentIfNeeded(tab)
  } else if (!isPaid) {
    dialog({
      title: t('tabs.payment-method-error'),
      content: `${t('tabs.payment-method-error-text-1')} ${t('tabs.payment-method-error-text-2')}`
    })
  }
  if (isPaid) {
    updateDeliveryOrderStatus({
      tabId: tab.id,
      newStatus: 'DELIVERED'
    })
    closeTab({ tabId: tab.id })
  }
  emit('update:selectedTabId', null)
}

function handleRecentlyScannedForCloseDelivery() {
  Object.keys(recentlyScanned.value).forEach(tabCode => {
    if (isBefore(subSeconds(new Date(), 10), recentlyScanned.value[tabCode])) {
      const tabId = codeToTabs.value[tabCode].id
      const tab = allTabs.value[tabId]
      finishOrder(tab)
    }
  })
  recentlyScanned.value = {}
}

function selectTab(tabId: string | null) {
  previousTabId.value = tabId || ''
  emit('update:selectedTabId', tabId)
}

function setToReadyToPickupIfKitchen(tab: Tab) {
  if (tab.deliveryOrder && tab.deliveryOrder.status === 'KITCHEN') {
    updateDeliveryOrderStatus({
      tabId: tab.id,
      newStatus: 'READY_TO_PICKUP'
    })
  }
}

function updateStatus(inputString: string) {
  inputString = inputString.trim()
  const targetCourier = Object.values(couriers.value).find(
    courier => courier.code === inputString
  )
  const targetTab = openTabs.value.find(
    tab => tab.id.slice(-10) === inputString || tab.code === inputString
  )
  if (targetTab) {
    recentlyScanned.value[targetTab.code] = new Date()
    setToReadyToPickupIfKitchen(targetTab)
  } else if (targetCourier) {
    if (
      courierHasOrdersOnDelivery(targetCourier) &&
      Object.keys(recentlyScanned.value).length > 0
    ) {
      dialog({
        title: t('tabs.courier-error-title'),
        content: `${t('tabs.courier-error-text-1')} ${t('tabs.courier-error-text-2')}`
      })
      recentlyScanned.value = {}
      return
    }
    handleAssignedOrders(targetCourier)
    handleRecentlyScannedForCourier(targetCourier)
  } else if (inputString === 'Z0000') {
    handleRecentlyScannedForCloseDelivery()
  }
}

function selectFirst() {
  if (app.isMobile) return
  if (filteredTabs.value.length > 0) {
    selectTab(filteredTabs.value[0].id)
  } else {
    selectTab(null)
  }
}

async function createNewTab() {
  if (props.tabsType === 'takeAway' && !config.value.showTakeAwayTabPopup) {
    const newTab = {
      seats: 0,
      pickupType: 'takeAway'
    }
    const tabId = openTab({
      tableId: null,
      tab: newTab
    })
    emit('tabCreated', tabId)
    router.push({ name: 'orderManagement', params: { tabId } })
  } else if (props.tabsType === 'delivery') {
    configuringTab.value.delivery = true
  } else {
    configuringTab.value.onSite = true
  }
}

const fullScreen = computed(() => viewMode.value === 'fullScreen')

watch(
  () => props.tabsType,
  value => {
    if (value != 'delivery') choosingForShipment.value = false
  }
)
</script>
