<template>
  <l-modal
    size="small"
    :title="title"
    :button-text="$t('ctas.confirm')"
    :button-enabled="isComplete"
    :full-height="false"
    @close="emit('close')"
    @action="saveTab()"
  >
    <div class="flex flex-col h-full">
      <l-field :label="$t('new-tab.name-label')">
        <l-input
          v-model="newTab.name"
          @focus="focused = false"
          @blur="focused = true"
          size="small"
          :placeholder="$t('new-tab.name-placeholder')"
        />
      </l-field>
      <div class="flex flex-col h-full sm:h-96">
        <l-field :label="$t('new-tab.seats-selector-default')">
          <l-number-input
            v-model="newTab.seats"
            @focus="focused = false"
            @blur="focused = true"
            :max="100"
            :min="0"
            format="integer"
            :placeholder="$t('new-tab.seats-selector-default')"
          />
        </l-field>
        <calculator-keypad
          mode="normal"
          :focused="focused"
          @action="handleAction"
          @number="handleNumber"
        />
      </div>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LField, LInput, LModal, LNumberInput } from '@last/core-ui/paprika'

import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { CustomerInfo, Table } from '@/types'

import CalculatorKeypad from '../Calculator/CalculatorKeypad.vue'
import {
  CalculatorKeypadActions,
  CalculatorKeypadActionsType
} from '../Calculator/CalculatorUtils'

const { t } = useI18n()
const router = useRouter()

const props = withDefaults(
  defineProps<{
    table?: Table
    customer?: CustomerInfo
  }>(),
  {
    isActive: false,
    table: undefined,
    customer: undefined
  }
)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'tabCreated', tabId: string): void
}>()

const tabsStore = useTabsStore()
const configStore = useConfigStore()

const { config } = storeToRefs(configStore)
const { openTab, openTabWithCustomer } = tabsStore

const focused = ref<boolean>(true)
const newTab = ref<{
  lang: string
  name: string | null
  seats: number | null
}>({
  lang: 'ES',
  name: null,
  seats: null
})

onMounted(() => {
  newTab.value.lang = config.value.locationCountryCode
  if (!config.value.organizationConfig.seatSelector) {
    newTab.value.seats = 0
    saveTab()
  }
})

const isComplete = computed(() => newTab.value.seats != null)

const title = computed(() => {
  if (props.table) {
    return t('new-tab.new-tab-table', { name: props.table.name })
  }
  return t('new-tab.new-tab')
})

function saveTab() {
  if (!isComplete.value) return
  let tabId = null
  if (props.customer) {
    tabId = openTabWithCustomer({
      tableId: props.table?.id || null,
      tab: { ...newTab.value, customerInfo: props.customer },
      customer: props.customer
    })
  } else {
    tabId = openTab({
      tableId: props.table?.id || null,
      tab: newTab.value
    })
  }
  emit('tabCreated', tabId)
  emit('close')
  router.push({ name: 'orderManagement', params: { tabId } })
}

function handleAction(action: CalculatorKeypadActionsType): void {
  switch (action) {
    case CalculatorKeypadActions.CLEAR:
      newTab.value.seats = null
      break
    case CalculatorKeypadActions.BACKSPACE:
      newTab.value.seats = +`${newTab.value.seats}`.slice(0, -1) || null
      break
  }
}

function handleNumber(num: number): void {
  let newValue = +`${newTab.value.seats || ''}${num}`
  newValue = Math.min(newValue, 100)
  if (newValue < 1) return
  newTab.value.seats = newValue
}
</script>
