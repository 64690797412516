import { storeToRefs } from 'pinia'

import { useBeepersStore } from '@/store/beepers'

import * as touchIt from './touchIt'

type Provider = 'touchIt'

export class Beeper {
  private static instance: Beeper
  private providers: { [key in Provider]: any }

  constructor() {
    this.providers = {
      touchIt: touchIt
    }
  }

  public static getInstance(): Beeper {
    if (!Beeper.instance) {
      Beeper.instance = new Beeper()
    }
    return Beeper.instance
  }

  public beep(provider: Provider, beeperId: string) {
    const beeperStore = useBeepersStore()
    const { beepersMetadata } = storeToRefs(beeperStore)
    const beeperService = this.providers[provider]
    if (beeperService) {
      beeperService.beep(beeperId, beepersMetadata)
    }
  }

  public assignToTab(provider: Provider, tabId: string) {
    const beeperService = this.providers[provider]
    if (beeperService) {
      beeperService.assignToTab(tabId)
    }
  }
}
