<template>
  <div class="text-sm text-r-500">
    <slot>{{ errorMessage }}</slot>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

const { errorMessage } = inject<any>('field')
</script>
