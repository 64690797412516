<template>
  <div class="w-full h-full flex flex-col p-2 sm:p-4">
    <div class="w-full flex items-center gap-2 mb-2">
      <div v-if="!tabId" class="max-w-48 w-full">
        <l-date-picker
          v-model:date="selectedDate"
          :show-time="false"
          :only-future="true"
          :placeholder="$t('reservations.today')"
          size="small"
        />
      </div>
      <l-search-bar v-model="searchQuery" size="small" />
      <div v-if="!tabId">
        <l-button icon="plus" size="small" @click="editingReservation = {}" />
      </div>
    </div>
    <div
      class="flex flex-col w-full h-full overflow-y-scroll scrolling-touch pb-14"
    >
      <div
        class="flex flex-col"
        v-for="(group, time) in groupReservation"
        :key="time"
      >
        <div
          class="h-10 font-heading text-xl font-bold text-n-0 flex items-center gap-x-1 border-b border-n-600 mt-4"
        >
          {{ d(time, 'time') }}
        </div>
        <reservation-row
          v-for="reservation in group"
          :key="reservation.id"
          :highlight="reservationId === reservation.id"
          :reservation="reservation"
          @click="selectReservation(reservation)"
        />
      </div>
      <empty-case
        v-if="Object.keys(groupReservation).length === 0"
        class="flex-1 align-center justify-center"
        type="empty"
        item-translation-key="reservations.title"
      />
    </div>
  </div>
  <reservation-editor
    v-if="editingReservation"
    :reservation="editingReservation"
    @close="editingReservation = null"
  />
</template>

<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse'
import { isSameDay, roundToNearestMinutes } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onActivated, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import {
  EmptyCase,
  LButton,
  LDatePicker,
  LSearchBar
} from '@last/core-ui/paprika'

import ReservationEditor from '@/components/reservations/ReservationEditor.vue'
import { useNotifications } from '@/composables/useNotifications'
import { useReservationsStore } from '@/store/reservations'
import { useTabsStore } from '@/store/tabs'
import { Reservation } from '@/types'

import ReservationRow from './ReservationRow.vue'

const reservationsStore = useReservationsStore()
const tabsStore = useTabsStore()

const { editReservation } = reservationsStore
const { reservations, nearReservations, sortedReservations } =
  storeToRefs(reservationsStore)

const { t, d } = useI18n()
const route = useRoute()
const router = useRouter()
const { notifyInfo } = useNotifications()

const editingReservation = ref()
const searchQuery = ref('')
const selectedDate = ref<Date | null>(null)

const tabId = computed(() => route.query.tabId as string | null)
const reservationId = computed(() => route.query.reservationId as string | null)

onActivated(() => {
  if (route.query.initialTableId) {
    editingReservation.value = {
      tables: [route.query.initialTableId]
    }
    router.replace({ name: 'reservations' })
  }
  if (route.query.editReservation) {
    editingReservation.value =
      reservationsStore.reservations[route.query.editReservation as string]
    router.replace({ name: 'reservations' })
  }
})

watch(reservationId, () => {
  if (reservationId.value) {
    const reservation = reservations.value[reservationId.value]
    if (
      reservation &&
      !isSameDay(reservation.dateTime, selectedDate.value ?? new Date())
    ) {
      selectedDate.value = new Date(reservation.dateTime)
      nextTick(() => {
        const el = document.getElementById(reservationId.value!)
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    }
  }
})

function selectReservation(reservation: Reservation) {
  if (tabId.value) {
    linkReservation(reservation.id)
  } else {
    editingReservation.value = reservation
  }
}

function linkReservation(reservationId: string) {
  if (!tabId.value) return

  const tab = tabsStore.tabs[tabId.value]
  if (!tab) return

  const tabIdAlreadyLinked = Object.values(sortedReservations.value).find(
    reservation => reservation.tabId === tabId.value
  )
  if (tabIdAlreadyLinked) {
    const oldReservation = {
      ...tabIdAlreadyLinked,
      tabId: null,
      tables: tab.tables
    }
    editReservation(oldReservation)
  }
  const reservation = {
    ...reservations.value[reservationId],
    tabId: tabId.value,
    tables: tab.tables
  }
  editReservation(reservation)
  notifyInfo({
    title: t('link-reservation.reservation-linked')
  })
  router.go(-1)
}

const availableReservations = computed(() => {
  const reservations = tabId.value
    ? nearReservations.value
    : sortedReservations.value
  const date = selectedDate.value ?? new Date()
  return reservations.filter(reservation =>
    isSameDay(reservation.dateTime, date)
  )
})

const { results: searchResults } = useFuse(searchQuery, availableReservations, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['name', 'surname', 'phoneNumber'],
    threshold: 0.4,
    minMatchCharLength: 1
  }
})

const groupReservation = computed(() =>
  searchResults.value
    .map(result => result.item)
    .reduce(
      (acc, reservation) => {
        const groupKey = roundToNearestMinutes(reservation.dateTime, {
          nearestTo: 30,
          roundingMethod: 'trunc'
        })
        const key = groupKey.toISOString()
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(reservation)
        return acc
      },
      {} as Record<string, Reservation[]>
    )
)
</script>
