<template>
  <div class="w-full h-screen flex justify-center items-center">
    <div class="flex w-full flex-col items-center gap-8">
      <img :src="success" class="w-40" />
      <h1 class="text-3xl text-n-800 dark:text-n-0 font-bold font-poppins">
        {{ $t('new-password.new-password.done.title') }}
      </h1>
      <p class="text-sm text-n-300">
        {{ $t('new-password.new-password.done.description') }}
      </p>
      <l-button class="w-full" @click="emit('login')">
        {{ $t('ctas.login') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import success from '@last/core-ui/components/assets/svg/success-light.svg?url'
import { LButton } from '@last/core-ui/paprika'

const emit = defineEmits<{
  login: []
}>()
</script>
