import { Buffer } from 'buffer'

import { Capacitor } from '@capacitor/core'
import { v4 as uuid } from 'uuid'

import { sleep } from '@last/core'

import HttpFactory from '@/drivers/http'

class CashGuardREST {
  constructor() {
    this.initialized = false
    this.currentTransaction = null
  }

  async init(config) {
    let platform = Capacitor.getPlatform()
    if (platform != 'web') {
      this.http = HttpFactory.getHttp()
      this.config = config
      this.authorization = this.getAuthorization(
        config.username,
        config.password
      )
      this.inputAmountListener = config.inputAmountListener
      this.totalAmountListener = config.totalAmountListener
      this.initialized = true
      this.canceled = false
    }
  }

  getAuthorization(username = 'root', password = 'admin') {
    if (!username || !password)
      throw new Error('Username and password are required')
    return `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`
  }

  async getPayedAmount(id) {
    try {
      const response = await this.http.get(
        `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/transactions/${id}`,
        {
          'Content-Type': 'application/json',
          Authorization: this.authorization
        }
      )
      return response.data.paidIn[0]?.amount ?? 0
    } catch {
      return 0
    }
  }

  async charge(amount) {
    if (!this.initialized) return 0
    this.inputAmountListener(0)
    this.currentTransaction = await this.createTransaction()
    await this.initTransaction(this.currentTransaction, amount)
    let payedAmount = 0
    while (amount > 0 && payedAmount < amount && !this.canceled) {
      payedAmount = await this.getPayedAmount(this.currentTransaction)
      this.inputAmountListener(payedAmount)
      await sleep(500)
    }
    if (this.canceled) {
      await this.releaseTransaction(this.currentTransaction)
      this.canceled = false
      return 0
    }
    await this.acceptTransaction(this.currentTransaction, amount)
  }

  async createTransaction() {
    const id = uuid()
    await this.http.put(
      `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/queue/${id}`,
      {
        'Content-Type': 'application/json',
        Authorization: this.authorization
      },
      JSON.stringify({})
    )
    return id
  }

  async initTransaction(id) {
    await this.http.put(
      `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/transactions/${id}?status=initiated&currency=EUR`,
      {
        'Content-Type': 'application/json',
        Authorization: this.authorization
      },
      JSON.stringify({
        status: 'initiated',
        currency: 'EUR'
      })
    )
  }

  async acceptTransaction(id, amount) {
    await this.http.put(
      `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/transactions/${id}?status=amountDue&amountDue=${amount}`,
      {
        'Content-Type': 'application/json',
        Authorization: this.authorization
      },
      JSON.stringify({
        status: 'amountDue',
        amountDue: amount
      })
    )
  }

  async releaseTransaction(id) {
    await this.http.put(
      `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/transactions/${id}?status=repaid&currency=EUR`,
      {
        'Content-Type': 'application/json',
        Authorization: this.authorization
      },
      JSON.stringify({
        status: 'repaid',
        currency: 'EUR'
      })
    )
    await this.http.put(
      `http://${this.config.ip}:${this.config.port}/cashguard/posApi/4.0/transactions/${id}?status=released&currency=EUR`,
      {
        'Content-Type': 'application/json',
        Authorization: this.authorization
      },
      JSON.stringify({
        status: 'released',
        currency: 'EUR'
      })
    )
  }

  async cancel() {
    if (this.canceled) return
    this.canceled = true
  }

  async payIn(amount) {
    return this.charge(amount)
  }

  async payOut(amount) {
    return this.charge(-amount)
  }

  async close() {
    return
  }
}

export default new CashGuardREST()
