import { differenceInDays } from 'date-fns'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useTracker } from '@last/core-ui/paprika'

import {
  checkVerificationCode,
  getBillingStatus,
  getDemoAuth,
  getEmployees,
  loginSupport,
  requestResetPassword,
  verificationCode
} from '@/api/auth'
import { authApi } from '@/api/client'
import { getLocation } from '@/api/locations'
import StorageFactory from '@/drivers/storage'
import { logger, rum } from '@/monitoring'
import { normalizeEmployees } from '@/normalizr'
import { LoginRequest } from '@/openapi'
import mqtt from '@/sync/mqtt'
import { Employee, Location } from '@/types'

interface BillingStatus {
  stillInTrial: boolean
  gracePeriodTriggered: boolean
  endsAt?: string
  posEnabled: boolean
  expired?: boolean
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    const tracker = useTracker()
    const router = useRouter()

    const accessToken = ref<string>()
    const employees = ref<Record<string, Employee>>({})
    const privileges = ref<Record<string, string[]>>({})
    const currentEmployeeId = ref<string>()
    const locationId = ref<string>()
    const location = ref<{
      id: string
      name: string
      coordinates: { latitude: number; longitude: number }
    }>()
    const organizationId = ref<string>()
    const billingStatus = ref<BillingStatus>({
      stillInTrial: false,
      gracePeriodTriggered: false,
      posEnabled: true
    })

    const isAuthenticated = computed(() => !!accessToken.value)

    const listEmployees = computed(() => Object.values(employees.value || {}))

    const getPrivileges = computed(() => privileges.value)

    const currentEmployee = computed<Employee | void>(() => {
      if (!currentEmployeeId.value) return
      return employees.value[currentEmployeeId.value]
    })

    const currentEmployeePrivileges = computed(() => {
      if (!currentEmployeeId.value) return []
      return privileges.value[currentEmployeeId.value]
    })

    const trialExpired = computed(() =>
      Boolean(billingStatus.value.stillInTrial && billingStatus.value.expired)
    )

    const gracePeriodExpired = computed(() =>
      Boolean(
        billingStatus.value.gracePeriodTriggered && billingStatus.value.expired
      )
    )

    const daysLeft = computed(() => {
      if (!billingStatus.value.endsAt) return '-'
      return differenceInDays(new Date(billingStatus.value.endsAt), new Date())
    })

    async function login(loginRequest: LoginRequest) {
      const response = await authApi.login({ loginRequest })
      accessToken.value = response.accessToken
      if (response.locationId) {
        locationId.value = response.locationId
      }
    }

    function askPinForPrivilege(privilege: string) {
      return !listEmployees.value.every(employee =>
        getPrivileges.value[employee.id].includes(privilege)
      )
    }

    function hasPrivilege(employeeId: string, privilege: string) {
      return !!getPrivileges.value[employeeId]?.includes(privilege)
    }

    function setAccessToken(token: string) {
      accessToken.value = token
    }

    function initAnalytics() {
      if (!location.value) return
      const { id, name } = location.value
      tracker.identify({ id, name })
      rum.identify({ id, name })
      logger.identify({ id, name })
    }

    async function supportLogin({
      accessToken,
      selectedOrganizationId,
      selectedLocationId
    }: {
      accessToken: string
      selectedOrganizationId: string
      selectedLocationId: string
    }) {
      const supportUserIsValid = await loginSupport(accessToken)
      if (supportUserIsValid) {
        setAccessToken(accessToken)
        organizationId.value = selectedOrganizationId
        locationId.value = selectedLocationId
        return true
      }
      return false
    }

    async function refreshCurrentLocation() {
      if (!locationId.value) return
      const refreshLocation = await getLocation()
      selectLocation(refreshLocation)
    }

    function selectEmployee(employeeId: string) {
      currentEmployeeId.value = employeeId
    }

    async function refreshEmployees() {
      const employeesData = await getEmployees()
      const employeeData = normalizeEmployees(employeesData) as {
        [key: string]: {
          [key: string]: Employee
        }
      }
      employees.value = employeeData.employees
      const privilegeData = employeesData.reduce(
        (acc, employee) => {
          acc[employee.id] = employee.privileges
          return acc
        },
        {} as { [key: string]: string[] }
      )
      privileges.value = privilegeData
    }

    async function refreshBillingStatus() {
      billingStatus.value = await getBillingStatus()
    }

    function validateEmployeePinWithPrivilege({
      pin,
      privilege
    }: {
      pin: string
      privilege: string
    }) {
      const ret = { pin: false, access: false }
      const currentEmployee = currentEmployeeId.value
        ? employees.value[currentEmployeeId.value]
        : null

      if (currentEmployee && pin === currentEmployee.accessPin) {
        ret.pin = true
        if (currentEmployeePrivileges.value.includes(privilege)) {
          ret.access = true
        }
      } else {
        listEmployees.value.forEach(employee => {
          if (
            employee.accessPin === pin &&
            hasPrivilege(employee.id, privilege)
          ) {
            ret.access = true
            currentEmployeeId.value = employee.id
          }
        })
      }
      return ret
    }

    function selectLocation(newLocation: Location) {
      locationId.value = newLocation.id
      location.value = {
        id: newLocation.id,
        name: newLocation.name,
        coordinates: {
          latitude: newLocation.latitude,
          longitude: newLocation.longitude
        }
      }
      mqtt.subscribe(newLocation.id)
      initAnalytics()
    }

    async function logout() {
      accessToken.value = undefined
      locationId.value = undefined
      await StorageFactory.getStorage().clear()
      router.push({ name: 'login' })
    }

    async function getDemoAuthData() {
      const demoData = await getDemoAuth()
      const { locationId: demoLocationId, organizationId: demoOrganizationId } =
        demoData
      locationId.value = demoLocationId
      organizationId.value = demoOrganizationId
      accessToken.value = 'demoToken'
    }

    function setBillingStatus(status: BillingStatus) {
      billingStatus.value = status
    }

    async function sendVerificationCode(email: string): Promise<string> {
      try {
        return verificationCode(email)
      } catch {
        return ''
      }
    }

    async function validateVerificationCode(
      id: string,
      code: string
    ): Promise<boolean> {
      try {
        return (await checkVerificationCode(id, code)).result
      } catch {
        return false
      }
    }

    async function resetPassword(
      id: string,
      password: string
    ): Promise<boolean> {
      try {
        await requestResetPassword(id, password)
        return true
      } catch {
        return false
      }
    }

    return {
      accessToken,
      employees,
      privileges,
      currentEmployeeId,
      locationId,
      location,
      organizationId,
      billingStatus,

      isAuthenticated,
      listEmployees,
      getPrivileges,
      currentEmployee,
      currentEmployeePrivileges,
      trialExpired,
      gracePeriodExpired,
      daysLeft,

      login,
      askPinForPrivilege,
      setAccessToken,
      supportLogin,
      refreshCurrentLocation,
      selectEmployee,
      refreshEmployees,
      refreshBillingStatus,
      validateEmployeePinWithPrivilege,
      selectLocation,
      logout,
      getDemoAuthData,
      setBillingStatus,
      sendVerificationCode,
      validateVerificationCode,
      resetPassword,
      hasPrivilege
    }
  },
  { persist: true }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
