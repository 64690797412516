<template>
  <full-screen :title="title" @close="router.go(-1)" show-close>
    <div
      class="w-full mx-auto overflow-y-scroll scrolling-touch h-full sm:flex justify-center"
    >
      <div
        class="py-5 relative flex-1 flex flex-col px-4 w-full max-w-[52.25rem]"
      >
        <div class="w-full flex items-center gap-4">
          <l-search-bar v-model="searchQuery" size="large" />
        </div>
        <transition name="switch" mode="out-in">
          <div v-if="filteredBeepers.length" class="w-full">
            <transition-group
              name="beeper-list"
              class="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-4 relative"
              tag="div"
              @before-leave="beforeLeave"
            >
              <div v-for="beeper in filteredBeepers" :key="beeper.id">
                <beeper-component
                  :beeper="beeper"
                  :wiggle="!!tabId"
                  @click="beep(beeper)"
                  class="cursor-pointer"
                />
              </div>
            </transition-group>
          </div>
          <empty-case
            v-else
            class="flex-1 align-center justify-center"
            type="empty"
            item-translation-key="beepers.title"
          />
        </transition>
      </div>
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { EmptyCase, LSearchBar } from '@last/core-ui/paprika'

import BeeperComponent from '@/components/Beeper.vue'
import { useTabs } from '@/composables/useTabs'
import { useBeepersStore } from '@/store/beepers'
import type { Beeper } from '@/types/beeper'

import FullScreen from './core/FullScreen.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const beepersStore = useBeepersStore()
const { beepers } = storeToRefs(beepersStore)
const searchQuery = ref('')

const tabId = computed(() => route.query.tabId as string | null)

function beep(beeper: Beeper) {
  if (!tabId.value) return

  beepersStore.assignBeeper(beeper.id, tabId.value)
  router.go(-1)
}

const filteredBeepers = computed(() => {
  const foundBeepers = Object.values(beepers.value).filter(beeper => {
    const searchString = searchQuery.value.toLowerCase()
    let code
    if (beeper.tabId) {
      const { tab } = useTabs(beeper.tabId)
      code = tab?.value?.code
    }
    return (
      beeper.id.toLowerCase().includes(searchString) ||
      (beeper.tabId && beeper.tabId.toLowerCase().includes(searchString)) ||
      (code && code.toLowerCase().includes(searchString))
    )
  })
  return foundBeepers
})

const title = computed(() =>
  tabId.value ? t('beepers.assign-beeper') : t('beepers.title')
)

function beforeLeave(element: Element) {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.beeper-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.beeper-list-enter-active {
  transition: all 0.4s ease;
}
.beeper-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.beeper-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}

.beeper-list-move {
  transition: all 0.3s ease;
}

.switch-enter-from,
.switch-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.switch-enter-active {
  transition: all 0.3s ease;
}

.switch-leave-active {
  transition: all 0.3s ease;
}
</style>
