<template>
  <tippy
    ref="contextMenu"
    :interactive="true"
    :arrow="false"
    trigger="auto"
    :theme="fluidSize ? 'contextmenufluid' : 'contextmenu'"
    :placement="placement"
    :duration="100"
    :append-to="appendTo"
    :on-click-outside="onClickOutside"
    :hide-on-click="false"
    :on-hidden="() => (open = false)"
    @show="scrollToElement"
  >
    <slot />
    <template #content>
      <div
        class="py-2 z-20 overflow-y-scroll text-n-600 bg-n-0 dark:bg-n-800 dark:text-n-0 dark:border-n-700 rounded-lg"
        :class="[
          {
            'w-screen h-dvh fixed left-0 right-0': fullscreen,
            'max-w-80 max-h-80 border border-n-100': !fullscreen && !fluidSize,
            'border border-n-100': !fullscreen && fluidSize,
            '-mt-1 min-w-32': size === 'x-small',
            'min-w-48': !fluidSize
          },
          [
            limitHeight > 0 ? `max-h-${limitHeight}` : '',
            minWidth > 0 ? `min-w-${minWidth}` : ''
          ]
        ]"
      >
        <div
          class="px-2"
          :class="[size === 'x-small' ? 'text-xs' : 'py-3 text-sm']"
        >
          <slot name="content" :options="options">
            <div
              v-if="search"
              class="fixed inset-x-[1px] top-[1px] bg-n-0 dark:bg-n-800 rounded-lg px-2"
              :class="[size === 'x-small' ? 'pt-2' : 'pt-3 text-sm']"
            >
              <l-search-bar v-model="query" />
            </div>
            <div v-if="search" class="h-9" />
            <div
              v-for="option in filteredOptions"
              :key="option.value"
              ref="optionEl"
            >
              <slot
                name="option"
                v-bind="{
                  option,
                  modeValue: models[option.value],
                  selectOption
                }"
              >
                <dropdown-option
                  :model-value="models[option.value]"
                  :option="option"
                  :multiselect="multiselect"
                  :mark-selected="markSelected"
                  :size="size"
                  :partially-selected="
                    partiallySelectedValues.includes(option.value)
                  "
                  @partial-removed="removeFromPartiallySelected"
                  @update:model-value="value => selectOption(option, value)"
                />
              </slot>
            </div>
          </slot>
          <slot name="extraContent" />
        </div>
      </div>
    </template>
  </tippy>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from 'vue'
import { Tippy, type TippyOptions } from 'vue-tippy'

import { fuzzy } from '@last/core'
import { LSearchBar } from '@last/core-ui/paprika'
import type { Size } from '@last/core-ui/paprika/components/types'

import DropdownOption from './DropdownOption.vue'
import type { Model, Option } from './types'

type Props = {
  options: Option[]
  allowRemove?: boolean
  search?: boolean
  /**
   * A boolean flag that determines whether fuzzy matching is enabled or not.
   * When set to true, the logic will utilize fuzzy matching algorithms,
   * often used to handle approximate matches or tolerate slight differences in input values.
   * When set to false, the logic will enforce strict or exact matching.
   */
  useFuzzy?: boolean
  multiselect?: boolean
  fullscreen?: boolean
  markSelected?: boolean
  /** Change placement of the open content */
  placement?: TippyOptions['placement']
  /** Change default `appendTo` of Tippy */
  appendTo?: TippyOptions['appendTo']
  size?: Size
  blockHideOnClick?: boolean
  /** If setted to true, max width will not be setted */
  fluidSize?: boolean
  /**
   * Specifies a limit for the maximum height of the dropdown content.
   * A value of `0` means there is no height constraint.
   * Can be used to restrict the vertical size of the content for better usability.
   */
  limitHeight?: number
  /**
   * Specifies a limit for the minimum width of the dropdown content.
   * A value of `0` means there is no width constraint.
   * Can be used to restrict the horizontal size of the content for better usability.
   */
  minWidth?: number
}

const props = withDefaults(defineProps<Props>(), {
  markSelected: true,
  placement: 'bottom-start',
  size: 'medium',
  blockHideOnClick: false,
  appendTo: () => document.body,
  fluidSize: false,
  limitHeight: 0,
  minWidth: 0,
  useFuzzy: true
})

const selected = defineModel<(string | number)[]>({
  default: [],
  required: false
})

const partiallySelectedValues = defineModel<(string | number)[]>(
  'partiallySelectedValues',
  {
    default: []
  }
)

const open = defineModel<boolean>('open')
const contextMenuRef = useTemplateRef<typeof Tippy>('contextMenu')
const listOptions = useTemplateRef('optionEl')

function onClickOutside(): void {
  if (!!contextMenuRef.value && !props.blockHideOnClick) {
    contextMenuRef?.value?.hide()
  }
}

const query = ref('')

const models = computed(() => {
  return props.options.reduce(
    (acc, option) => {
      acc[option.value] = {
        value: option.value,
        selected: selected.value.includes(option.value),
        children: option.children
          ? option.children.map(child => ({
              value: child.value,
              selected: selected.value.includes(child.value)
            }))
          : []
      }
      return acc
    },
    {} as Record<string, Model>
  )
})

const filteredOptions = computed(() => {
  if (!query.value) return props.options
  return props.options.flatMap(option => {
    if (props.useFuzzy) {
      if (fuzzy(option.label, query.value)) return [option]
    } else {
      if (option.label.toLowerCase().includes(query.value.toLowerCase()))
        return [option]
    }

    if (!option.children) return []
    const filteredChildren = option.children.filter(child =>
      fuzzy(child.label, query.value)
    )
    return filteredChildren.length
      ? [{ ...option, children: filteredChildren }]
      : []
  })
})

function selectOption(option: Option, selection: Model) {
  const optionValues = [
    option.value,
    ...(option.children?.map(child => child.value) || [])
  ]
  const selectedValues = selection.selected ? [selection.value] : []
  for (const child of selection.children ?? []) {
    if (child.selected) selectedValues.push(child.value)
  }
  if (!props.multiselect) {
    selected.value = selectedValues
    if (!props.blockHideOnClick) {
      open.value = false
    }
  } else {
    const newSelected = selected.value
      .filter(value => !optionValues.includes(value))
      .concat(selectedValues)
    selected.value = newSelected
  }
}

function scrollToElement() {
  const selectedIndex = Object.values(models.value).findIndex(
    model => model.selected
  )
  if (selectedIndex === -1) return
  nextTick(() => {
    listOptions.value?.[selectedIndex]?.scrollIntoView({
      block: 'center'
    })
  })
}

watch(open, value => {
  if (value) {
    contextMenuRef.value?.show()
  } else {
    contextMenuRef.value?.hide()
  }
})

onMounted(() => {
  if (open.value) {
    contextMenuRef.value?.show()
  }
})

function removeFromPartiallySelected(value: string | number): void {
  const index = partiallySelectedValues.value.indexOf(value)
  if (index === -1) return
  partiallySelectedValues.value.splice(index, 1)
}
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='contextmenu'],
.tippy-box[data-theme~='contextmenufluid'] {
  background: none !important;
}

.tippy-box[data-theme~='contextmenu'] .tippy-content,
.tippy-box[data-theme~='contextmenufluid'] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='contextmenu'][data-animation='fade'][data-state='hidden'],
.tippy-box[data-theme~='contextmenufluid'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}

.tippy-box[data-theme~='contextmenufluid'] {
  max-width: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
