<template>
  <div>
    <div class="flex flex-row items-center">
      <l-search-bar
        v-model="query"
        class="flex-1"
        size="medium"
        :debounce-time="500"
        @update:model-value="updateCompanies()"
      />
      <l-button icon="plus" class="ml-4" @click="createCompany()" />
    </div>
    <transition-group name="companies-list" mode="out-in">
      <div
        v-for="company in companies"
        :key="company.id"
        class="flex flex-row justify-between bg-n-700 rounded-[10px] py-6 px-4 my-4 cursor-pointer"
        @click="selectCompany(company)"
      >
        <div>
          <div class="font-heading text-base">{{ company.name }}</div>
          <div class="font-body text-sm font-normal text-n-200">
            {{ `NIF: ${company.taxId}` }}
          </div>
        </div>
        <div class="text-v-300 pl-6" @click.stop="editCompany(company)">
          <l-icon name="edit" />
        </div>
      </div>
    </transition-group>
    <div v-if="companies.length === 0" class="mt-8">
      <empty-case type="empty" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { EmptyCase, LButton, LIcon, LSearchBar } from '@last/core-ui/paprika'

import { getCompanies } from '@/api/companies'
import { useNotifications } from '@/composables/useNotifications'
import { type Company } from '@/types'

const { t } = useI18n()
const { notifyError } = useNotifications()

const emit = defineEmits(['modify', 'select'])

const companies = ref<Company[]>([])

const query = ref('')

onMounted(() => {
  updateCompanies()
})

async function updateCompanies() {
  try {
    companies.value = await getCompanies(query.value)
  } catch {
    notifyError({
      title: t('companies-list.error-fetching-companies')
    })
  }
}

function createCompany() {
  emit('modify', null)
}

function editCompany(company: Company) {
  emit('modify', company)
}

function selectCompany(company: Company) {
  emit('select', company)
}
</script>

<style scoped>
.companies-list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.companies-list-enter-active {
  transition: all 0.3s ease;
}

.companies-list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.companies-list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}

.companies-list-move {
  transition: all 0.2s ease;
}
</style>
