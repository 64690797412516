import { toRaw } from 'vue'

import app from '@/app'
import { useNotifications } from '@/composables/useNotifications'
import i18n from '@/i18n'
import { rum } from '@/monitoring'
import { DataphoneConfig } from '@/types/config'

import { type IDataphone } from './dataphone'

export type RedsysChargeResponse = {
  redsys: {
    rtsId: string
    orderNumber: string
  }
  receipt: {
    clientCard: string
    orderNumber: string
    expiration: string
    commerce: string
    resCode: string
    terminal: string
    transactionNum: string
    tagApp: string
    idApp: string
    resVerification: string
    resCodeAuto: string
  }
}

export class RedsysDataphone implements IDataphone {
  public initialized: boolean = false
  public canCancel: boolean = false

  private initializing: boolean = false
  private config: DataphoneConfig
  private ipcRenderer: any

  constructor(config: DataphoneConfig) {
    this.initialized = false
    this.initializing = false
    this.config = config
  }

  async init() {
    if (!app.isElectron) return
    const { ipcRenderer } = window.require('electron')
    this.ipcRenderer = ipcRenderer
    if (!this.initializing) {
      this.initializing = true
      try {
        await this.ipcRenderer.invoke('dataphone:init', toRaw(this.config))
        this.initialized = true
      } catch (error) {
        rum.addError(error)
        const { notifyError } = useNotifications()
        notifyError({
          title: i18n.global.t('dataphone.bad-config-title'),
          description: i18n.global.t('dataphone.bad-config-subtitle')
        })
      }
    }
    this.initializing = false
  }

  async charge({
    amount,
    paymentId
  }: {
    amount: number
    paymentId: string
  }): Promise<RedsysChargeResponse> {
    if (!this.initialized) {
      throw new Error('Dataphone not initialized')
    }
    const res = await this.ipcRenderer.invoke(
      'dataphone:charge',
      amount,
      paymentId
    )
    if (!res?.chargeAuthorised) {
      throw new Error('Dataphone charging failed')
    }
    return res?.metadata
  }

  cancel(): Promise<void> {
    if (!this.initialized) {
      throw new Error('Dataphone not initialized')
    }
    return this.ipcRenderer.invoke('dataphone:cancel')
  }

  async refund(payment: any) {
    if (!this.initialized) {
      throw new Error('Dataphone not initialized')
    }
    const res = await this.ipcRenderer.invoke(
      'dataphone:refund',
      JSON.parse(JSON.stringify(payment))
    )
    return res
  }
}
