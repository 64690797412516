<template>
  <div
    class="fixed top-0 grid w-full h-full overflow-hidden pointer-events-none z-10 pt-safe"
  >
    <transition-group
      class="h-full flex flex-col w-[20rem] mt-4 overflow-y-auto self-start justify-self-end items-center"
      tag="div"
      name="list"
      appear
      @before-leave="beforeLeave"
    >
      <l-notification
        v-for="notification in notifications"
        :key="notification.id"
        class="notification mb-2 pointer-events-auto"
        v-bind="notification"
        @close="() => deleteNotification(notification.id)"
        @cancel="() => cancelProgress(notification)"
      />
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import LNotification from './LNotification.vue'
import { type Notification } from './types'

type Props = {
  notifications: Notification[]
}

const emit = defineEmits(['update:notifications', 'cancel'])

const props = withDefaults(defineProps<Props>(), {
  notifications: () => []
})

const deleteNotification = (id: string) => {
  const updatedNotifications = props.notifications.filter(
    notification => notification.id !== id
  )
  emit('update:notifications', updatedNotifications)
}

const cancelProgress = (notification: Notification) => {
  emit('cancel', notification)
  deleteNotification(notification.id)
}

watch(
  () => props.notifications,
  newNotifications => {
    newNotifications.forEach(notification => {
      if (notification.progress || notification.progress === 0) {
        const progress = Number(notification.progress)
        if (progress >= 100) {
          setTimeout(() => {
            deleteNotification(notification.id)
          }, 500)
        }
        return
      }
      setTimeout(() => {
        deleteNotification(notification.id)
      }, 3000)
    })
  },
  { deep: true }
)

const beforeLeave = (element: Element) => {
  const el = element as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style scoped>
.list-enter-from {
  opacity: 0;
  transform: scale(0.6);
}

.list-enter-active {
  transition: all 0.4s ease;
}

.list-leave-to {
  opacity: 0;
  transform: scale(0.6);
}

.list-leave-active {
  transition: all 0.3s ease;
  position: absolute;
}

.list-move {
  transition: all 0.3s ease;
}
</style>
