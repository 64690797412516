/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Login200Response
 */
export interface Login200Response {
    /**
     * 
     * @type {string}
     * @memberof Login200Response
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof Login200Response
     */
    locationId?: string;
}

/**
 * Check if a given object implements the Login200Response interface.
 */
export function instanceOfLogin200Response(value: object): value is Login200Response {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    return true;
}

export function Login200ResponseFromJSON(json: any): Login200Response {
    return Login200ResponseFromJSONTyped(json, false);
}

export function Login200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Login200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
    };
}

export function Login200ResponseToJSON(json: any): Login200Response {
    return Login200ResponseToJSONTyped(json, false);
}

export function Login200ResponseToJSONTyped(value?: Login200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'accessToken': value['accessToken'],
        'locationId': value['locationId'],
    };
}

