<template>
  <div class="w-full h-screen flex justify-center items-center">
    <div class="flex w-full flex-col gap-8">
      <div class="flex flex-col gap-2">
        <h1 class="text-3xl text-n-800 dark:text-n-0 font-bold font-poppins">
          {{ $t('new-password.new-password.password-input.title') }}
        </h1>
        <p class="text-sm text-n-300">
          {{ $t('new-password.new-password.password-input.description') }}
        </p>
      </div>
      <div class="flex-col w-full">
        <l-form-field name="email">
          <l-form-label :label="$t('inputs.email-label')" />
          <l-input :model-value="email" read-only type="text" />
        </l-form-field>
        <l-form-field name="password">
          <l-form-label mandatory :label="$t('inputs.password-label')" />
          <l-password-input
            autocomplete="new-password"
            id="password"
            v-model="firstPassword"
            :placeholder="$t('inputs.password-placeholder')"
          />
        </l-form-field>
        <l-form-field name="repeat-password">
          <l-form-label :label="$t('inputs.repeat-password-label')" mandatory />
          <l-password-input
            autocomplete="new-password"
            id="confirm-password"
            v-model="secondPassword"
            :placeholder="$t('inputs.repeat-password-placeholder')"
          />
          <l-form-error v-if="somethingWrong">
            {{ $t('new-password.new-password.password-input.error') }}
          </l-form-error>
        </l-form-field>
      </div>
      <l-button class="w-full" :disabled="!passwordsMatch" @click="submit">
        {{ $t('ctas.save') }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  LButton,
  LFormError,
  LFormField,
  LFormLabel,
  LInput,
  LPasswordInput
} from '@last/core-ui/paprika'

const props = defineProps<{
  email: string
  recoverId: string
  token: string
  newPassword: (data: {
    token: string
    recoverId: string
    password: string
  }) => Promise<void>
}>()

const emit = defineEmits<{
  done: []
}>()

const firstPassword = ref<string>()
const secondPassword = ref<string>()
const somethingWrong = ref(false)

async function submit() {
  if (!passwordsMatch.value) return
  if (!firstPassword.value) return
  try {
    somethingWrong.value = false
    await props.newPassword({
      token: props.token,
      recoverId: props.recoverId,
      password: firstPassword.value
    })
  } catch {
    somethingWrong.value = true
    return
  }
  emit('done')
}

const passwordsMatch = computed(
  () => firstPassword.value && firstPassword.value === secondPassword.value
)
</script>
