/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequestOneOfDeviceInfo
 */
export interface LoginRequestOneOfDeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    os: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    osVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    appVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequestOneOfDeviceInfo
     */
    appBuild: string;
}

/**
 * Check if a given object implements the LoginRequestOneOfDeviceInfo interface.
 */
export function instanceOfLoginRequestOneOfDeviceInfo(value: object): value is LoginRequestOneOfDeviceInfo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('platform' in value) || value['platform'] === undefined) return false;
    if (!('model' in value) || value['model'] === undefined) return false;
    if (!('os' in value) || value['os'] === undefined) return false;
    if (!('osVersion' in value) || value['osVersion'] === undefined) return false;
    if (!('appVersion' in value) || value['appVersion'] === undefined) return false;
    if (!('appBuild' in value) || value['appBuild'] === undefined) return false;
    return true;
}

export function LoginRequestOneOfDeviceInfoFromJSON(json: any): LoginRequestOneOfDeviceInfo {
    return LoginRequestOneOfDeviceInfoFromJSONTyped(json, false);
}

export function LoginRequestOneOfDeviceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequestOneOfDeviceInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'platform': json['platform'],
        'model': json['model'],
        'os': json['os'],
        'osVersion': json['osVersion'],
        'appVersion': json['appVersion'],
        'appBuild': json['appBuild'],
    };
}

export function LoginRequestOneOfDeviceInfoToJSON(json: any): LoginRequestOneOfDeviceInfo {
    return LoginRequestOneOfDeviceInfoToJSONTyped(json, false);
}

export function LoginRequestOneOfDeviceInfoToJSONTyped(value?: LoginRequestOneOfDeviceInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'platform': value['platform'],
        'model': value['model'],
        'os': value['os'],
        'osVersion': value['osVersion'],
        'appVersion': value['appVersion'],
        'appBuild': value['appBuild'],
    };
}

