<template>
  <div class="flex flex-col items-center justify-center gap-2">
    <div
      ref="upEl"
      tabindex="0"
      role="button"
      data-testid="up"
      @click="increment"
    >
      <l-icon
        name="bracket-up"
        :size="size"
        class="text-v-500 dark:text-v-300 cursor-pointer"
      />
    </div>
    <div class="w-[3.2rem]">
      <l-input
        ref="inputRef"
        type="number"
        :model-value="model"
        @update:model-value="onUpdateValue"
        @keyup="$emit('keyup', $event)"
        @blur="validate"
        :size="size === 'small' ? 'small' : 'medium'"
        :placeholder="props.default ?? '00'"
      />
    </div>
    <div ref="downEl" tabindex="0" role="button" @click="decrement">
      <l-icon
        name="bracket-down"
        :size="size"
        class="text-v-500 dark:text-v-300 cursor-pointer"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { LIcon, LInput } from '@last/core-ui/paprika'

import type { Size } from '../types'
import { onHold } from './onHold'

const props = defineProps<{
  size: Size
  max: number
  step: number
  default?: string | null
}>()

const model = defineModel<string | null>({
  default: null
})

defineEmits<{
  keyup: [KeyboardEvent]
}>()

const upEl = useTemplateRef('upEl')
const downEl = useTemplateRef('downEl')
const inputRef = useTemplateRef('inputRef')

onHold(upEl, () => increment())

onHold(downEl, () => decrement())

function increment() {
  const value = parseInt(model.value ?? props.default ?? '00') + props.step
  if (value < props.max) {
    model.value = value.toString().padStart(2, '0')
  } else {
    model.value = '00'
  }
}

function decrement() {
  const value = parseInt(model.value ?? props.default ?? '00') - props.step
  if (value >= 0) {
    model.value = value.toString().padStart(2, '0')
  } else {
    model.value = (props.max - Math.abs(value)).toString().padStart(2, '0')
  }
}

function onUpdateValue(value: string | number | null) {
  if (value && value.toString().length >= 2) {
    const parsedValue = Math.abs(parseInt(value.toString()))
    if (parsedValue > props.max) {
      model.value = props.max.toString()
    } else {
      model.value = parsedValue.toString()
    }
    inputRef.value!.inputRef.value = model.value
  }
}

function validate(event: FocusEvent) {
  const rawValue = (event.currentTarget as HTMLInputElement).value

  if (!rawValue) return

  if (rawValue === '') {
    model.value = '00'
  }

  const value = parseInt(rawValue)
  if (value.toString().length === 1) {
    model.value = value.toString().padStart(2, '0')
  }
}

defineExpose({
  focus: () => {
    const input = inputRef.value
    input?.focus()
  }
})
</script>
