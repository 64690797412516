import { RouteRecordRaw } from 'vue-router'

import AboutPopup from '@/components/AboutPopup.vue'
import Beepers from '@/components/Beepers.vue'
import Checkout from '@/components/checkout/Checkout.vue'
import DeliveryCheckout from '@/components/checkout/DeliveryCheckout.vue'
import FastCheckoutView from '@/components/checkout/FastCheckout.vue'
import CouriersReport from '@/components/couriersReport/CouriersReport.vue'
import CloseDelivery from '@/components/delivery/CloseDelivery.vue'
import EmployeeSelector from '@/components/EmployeeSelector.vue'
import HardwareSelector from '@/components/HardwareSelector/HardwareSelector.vue'
import Home from '@/components/Home.vue'
import MenuAvailability from '@/components/MenuAvailability/MenuAvailability.vue'
import DemoPos from '@/components/onboarding/DemoPos.vue'
import FreeTrialExpired from '@/components/onboarding/FreeTrialExpired.vue'
import GracePeriodExpired from '@/components/onboarding/GracePeriodExpired.vue'
import LocationSelector from '@/components/onboarding/LocationSelector.vue'
import Login from '@/components/onboarding/Login.vue'
import NewPassword from '@/components/onboarding/NewPassword.vue'
import OverTheLimit from '@/components/onboarding/OverTheLimit.vue'
import SupportLogin from '@/components/onboarding/SupportLogin.vue'
import OrderManagement from '@/components/ordering/OrderManagement.vue'
import RealTimeReport from '@/components/reports/RealTimeReport.vue'
import LinkReservations from '@/components/reservations/LinkReservations.vue'
import Reservations from '@/components/reservations/Reservations.vue'
import SearchView from '@/components/Search/SearchView.vue'
import EndShift from '@/components/shift/EndShift.vue'
import PayInPayOut from '@/components/shift/PayInPayOut.vue'
import StartShift from '@/components/shift/StartShift.vue'
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import WrongTime from '@/components/WrongTime.vue'
import { useHome } from '@/composables/useHome'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'employees' }
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'pos',
    path: '/pos',
    component: Home,
    redirect() {
      const { checkRedirect } = useHome()
      return checkRedirect()
    },
    children: [
      {
        name: 'floorplan',
        path: 'floorplan',
        component: TableTabs
      },
      {
        name: 'delivery',
        path: 'delivery',
        component: TabBrowser,
        props: {
          tabsType: 'delivery'
        }
      },
      {
        name: 'takeAway',
        path: 'takeAway',
        component: TabBrowser,
        props: {
          tabsType: 'takeAway'
        }
      },
      {
        name: 'tabs',
        path: 'tabs',
        component: TabBrowser,
        props: {
          tabsType: 'all'
        }
      },
      {
        name: 'fastMode',
        path: 'fast-mode/:tabId?',
        component: OrderManagement
      },
      {
        name: 'reservations',
        path: 'reservations',
        component: Reservations
      }
    ]
  },
  {
    name: 'linkReservations',
    path: '/reservations',
    component: LinkReservations
  },
  {
    name: 'demoPos',
    path: '/demo-pos',
    component: DemoPos
  },
  {
    name: 'orderManagement',
    path: '/pos/order-management/:tabId',
    component: OrderManagement
  },
  {
    name: 'checkout',
    path: '/pos/checkout/:tabId',
    component: Checkout
  },
  {
    name: 'fastCheckout',
    path: '/pos/fast-checkout/:tabId',
    component: FastCheckoutView,
    props: true
  },
  {
    name: 'deliveryCheckout',
    path: '/pos/checkout/delivery/:tabId',
    component: DeliveryCheckout,
    props: true
  },
  {
    name: 'supportLogin',
    path: '/support-login',
    component: SupportLogin
  },
  {
    name: 'employees',
    path: '/employees',
    component: EmployeeSelector
  },
  {
    name: 'startShift',
    path: '/start-shift',
    component: StartShift
  },
  {
    name: 'locations',
    path: '/locations',
    component: LocationSelector
  },
  {
    name: 'realTimeReport',
    path: '/real-time-report',
    component: RealTimeReport
  },
  {
    name: 'couriersReport',
    path: '/couriers-report',
    component: CouriersReport
  },
  {
    name: 'search',
    path: '/search',
    component: SearchView
  },
  {
    name: 'endShift',
    path: '/end-shift',
    component: EndShift
  },
  {
    name: 'payInPayOut',
    path: '/pay-in-pay-out',
    component: PayInPayOut
  },
  {
    name: 'products',
    path: '/products',
    component: MenuAvailability
  },
  {
    name: 'overTheLimit',
    path: '/over-the-limit',
    component: OverTheLimit
  },
  {
    name: 'closeDelivery',
    path: '/close-delivery',
    component: CloseDelivery
  },
  {
    name: 'hardwareSelector',
    path: '/hardware-selector',
    component: HardwareSelector
  },
  {
    name: 'freeTrialExpired',
    path: '/free-trial-expired',
    component: FreeTrialExpired
  },
  {
    name: 'gracePeriodExpired',
    path: '/grace-period-expired',
    component: GracePeriodExpired
  },
  {
    name: 'about',
    path: '/about',
    component: AboutPopup
  },
  {
    name: 'wrongTime',
    path: '/wrong-time',
    component: WrongTime
  },
  {
    name: 'beepers',
    path: '/beepers',
    component: Beepers
  },
  {
    name: 'newPassword',
    path: '/new-password/:recoverId?/:token?',
    component: NewPassword
  }
]
