<template>
  <div class="flex flex-col justify-between items-center gap-8">
    <div class="flex w-full flex-col gap-8">
      <div>
        <h1 class="text-3xl text-n-800 dark:text-n-0 font-bold font-poppins">
          {{ $t('new-password.email.title') }}
        </h1>
        <p class="text-sm text-n-300 mt-2">
          {{ $t('new-password.email.description') }}
        </p>
      </div>
      <l-field :label="$t('inputs.email-label')" class="w-full">
        <l-input v-model="email" />
      </l-field>
    </div>
    <l-button
      class="w-full"
      @click="sendValidationToken"
      :disabled="!isValidEmail || loading"
    >
      {{ $t('ctas.continue') }}
    </l-button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { lastUtils } from '@last/core'
import { LButton, LField, LInput } from '@last/core-ui/paprika'

const email = ref<string>()
const loading = ref(false)

const props = defineProps<{
  verificationToken: (email: string) => Promise<void>
}>()

const emit = defineEmits<{
  emailSent: []
}>()

async function sendValidationToken() {
  loading.value = true
  if (!email.value) return
  await props.verificationToken(email.value)
  loading.value = false
  emit('emailSent')
}

const isValidEmail = computed(
  () => email.value && lastUtils.isEmail(email.value)
)
</script>
