<template>
  <div
    class="fixed bottom-safe pb-4 pr-4 right-0 text-n-0 pointer-events-auto flex sm:flex-col"
  >
    <l-tooltip trigger="click" placement="auto">
      <div
        class="p-3 rounded-l-full sm:rounded-t-full sm:rounded-b-none transition-colors"
        :class="{
          'bg-v-300 pointer-events-none': modelValue === 'time',
          'bg-n-700': modelValue !== 'time'
        }"
        @click="emitModeChange('time')"
      >
        <l-icon name="time" size="medium" />
      </div>
      <template #content>
        {{ $t('reservations.time') }}
      </template>
    </l-tooltip>
    <l-tooltip trigger="click" placement="auto">
      <div
        class="p-3 transition-colors cursor-pointer"
        :class="{
          'bg-v-300 pointer-events-none': modelValue === 'diners',
          'bg-n-700': modelValue !== 'diners'
        }"
        @click="emitModeChange('diners')"
      >
        <l-icon name="diners" size="medium" />
      </div>
      <template #content>
        {{ $t('reservations.diners') }}
      </template>
    </l-tooltip>
    <l-tooltip trigger="click" placement="auto">
      <div
        class="p-3 rounded-r-full sm:rounded-t-none sm:rounded-b-full transition-colors cursor-pointer"
        :class="{
          'bg-v-300 pointer-events-none': props.modelValue === 'amount',
          'bg-n-700': modelValue !== 'amount'
        }"
        @click="emitModeChange('amount')"
      >
        <l-icon name="euro" size="medium" />
      </div>
      <template #content>
        {{ $t('end-shift.amount') }}
      </template>
    </l-tooltip>

    <l-tooltip trigger="click" placement="auto-end">
      <div
        class="p-3 rounded-full ml-4 sm:mt-4 sm:ml-0 bg-n-700 cursor-pointer"
      >
        <l-icon name="status" size="medium" />
      </div>
      <template #content>
        <div class="flex flex-col bg-n-600 rounded-xl px-4 py-4">
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 bg-n-0 rounded-full" />
            {{ $t('floorplan.free') }}
          </div>
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 rounded-full bg-b-500" />
            {{ $t('floorplan.occupied') }}
          </div>
          <div class="flex items-center text-n-200 mb-4">
            <div class="w-4 h-4 mr-2 rounded-full bg-r-300" />
            {{ $t('floorplan.to-pay') }}
          </div>
          <div class="flex items-center text-n-200">
            <div class="w-4 h-4 mr-2 rounded-full bg-y-500" />
            {{ $t('floorplan.reserved') }}
          </div>
          <template v-if="config.enableKitchenOrders">
            <div class="flex items-center text-n-200 mt-4">
              <div class="w-4 h-4 p-1 mr-2 bg-n-0 rounded-full">
                <div class="w-full h-full mr-2 rounded-full bg-y-500" />
              </div>
              {{ $t('floorplan.not-sent-to-kitchen') }}
            </div>
          </template>
        </div>
      </template>
    </l-tooltip>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { LIcon, LTooltip, useTracker } from '@last/core-ui/paprika'

import { useConfigStore } from '@/store/config'

const tracker = useTracker()

const props = withDefaults(
  defineProps<{
    modelValue: 'time' | 'diners' | 'amount'
  }>(),
  { modelValue: 'time' }
)

const emit = defineEmits(['update:modelValue'])

const { config } = storeToRefs(useConfigStore())

function emitModeChange(mode: string) {
  tracker.track(`floorplanMode:${mode}`)
  emit('update:modelValue', mode)
}
</script>
