<template>
  <input-wrapper
    ref="inputWrapperRef"
    v-bind="{ ...$props, ...$attrs }"
    v-model="model"
    @click="focus"
  >
    <template #left>
      <template v-if="slots['left']">
        <component :is="slots['left']" />
      </template>
      <l-icon
        v-if="icon && iconPosition == 'left' && !slots['left']"
        :name="icon"
        class="text-n-800 dark:text-n-200 ml-2"
      />
    </template>
    <template #right>
      <template v-if="slots['right']">
        <component :is="slots['right']" />
      </template>
      <l-icon
        v-if="icon && iconPosition == 'right' && !slots['right']"
        :name="icon"
        class="text-n-800 dark:text-n-200 mr-2"
      />
    </template>
  </input-wrapper>
</template>

<script setup lang="ts">
import {
  computed,
  useSlots,
  useTemplateRef,
  type InputHTMLAttributes
} from 'vue'

import type { LIconName } from '../icon.type'
import LIcon from '../LIcon.vue'
import { type InputProps } from './inputTypes'
import InputWrapper from './InputWrapper.vue'

type Props = InputProps & {
  icon?: LIconName
  iconPosition?: 'left' | 'right'
} & /* @vue-ignore */ InputHTMLAttributes

withDefaults(defineProps<Props>(), {
  icon: undefined,
  iconPosition: 'right',
  maxLength: 250
})

const slots = useSlots()

const inputWrapperRef = useTemplateRef('inputWrapperRef')

const model = defineModel<string | number | null>({
  default: null
})

function focus(): void {
  inputWrapperRef.value?.focusInput()
  setTimeout(() => {
    const target = inputWrapperRef.value
    target?.inputRef?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, 200)
}

const inputRef = computed(() => inputWrapperRef.value!.inputRef!)

defineExpose({ focus, inputRef })
</script>
