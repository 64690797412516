<template>
  <div class="flex flex-col h-screen pb-safe overflow-hidden">
    <top-bar>
      <template #center>
        <top-menu />
      </template>
    </top-bar>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { useIdle } from '@vueuse/core'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

import TopBar from '@/components/core/TopBar.vue'
import TopMenu from '@/components/TopMenu.vue'
import { useHome } from '@/composables/useHome'
import { useConfigStore } from '@/store/config'

const { config } = useConfigStore()
const { activeMenu } = useHome()
const router = useRouter()

router.afterEach(route => {
  if (route.matched[0].name === 'pos') {
    activeMenu.value = route.name as string
  }
})

if (config.timeoutSeconds) {
  const { idle } = useIdle(config.timeoutSeconds * 1000)

  watch(idle, isIdle => {
    if (isIdle && router.currentRoute.value.name !== 'employees') {
      router.push({ name: 'employees' })
    }
  })
}
</script>
