<template>
  <div class="flex flex-col h-screen pb-safe overflow-hidden">
    <top-bar>
      <template #left>
        <top-menu />
      </template>
      <template #right>
        <l-button
          size="medium"
          icon="hamburger-3-lines"
          type="text"
          color="white"
          @click="showMenu = true"
        />
      </template>
    </top-bar>
    <side-menu :open="showMenu" @close="showMenu = false" />

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { LButton } from '@last/core-ui/paprika'

import TopMenu from '@/components/TopMenu.vue'
import { useHome } from '@/composables/useHome'
import TopBar from '@/mobile/components/TopBar.vue'

import SideMenu from '../components/SideMenu.vue'

const { activeMenu } = useHome()
const router = useRouter()

const showMenu = ref(false)

router.afterEach(route => {
  if (route.matched[0].name === 'pos') {
    activeMenu.value = route.name as string
  }
})
</script>
