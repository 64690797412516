<template>
  <div
    ref="lottieAnimationContainer"
    class="lottie-animation-container"
    :style="getCurrentStyle"
    @mouseenter="hoverStarted"
    @mouseleave="hoverEnded"
  ></div>
</template>

<script setup lang="ts">
import type {
  AnimationDirection,
  AnimationItem,
  AnimationSegment
} from 'lottie-web'
import { computed, useTemplateRef, watch, watchEffect } from 'vue'

interface Props {
  animationData?: any
  loop?: boolean | number
  autoPlay?: boolean
  width?: number | string
  height?: number | string
  speed?: number
  delay?: number
  direction?: string
  pauseOnHover?: boolean
  playOnHover?: boolean
  backgroundColor?: string
  pauseAnimation?: boolean
  noMargin?: boolean
  scale?: number
  renderer?: string
  rendererSettings?: any
  assetsPath?: string
}

const props = withDefaults(defineProps<Props>(), {
  animationData: () => ({}),
  loop: true,
  autoPlay: true,
  width: '100%',
  height: '100%',
  speed: 1,
  delay: 0,
  direction: 'forward',
  pauseOnHover: false,
  playOnHover: false,
  backgroundColor: 'transparent',
  pauseAnimation: false,
  noMargin: false,
  scale: 1,
  renderer: 'svg',
  rendererSettings: () => ({}),
  assetsPath: ''
})

const emits = defineEmits<{
  onComplete: []
  onLoopComplete: []
  onEnterFrame: []
  onSegmentStart: []
  onAnimationLoaded: []
}>()

const lottieAnimationContainer = useTemplateRef('lottieAnimationContainer')

let internalAnimationData: any
let lottieAnimation: AnimationItem | null = null
let internalDirection: AnimationDirection = 1

watchEffect(async () => {
  // track and ensure that `lottieAnimationContainer` is mounted
  // fix: #502
  if (!lottieAnimationContainer.value) return

  if (JSON.stringify(props.animationData) !== JSON.stringify({})) {
    // clone the animationData to prevent it from being mutated
    internalAnimationData = JSON.parse(JSON.stringify(props.animationData))
  } else {
    throw new Error('You must provide either animationLink or animationData')
  }

  loadLottie()
})

const loadLottie = async () => {
  // check if the lottieAnimationContainer has been created
  if (!lottieAnimationContainer.value) return

  // check if the animationData has been loaded
  if (!internalAnimationData) return

  // destroy the animation if it already exists
  lottieAnimation?.destroy()

  // reset the lottieAnimation variable
  lottieAnimation = null

  // set the autoplay and loop variables
  let autoPlay = props.autoPlay
  let loop = props.loop

  if (props.playOnHover) {
    autoPlay = false
  }

  // drop the loop by one
  // this is because lottie-web will loop one extra time
  if (typeof loop === 'number') {
    if (loop > 0) {
      loop = loop - 1
    }
  }

  // if the delay is greater than 0, we need to set autoplay to false
  if (props.delay > 0) {
    autoPlay = false
  }

  const lottieAnimationConfig: any = {
    container: lottieAnimationContainer.value,
    renderer: props.renderer,
    loop: loop,
    autoplay: autoPlay,
    animationData: internalAnimationData,
    assetsPath: props.assetsPath
  }

  // check if the custom rendererSettings is provided
  if (props.rendererSettings) {
    lottieAnimationConfig.rendererSettings = props.rendererSettings
  }

  /**
   * If the scale is not 1, we need to set `viewBoxOnly` to true
   * This will remove the translate3d transform from the svg and
   * will allow us to scale the svg using css transform scale
   */
  if (props.scale !== 1) {
    lottieAnimationConfig.rendererSettings = {
      ...lottieAnimationConfig.rendererSettings,
      viewBoxOnly: true
    }
  }

  // actually load the animation
  const Lottie = await import('lottie-web/build/player/lottie_light').then(
    module => module.default
  )
  lottieAnimation = Lottie.loadAnimation(lottieAnimationConfig)

  setTimeout(() => {
    autoPlay = props.autoPlay

    if (props.playOnHover) {
      lottieAnimation?.pause()
    } else {
      if (autoPlay) {
        lottieAnimation?.play()
      } else {
        lottieAnimation?.pause()
      }
    }

    /**
     * Emit an `onAnimationLoaded` event when the animation is loaded
     * This should help with times where you want to run functions on the ref of the element
     */
    emits('onAnimationLoaded')
  }, props.delay)

  // set the speed and direction
  lottieAnimation.setSpeed(props.speed)

  if (props.direction === 'reverse') {
    lottieAnimation.setDirection(-1)
  }
  if (props.direction === 'normal') {
    lottieAnimation.setDirection(1)
  }

  // pause the animation if pauseAnimation or playOnHover is true
  if (props.pauseAnimation) {
    lottieAnimation.pause()
  } else {
    if (props.playOnHover) {
      lottieAnimation.pause()
    }
  }

  // set the emit events
  lottieAnimation.addEventListener('loopComplete', () => {
    if (props.direction === 'alternate') {
      lottieAnimation?.stop()
      internalDirection = internalDirection === -1 ? 1 : -1 //invert direction
      lottieAnimation?.setDirection(internalDirection)
      lottieAnimation?.play()
    }
    emits('onLoopComplete')
  })

  lottieAnimation.addEventListener('complete', () => {
    emits('onComplete')
  })

  lottieAnimation.addEventListener('enterFrame', () => {
    emits('onEnterFrame')
  })

  lottieAnimation.addEventListener('segmentStart', () => {
    emits('onSegmentStart')
  })
}

// generate the css variables for width, height and background color
const getCurrentStyle = computed(() => {
  let width = props.width
  let height = props.height

  // set to px values if a number is passed
  if (typeof props.width === 'number') {
    width = `${props.width}px`
  }

  if (typeof props.height === 'number') {
    height = `${props.height}px`
  }

  const cssVariables = {
    '--lottie-animation-container-width': width,
    '--lottie-animation-container-height': height,
    '--lottie-animation-container-background-color': props.backgroundColor,
    '--lottie-animation-margin': props.noMargin ? '0' : '0 auto',
    '--lottie-animation-scale': props.scale != 1 ? props.scale : ''
  }

  return cssVariables
})

// function to check if the container is being hovered
const hoverStarted = () => {
  if (lottieAnimation && props.pauseOnHover) {
    lottieAnimation.pause()
  }

  if (lottieAnimation && props.playOnHover) {
    lottieAnimation.play()
  }
}

// function to check if the container is no longer being hovered
const hoverEnded = () => {
  if (lottieAnimation && props.pauseOnHover) {
    lottieAnimation.play()
  }
  if (lottieAnimation && props.playOnHover) {
    lottieAnimation.pause()
  }
}

// watch for changes in props.pauseAnimation
watch(
  () => props.pauseAnimation,
  () => {
    // error if pauseAnimation is true and pauseOnHover is also true or playOnHover is also true
    if ((props.pauseOnHover || props.playOnHover) && props.pauseAnimation) {
      // eslint-disable-next-line no-console
      console.error(
        'If you are using pauseAnimation prop for Vue3-Lottie, please remove the props pauseOnHover and playOnHover'
      )
      return
    }

    // control the animation play state
    if (lottieAnimation) {
      if (props.pauseAnimation) {
        lottieAnimation.pause()
      } else {
        lottieAnimation.play()
      }
    }
  }
)

// method to play the animation
const play = () => {
  if (lottieAnimation) {
    lottieAnimation.play()
  }
}

// method to pause the animation
const pause = () => {
  if (lottieAnimation) {
    lottieAnimation.pause()
  }
}

// method to stop the animation. It will reset the animation to the first frame
const stop = () => {
  if (lottieAnimation) {
    lottieAnimation.stop()
  }
}

const destroy = () => {
  if (lottieAnimation) {
    lottieAnimation.destroy()
  }
}

const setSpeed = (speed: number = 1) => {
  // speed: 1 is normal speed.

  if (speed <= 0) {
    throw new Error('Speed must be greater than 0')
  }

  if (lottieAnimation) {
    lottieAnimation.setSpeed(speed)
  }
}

const setDirection = (direction: 'forward' | 'reverse') => {
  if (lottieAnimation) {
    if (direction === 'forward') {
      lottieAnimation.setDirection(1)
    } else if (direction === 'reverse') {
      lottieAnimation.setDirection(-1)
    }
  }
}

const goToAndStop = (frame: number, isFrame: boolean = true) => {
  //value: numeric value.
  //isFrame: defines if first argument is a time based value or a frame based (default true).

  if (lottieAnimation) {
    lottieAnimation.goToAndStop(frame, isFrame)
  }
}

const goToAndPlay = (frame: number, isFrame: boolean = true) => {
  //value: numeric value
  //isFrame: defines if first argument is a time based value or a frame based (default true).

  if (lottieAnimation) {
    lottieAnimation.goToAndPlay(frame, isFrame)
  }
}

const playSegments = (
  segments: AnimationSegment | AnimationSegment[],
  forceFlag: boolean = false
) => {
  //segments: array. Can contain 2 numeric values that will be used as first and last frame of the animation. Or can contain a sequence of arrays each with 2 numeric values.
  //forceFlag: boolean. If set to false, it will wait until the current segment is complete. If true, it will update values immediately.

  if (lottieAnimation) {
    lottieAnimation.playSegments(segments, forceFlag)
  }
}

const setSubFrame = (useSubFrame: boolean = true) => {
  // useSubFrames: If false, it will respect the original AE fps. If true, it will update on every requestAnimationFrame with intermediate values. Default is true.
  if (lottieAnimation) {
    lottieAnimation.setSubframe(useSubFrame)
  }
}

const getDuration = (inFrames: boolean = true) => {
  if (lottieAnimation) {
    return lottieAnimation.getDuration(inFrames)
  }
}

const updateDocumentData = (documentData: any, index: number = 0) => {
  if (lottieAnimation) {
    lottieAnimation.renderer.elements[index].updateDocumentData(documentData)
  }
}

defineExpose({
  play,
  pause,
  stop,
  destroy,
  setSpeed,
  setDirection,
  goToAndStop,
  goToAndPlay,
  playSegments,
  setSubFrame,
  getDuration,
  updateDocumentData
})
</script>

<style scoped>
.lottie-animation-container {
  width: var(--lottie-animation-container-width);
  height: var(--lottie-animation-container-height);
  background-color: var(--lottie-animation-container-background-color);
  overflow: hidden;
  margin: var(--lottie-animation-margin);
}

.lottie-animation-container svg {
  transform: scale(var(--lottie-animation-scale));
}
</style>
