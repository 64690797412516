<template>
  <div>
    <l-dropdown
      v-model:open="showDropdown"
      :options="dropdownOptions"
      placement="bottom-end"
      :allow-remove="false"
      :search="false"
      :multiselect="false"
      @update:model-value="selectOption"
    >
      <l-button
        class="relative"
        size="small"
        icon="3-dots"
        @click="showDropdown = !showDropdown"
      />
    </l-dropdown>
    <move-products-between-tabs
      v-if="selected === 'move-products'"
      :from-tab-id="tabId"
      class="absolute left-0 top-0 z-20"
      @products-moved="goToTab"
      @close="closePopUps"
    />
    <resend-kitchen-orders
      v-if="selected === 'resend-kitchen-orders'"
      :tab-id="tabId"
      @close="closePopUps"
    />
    <send-kitchen-note
      v-if="selected === 'kitchen-note'"
      :tab-id="tabId"
      @close="closePopUps"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LButton, LDropdown } from '@last/core-ui/paprika'
import type { Option } from '@last/core-ui/paprika/components/dropdown/types'

import { useTabs } from '@/composables/useTabs'
import { Beeper as BeeperIntegration } from '@/integrations/beeper/beeper'
import { useBeepersStore } from '@/store/beepers'
import { useConfigStore } from '@/store/config'

import MoveProductsBetweenTabs from './MoveProductsBetweenTabs.vue'
import ResendKitchenOrders from './ResendKitchenOrders.vue'
import SendKitchenNote from './SendKitchenNote.vue'

const props = defineProps<{
  tabId: string
}>()

const { t } = useI18n()
const router = useRouter()

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const beeperStore = useBeepersStore()
const { beepers } = storeToRefs(beeperStore)

const { beeper } = useTabs(toRef(() => props.tabId))

const dropdownOptions = computed(() => {
  const options: Option[] = [
    {
      icon: 'kitchen-note',
      label: t('ordering.kitchen-note'),
      value: 'kitchen-note'
    },
    {
      icon: 'double-arrow-inside',
      label: t('ordering.move-products'),
      value: 'move-products'
    }
  ]

  if (config.value.enableKitchenOrders) {
    options.splice(0, 0, {
      icon: 'refresh',
      label: t('resend-kitchen-orders.title'),
      value: 'resend-kitchen-orders'
    })
  }

  if (Object.keys(beepers.value).length > 0) {
    options.splice(2, 0, {
      icon: 'bell',
      label: t('beepers.assign-beeper'),
      value: 'assign-beeper',
      onClick: () => {
        router.push({ name: 'beepers', query: { tabId: props.tabId } })
      }
    })

    if (beeper.value) {
      options.splice(3, 0, {
        icon: 'lightning',
        label: t('beepers.activate-beeper'),
        value: 'activate-beeper',
        onClick: () => {
          BeeperIntegration.getInstance().beep(
            'touchIt',
            beeper.value?.id as string
          )
        }
      })
    }
  }
  return options
})
const showDropdown = ref(false)
const selected = ref<string>('')

const selectOption = (value: string[]) => {
  showDropdown.value = false
  selected.value = value[0]
}

const closePopUps = () => {
  selected.value = ''
}

const goToTab = (tabId: string) => {
  closePopUps()
  router.push({ name: 'orderManagement', params: { tabId } })
}
</script>
