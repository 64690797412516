import { isAfter } from 'date-fns'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { VirtualBrand } from '@/types/virtualBrands'

export const useDeliveryCompaniesStore = defineStore(
  'deliveryCompanies',
  () => {
    const virtualBrandsClosingTimes = ref<VirtualBrand[]>([])

    const sortedClosedUntil = computed(() => {
      return virtualBrandsClosingTimes.value
        .flatMap(brand =>
          Object.values(brand.externalDeliveryCompaniesClosingTimes)
        )
        .filter(time => time !== null)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    })

    const virtualBrandsWithClosedDeliveries = computed(() => {
      return virtualBrandsClosingTimes.value
        .map(brand => {
          return {
            ...brand,
            externalDeliveryCompaniesClosingTimes: Object.entries(
              brand.externalDeliveryCompaniesClosingTimes
            ).reduce(
              (res, [key, value]) => {
                if (value && isAfter(new Date(value), new Date())) {
                  res[key] = value
                }
                return res
              },
              {} as Record<string, Date>
            )
          }
        })
        .filter(
          brand =>
            Object.values(brand.externalDeliveryCompaniesClosingTimes).length >
            0
        )
    })

    const hasDeliveries = computed(() => {
      return (
        virtualBrandsClosingTimes.value.filter(
          brand =>
            Object.values(brand.externalDeliveryCompaniesClosingTimes).length >
            0
        ).length > 0
      )
    })

    function refreshClosingTimes(newVirtualBrandsClosingTimes: VirtualBrand[]) {
      virtualBrandsClosingTimes.value = newVirtualBrandsClosingTimes
    }

    return {
      virtualBrandsClosingTimes,
      sortedClosedUntil,
      virtualBrandsWithClosedDeliveries,
      refreshClosingTimes,
      hasDeliveries
    }
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeliveryCompaniesStore, import.meta.hot)
  )
}
