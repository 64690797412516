import type { Socket as NodeSocketType } from 'net'

interface SocketDriver {
  closed: boolean
  open(
    address: string,
    port: number,
    callback: () => void,
    reject: (error: Error) => void
  ): void
  write(
    data: string,
    callback: () => void,
    reject: (error: Error) => void
  ): void
  shutdownWrite(callback: () => void, reject: (error: Error) => void): void
  close(): void
  onData(callback: (data: Buffer) => void): void
}

class NodeSocket implements SocketDriver {
  private socket: NodeSocketType
  public closed: boolean

  constructor() {
    const net = window.require('net')
    this.closed = true
    this.socket = new net.Socket()
  }

  open(
    address: string,
    port: number,
    callback: () => void,
    reject: (error: Error) => void
  ) {
    this.socket.on('error', reject)
    this.socket.connect(port, address, callback)
    this.closed = false
  }

  write(data: string, callback: () => void, reject: (error: Error) => void) {
    this.socket.write(data, 'utf8', error => {
      if (error) {
        reject(error)
      } else {
        callback()
      }
    })
  }

  onData(callback: (data: Buffer) => void) {
    this.socket.on('data', callback)
  }

  shutdownWrite(callback: () => void, reject: (error: Error) => void) {
    this.socket.on('error', reject)
    this.socket.end('', 'utf8', callback)
    this.closed = true
  }

  close() {
    this.socket.destroy()
    this.closed = true
  }
}

class CapacitorSocket implements SocketDriver {
  // @ts-expect-error Type from cordova plugin
  private socket: Socket
  public closed: boolean
  constructor() {
    // @ts-expect-error Type from cordova plugin
    this.socket = new Socket()
    this.closed = true
    // add all global listener to avoid android issues
    this.socket.onError = () => {}
    this.socket.onData = () => {}
    this.socket.onClose = () => {
      this.closed = true
    }
  }

  open(
    address: string,
    port: number,
    callback: () => void,
    reject: (error: Error) => void
  ) {
    this.socket.open(address, port, callback, reject)
    this.closed = false
  }

  write(data: string, callback: () => void, reject: (error: Error) => void) {
    this.socket.write(data, callback, reject)
  }

  shutdownWrite(callback: () => void, reject: (error: Error) => void) {
    this.socket.shutdownWrite(callback, reject)
  }

  close() {
    this.socket.close()
    this.closed = true
  }

  onData(callback: (data: Buffer) => void) {
    this.socket.onData = callback
  }
}

class SocketFactory {
  static getSocket(): SocketDriver {
    try {
      window.require('net')
      return new NodeSocket()
    } catch {
      return new CapacitorSocket()
    }
  }
}

export default SocketFactory
