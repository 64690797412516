<template>
  <product-component
    :product="product"
    :disabled="someProductBilled"
    allow-remove
    allow-discount
    @delete="$emit('productsModified')"
  ></product-component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import ProductComponent from '@/components/core/Product.vue'
import type { Product } from '@/types'

defineEmits(['productsModified'])

const props = defineProps<{
  product: Product
}>()

const someProductBilled = computed(() => {
  return props.product.notBilledQuantity != props.product.quantity
})
</script>
