<template>
  <div class="w-full">
    <div class="flex w-full pb-4 items-center space-x-2">
      <div
        id="prev-month"
        class="w-7 h-7 flex justify-center items-center cursor-pointer rounded-full hover:bg-n-50 dark:hover:bg-n-600"
        :class="{ 'pointer-events-none opacity-50': cantGoBack }"
        @click="previousMonth()"
      >
        <l-icon name="bracket-left" class="text-n-600 dark:text-n-0" small />
      </div>
      <div v-if="showSelectors" class="flex flex-1 gap-2">
        <l-select
          v-model="month"
          v-model:selector-open="monthSelectorOpened"
          :options="months"
          class="flex-1 capitalize"
        />
        <l-select
          v-model="year"
          v-model:selector-open="yearSelectorOpened"
          :options="years"
          class="flex-shrink !w-auto"
        />
      </div>
      <div
        v-else
        class="flex-1 font-body text-n-800 dark:text-n-0 text-center capitalize"
      >
        {{ month }} {{ year }}
      </div>
      <div
        id="next-month"
        class="w-7 h-7 flex justify-center items-center rounded-full cursor-pointer hover:bg-n-50 dark:hover:bg-n-600"
        @click="nextMonth()"
      >
        <l-icon name="bracket-right" class="text-n-600 dark:text-n-0" small />
      </div>
    </div>
    <div class="flex w-full text-n-400 text-xs pb-3">
      <div
        v-for="day in weekdays"
        :key="day"
        class="flex-1 text-center uppercase"
      >
        {{ day }}
      </div>
    </div>
    <div id="days-container" class="w-full pb-2" @pointerleave="onPointerLeave">
      <div v-for="(row, index) in days" :key="index" class="flex-1 flex">
        <div
          v-for="(day, dayIndex) in row"
          :id="'day-' + day.day"
          :key="day.date.getTime()"
          class="flex-1 text-sm cursor-pointer flex justify-center py-0.5 relative"
          :class="computeClasses(day, dayIndex, row)"
          @click="select(day)"
          @pointerenter="overItem(day)"
        >
          <div
            class="w-8 h-8 rounded-full leading-8 text-center z-10"
            :class="computeInsideClasses(day)"
          >
            <span class="relative z-[1]">{{ day.day }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  addDays,
  addMonths,
  closestTo,
  eachDayOfInterval,
  endOfISOWeek,
  format,
  getDate,
  getISODay,
  interval,
  isBefore,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  set,
  setMonth,
  setYear,
  startOfISOWeek,
  startOfMonth,
  subDays,
  subMonths,
  type Locale
} from 'date-fns'
import caLocale from 'date-fns/locale/ca'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import esLocale from 'date-fns/locale/es'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { LIcon, LSelect } from '@last/core-ui/paprika'

export type Props = {
  /** Dates that can't be selected */
  unavailableDates?: Date[]
  /** Show month and year as selects */
  showSelectors?: boolean
  /** Maximum date that can be selected */
  maxDate?: Date | null
  /** Show only future dates inclusive today */
  onlyFuture?: boolean
  /** Show only working days */
  onlyWorkingDays?: boolean
  /** Determines if the selection will be made in ranges instead of single dates */
  useRangeMode?: boolean
  /** Determines how the range selection works */
  rangeModeType?: 'simple' | 'complex'
  /**
   * Default time of the date
   * If not provided, the current time will be used
   * Format HH:mm
   */
  defaultTime?: string
}

type Day = {
  date: Date
  day: number
  enabled: boolean
  selected: boolean
  futureSelection: boolean
  futureDeselection: boolean
}

const props = withDefaults(defineProps<Props>(), {
  unavailableDates: () => [],
  showSelectors: false,
  maxDate: null,
  onlyFuture: false,
  onlyWorkingDays: false,
  defaultTime: undefined,
  useRangeMode: false,
  rangeModeType: 'simple'
})

const someSelectorOpened = defineModel<boolean>('someSelectorOpened')
const monthSelectorOpened = ref<boolean>(false)
const yearSelectorOpened = ref<boolean>(false)

watch([monthSelectorOpened, yearSelectorOpened], () => {
  someSelectorOpened.value =
    monthSelectorOpened.value || yearSelectorOpened.value
})

const { locale } = useI18n()
const dateLocales: Record<string, Locale> = {
  ['es']: esLocale as unknown as Locale,
  ['ca']: caLocale as unknown as Locale,
  ['de']: deLocale as unknown as Locale,
  ['en']: enLocale as unknown as Locale
}

const currentDateLocale = computed<Locale>(
  (): Locale => dateLocales[locale.value as string]
)

const model = defineModel<Date | null | undefined>()

const endRangeDate = defineModel<Date>('endRangeDate')

const overRangeDate = ref<Date>()

const emit = defineEmits<{
  /** Emitted any time a valid date is clicked inside the component */
  dayClicked: []
  /** Emitted when the visible month changes */
  monthChanged: [Date]
}>()

const currentDate = ref(new Date())
const today = new Date()

onMounted(() => {
  if (model.value) {
    currentDate.value = model.value
  }
})

const years = computed(() => {
  let resultYears = []
  const pastYears = [
    ...Array(3)
      .fill(0)
      .map((_, i) => new Date().getFullYear() - i - 1)
  ]
  const futureYears = [
    new Date().getFullYear(),
    ...Array(3)
      .fill(0)
      .map((_, i) => new Date().getFullYear() + i + 1)
  ]
  if (props.onlyFuture) {
    resultYears = futureYears
  } else {
    resultYears = [...pastYears, ...futureYears]
  }
  return resultYears.sort().map(y => {
    return { label: y.toString(), value: y.toString() }
  })
})

const cantGoBack = computed(() => {
  return (
    props.onlyFuture &&
    today.getMonth() === currentDate.value.getMonth() &&
    today.getFullYear() === currentDate.value.getFullYear()
  )
})

const weekdays = computed(() => {
  return eachDayOfInterval({
    start: startOfISOWeek(today),
    end: endOfISOWeek(today)
  }).map(day => format(day, 'ccc', { locale: currentDateLocale.value }))
})

const futureSelectionOnFirstHalf = ref<boolean | undefined>(false)

const days = computed(() => {
  let firstWeek = startOfISOWeek(startOfMonth(currentDate.value))
  if (getISODay(firstWeek) !== 1) {
    firstWeek = startOfISOWeek(addDays(firstWeek, -7))
  }

  const endDate = addDays(firstWeek, 6 * 7 - 1)

  return chunks(
    eachDayOfInterval({ start: firstWeek, end: endDate }).map(date => {
      const maxDateFilter = props.maxDate
        ? isBefore(date, new Date(props.maxDate))
        : true

      const { isSelected, futureSelection, futureDeselection } =
        calculateSelectionFlags(date)
      return {
        date: props.defaultTime
          ? set(date, {
              hours: parseInt(props.defaultTime.split(':')[0]),
              minutes: parseInt(props.defaultTime.split(':')[1]),
              seconds: 0,
              milliseconds: 0
            })
          : date,
        day: getDate(date),
        enabled:
          isSameMonth(date, currentDate.value) &&
          !props.unavailableDates.some(d => isSameDay(d, date)) &&
          (!props.onlyFuture || isBefore(subDays(today, 1), date)) &&
          maxDateFilter &&
          (!props.onlyWorkingDays || getISODay(date) < 6),
        selected: isSelected,
        futureSelection: futureSelection,
        futureDeselection: futureDeselection
      }
    }),
    7
  )
})

function calculateSelectionFlags(date: Date) {
  let isSelected = model.value ? isSameDay(date, model.value) : false
  let futureSelection = false
  let futureDeselection = false

  if (props.useRangeMode && model?.value) {
    if (endRangeDate?.value) {
      isSelected = isWithinInterval(
        date,
        interval(model.value, endRangeDate.value)
      )
    }
    if (overRangeDate?.value) {
      const flags = calculateFutureFlags(date)
      futureSelection = flags.futureSelection
      futureDeselection = flags.futureDeselection
    }
  }

  return { isSelected, futureSelection, futureDeselection }
}

function calculateFutureFlags(date: Date) {
  let futureSelection = false
  let futureDeselection = false

  const isOnPast = model.value! > overRangeDate.value!
  const isOnFuture =
    overRangeDate.value! > (endRangeDate?.value || model.value!)

  if (isOnPast || isOnFuture) {
    futureSelectionOnFirstHalf.value = undefined
    if (isOnPast) {
      futureSelection =
        isWithinInterval(date, interval(overRangeDate.value!, model.value!)) &&
        !isSameDay(date, model.value!)
    } else if (isOnFuture) {
      futureSelection =
        isWithinInterval(
          date,
          interval(endRangeDate?.value || model.value!, overRangeDate.value!)
        ) && !isSameDay(date, endRangeDate?.value || model.value!)
    }
  } else if (endRangeDate?.value) {
    const closest = closestTo(overRangeDate.value!, [
      model.value!,
      endRangeDate.value
    ])
    const isOnFirstHalf = isSameDay(closest!, model.value!)
    futureSelectionOnFirstHalf.value = isOnFirstHalf

    if (isOnFirstHalf) {
      futureDeselection =
        isWithinInterval(date, interval(model.value!, overRangeDate.value!)) &&
        !isSameDay(date, overRangeDate.value!)
    } else {
      futureDeselection =
        isWithinInterval(
          date,
          interval(overRangeDate.value!, endRangeDate.value)
        ) && !isSameDay(date, overRangeDate.value!)
    }
  }

  return { futureSelection, futureDeselection }
}

function capitalizeFirstLetter(val: string): string {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

const months = computed(() => {
  const months = new Array(12).fill(null).map((_, i) => {
    const month = new Date(new Date().getFullYear(), i)
    return props.showSelectors
      ? {
          label: capitalizeFirstLetter(
            format(month, props.showSelectors ? 'MMMM' : 'MMM', {
              locale: currentDateLocale.value
            })
          ),
          value: month.getMonth()
        }
      : {
          label: format(month, props.showSelectors ? 'MMMM' : 'MMM', {
            locale: currentDateLocale.value
          }),
          value: month.getMonth()
        }
  })
  if (
    currentDate.value.getFullYear() === today.getFullYear() &&
    props.onlyFuture
  ) {
    months.splice(0, today.getMonth())
  }
  return months
})

const month = computed({
  get() {
    if (props.showSelectors) {
      return currentDate.value.getMonth()
    } else {
      return format(currentDate.value, 'MMMM', {
        locale: currentDateLocale.value
      })
    }
  },
  set(value) {
    currentDate.value = setMonth(currentDate.value, value as number)
  }
})

const year = computed({
  get() {
    return currentDate.value.getFullYear()
  },
  set(value) {
    currentDate.value = setYear(currentDate.value, value)
  }
})

function nextMonth() {
  currentDate.value = addMonths(currentDate.value, 1)
  emit('monthChanged', currentDate.value)
}

function previousMonth() {
  currentDate.value = subMonths(currentDate.value, 1)
  emit('monthChanged', currentDate.value)
}

function chunks(list: Day[], size: number) {
  const res = []
  for (let i = 0; i < list.length; i += size) {
    res.push(list.slice(i, i + size))
  }
  return res
}

function isDayClickable(day: Day): boolean {
  for (const date of props.unavailableDates) {
    if (isSameDay(date, day.date)) return false
  }

  if (!props.useRangeMode && !day.enabled) return false

  return true
}

function select(day: Day) {
  if (!isDayClickable(day)) return

  emit('dayClicked')
  if (!props.useRangeMode) {
    model.value = day.date
  } else {
    if (!model.value) {
      model.value = day.date
    } else {
      if (props.rangeModeType === 'simple') {
        if (endRangeDate.value) {
          endRangeDate.value = undefined
          model.value = day.date
        } else {
          if (day.date < model.value) {
            endRangeDate.value = model.value
            model.value = day.date
          } else {
            endRangeDate.value = day.date
          }
        }
        overItem(day)
      } else if (!endRangeDate?.value) {
        if (model.value > day.date) {
          endRangeDate.value = model.value
          model.value = day.date
        } else if (model.value < day.date) {
          endRangeDate.value = day.date
        } else {
          model.value = undefined
        }
      } else {
        if (day.date < model.value) {
          model.value = day.date
        } else if (day.date > endRangeDate.value) {
          endRangeDate.value = day.date
        } else {
          const closest = closestTo(day.date, [model.value, endRangeDate.value])
          if (closest && isSameDay(closest, model.value)) {
            model.value = day.date
          } else {
            endRangeDate.value = day.date
          }
        }
      }
    }
  }
}

function overItem(day: Day): void {
  overRangeDate.value = day.date
}

function onPointerLeave(): void {
  overRangeDate.value = undefined
}

function computeClasses(day: Day, dayIndex: number, row: Day[]) {
  const classes = []
  classes.push([
    !day.enabled ? 'text-n-300 dark:text-n-500' : 'text-n-800 dark:text-n-0'
  ])
  if (props.useRangeMode) {
    if (props.rangeModeType === 'simple') {
      classes.push([
        {
          'after:bg-v-500 dark:after:bg-v-300': day.selected
        },
        afterClasses(row, dayIndex)
        // beforeClasses(row, dayIndex)
      ])
    } else {
      classes.push([
        {
          'after:bg-v-500 dark:after:bg-v-300':
            day.selected && !day.futureSelection && !day.futureDeselection,
          'before:bg-v-300 dark:before:bg-v-300 dark:before:brightness-[80%]':
            (day.selected && day.futureDeselection) ||
            (overRangeDate.value &&
              isSameDay(overRangeDate.value, day.date) &&
              typeof futureSelectionOnFirstHalf.value !== 'undefined'),
          'before:bg-n-50 dark:before:bg-n-600':
            (!futureSelectionOnFirstHalf.value && day.futureSelection) ||
            (day.selected &&
              ((dayIndex !== 0 && row[dayIndex - 1].futureSelection) ||
                (dayIndex !== row.length - 1 &&
                  row[dayIndex + 1].futureSelection)))
        },
        afterClasses(row, dayIndex),
        beforeClasses(row, dayIndex)
      ])
    }
  }

  return classes
}

function computeInsideClasses(day: Day) {
  const classes = []

  if (props.useRangeMode) {
    if (props.rangeModeType === 'simple') {
      classes.push([
        {
          'bg-n-50 dark:bg-n-600':
            !day.selected &&
            isSameDay(overRangeDate.value!, day.date) &&
            day.enabled,
          'bg-v-500 dark:bg-v-300 text-n-0': day.selected,
          '!bg-v-300 dark:!brightness-[80%] !text-n-0':
            day.selected &&
            isSameDay(overRangeDate.value!, day.date) &&
            day.enabled
        }
      ])
    } else {
      classes.push([
        {
          'bg-n-50 dark:bg-n-600': !day.selected && day.futureSelection,
          'bg-v-500 dark:bg-v-300 text-n-0':
            day.selected && !day.futureDeselection,
          'bg-v-300 dark:brightness-[80%] text-n-0':
            day.selected && day.futureDeselection
        }
      ])
    }
  } else {
    classes.push([
      {
        'bg-n-50 dark:bg-n-600':
          !day.selected &&
          isSameDay(overRangeDate.value!, day.date) &&
          day.enabled,
        'bg-v-500 dark:bg-v-300 text-n-0': day.selected,
        '!bg-v-300 dark:!brightness-[80%] !text-n-0':
          day.selected &&
          isSameDay(overRangeDate.value!, day.date) &&
          day.enabled
      }
    ])
  }

  return classes
}

function afterClasses(row: Day[], dayIndex: number): string[] {
  const common = 'after:z-1 after:absolute after:top-0.5 after:bottom-0.5'
  let left = 'after:left-0'
  let right = 'after:right-0'
  if (!endRangeDate?.value) return [common, 'after:left-1/2', 'after:right-1/2']
  if (
    dayIndex === 0 ||
    !row[dayIndex - 1].selected ||
    (props.rangeModeType !== 'simple' && row[dayIndex - 1].futureDeselection)
  ) {
    left = 'after:left-1/2'
  }
  if (
    dayIndex === row.length - 1 ||
    !row[dayIndex + 1].selected ||
    (props.rangeModeType !== 'simple' && row[dayIndex + 1].futureDeselection)
  ) {
    right = 'after:right-1/2'
  }

  return [common, left, right]
}

function beforeClasses(row: Day[], dayIndex: number): string[] {
  const common = 'before:z-0 before:absolute before:top-0.5 before:bottom-0.5'
  let left = 'before:left-1/2'
  let right = 'before:right-1/2'

  const previousDay = row[dayIndex - 1]
  const nextDay = row[dayIndex + 1]
  if (
    dayIndex !== 0 &&
    (previousDay.selected ||
      previousDay.futureDeselection ||
      previousDay.futureSelection)
  ) {
    left = 'before:left-0'
  }

  if (
    dayIndex !== row.length - 1 &&
    (nextDay.selected ||
      nextDay.futureDeselection ||
      nextDay.futureSelection ||
      futureSelectionOnFirstHalf.value)
  ) {
    right = 'before:right-0'
  }

  return [common, left, right]
}
</script>
