<template>
  <div class="m-1 dark:bg-n-0 dark:rounded-full">
    <l-lottie :animation-data="LoadingAnimation" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import LoadingBarAnimation from '@last/core-ui/paprika/assets/lottie/lottie-bar-loading.json'
import LoadingSpinnerAnimation from '@last/core-ui/paprika/assets/lottie/lottie-spinner-loading.json'

import LLottie from './LLottie.vue'

type Props = {
  type: 'spinner' | 'bar'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'spinner'
})

const LoadingAnimation = computed(() => {
  return props.type === 'spinner'
    ? LoadingSpinnerAnimation
    : LoadingBarAnimation
})
</script>
