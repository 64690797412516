<template>
  <div class="flex h-full w-full">
    <div
      class="w-full sm:w-1/2 flex-shrink-0 bg-n-700 p-4 sm:p-10 flex-col sm:flex"
      :class="{ flex: showTillMethods, hidden: !showTillMethods }"
    >
      <div>
        <div class="text-n-0 mb-6">
          <div class="font-heading font-bold text-xl mb-1 capitalize">
            {{ closingTill.name }}
          </div>
          <div class="font-body">{{ $t('end-shift.shift-summary') }}</div>
        </div>
        <close-till-methods
          :open-methods="openMethods"
          :current-step="currentStep"
          :amounts="amounts"
          @step="onStep"
        />
      </div>

      <div class="mt-auto">
        <l-button
          size="medium"
          icon="printer"
          type="text"
          @click="printTillReport(closingTill)"
        >
          {{ $t('end-shift.print-till-report') }}
        </l-button>
      </div>
    </div>
    <div
      class="w-full sm:w-1/2 flex-shrink-0 bg-n-800 p-4 sm:p-10 flex-col h-full min-h-0 sm:flex"
      :class="{ flex: !showTillMethods, hidden: showTillMethods }"
    >
      <div class="flex justify-between mb-6">
        <div class="font-heading font-bold text-xl text-n-0 capitalize">
          {{ $t('bill.' + currentMethod, currentMethod!) }}
        </div>
        <div>
          <l-button size="small" type="text" @click="seeMovements">
            {{ $t('end-shift.see-movements') }}
          </l-button>
        </div>
      </div>
      <div class="flex-1 min-h-0">
        <cash-amount
          :key="currentMethod!"
          :done-label="doneLabel"
          :use-cash-machine="useCashMachine"
          :show-open-till="true"
          :show-bills-counter="currentMethod === 'cash'"
          :init-total="amounts[currentMethod!]"
          @done="continueAction"
        />
      </div>
    </div>
  </div>

  <l-modal
    :open="!!selectedReport"
    :title="$t('end-shift.title')"
    :button-text="$t('dialog.continue')"
    button-size="medium"
    :secondary-button="true"
    :secondary-button-text="$t('report.print-shift-movements')"
    secondary-button-size="medium"
    @action="closeReport"
    @secondary-action="printShiftMovements"
    @close="closeReport"
  >
    <div>
      <div class="flex justify-between items-center mb-4">
        <div class="font-body text-sm text-n-200">
          {{ $t('report.difference-since') }}:
          {{ $d(selectedReport!.start, 'date') }}
        </div>
        <div
          :class="{ 'text-g-500': diff === 0, 'text-r-500': diff != 0 }"
          class="font-heading text-xl font-bold"
        >
          <span v-if="diff > 0">+ </span>{{ currency(diff) }}
        </div>
      </div>
      <till-report-preview
        v-if="tillReport"
        :till-report="tillReport"
        :name="closingTill.name"
      />
    </div>
  </l-modal>

  <l-modal
    v-model:open="seeingMovements"
    :title="`${currentMethod} ${$t('end-shift.movements')}`"
    :button-text="$t('end-shift.print-movements')"
    @close="seeingMovements = false"
    @action="printCurrentMethodMovements"
  >
    <div class="pb-4">
      {{ $t('end-shift.start-amount') + ' : ' + currency(currentMethodStart!) }}
    </div>
    <close-till-method-movements
      :movements="movements[currentMethod!]!"
      :current-method="currentMethod!"
    />
  </l-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { LButton, LModal, useDialog } from '@last/core-ui/paprika'

import { getLastShift, getLatShiftMovements, getOpenMethods } from '@/api/shift'
import { useMoney } from '@/composables/useMoney'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { useTillStore } from '@/store/till'
import sync from '@/sync/service'
import {
  OpenMethodWithCurrentAmount,
  PaymentMethodType,
  ShiftMovement,
  Till,
  TillReport
} from '@/types'

import CashAmount from './CashAmount.vue'
import CloseTillMethodMovements from './CloseTillMethodMovements.vue'
import CloseTillMethods from './CloseTillMethods.vue'
import TillReportPreview from './TillReportPreview.vue'

type EmployeeTill = {
  id: string
  name: string
}

const props = defineProps<{
  tillId: string
}>()

const emit = defineEmits(['close'])

const configStore = useConfigStore()
const authStore = useAuthStore()
const tillStore = useTillStore()
const dialog = useDialog()
const { t } = useI18n()
const { currency } = useMoney()

const { config, tills } = storeToRefs(configStore)
const { listEmployees } = storeToRefs(authStore)
const { endShift } = tillStore

const openMethods = ref<OpenMethodWithCurrentAmount[]>([])
const tillReport = ref<TillReport | null>(null)
const selectedReport = ref<TillReport | null>(null)
const movements = ref<Partial<Record<PaymentMethodType, ShiftMovement[]>>>({})
const currentStep = ref(0)
const amounts = ref<Record<string, number>>({})
const seeingMovements = ref(false)
const showTillMethods = ref(true)

onMounted(async () => {
  openMethods.value = await getOpenMethods(props.tillId)
  if (!config.value.blindTill) {
    const movementsResponse = await getLatShiftMovements(props.tillId)
    movements.value = movementsResponse.reduce(
      (res, movement) => {
        res[movement.method] = [...(res[movement.method] || []), movement]
        return res
      },
      {} as Partial<Record<PaymentMethodType, ShiftMovement[]>>
    )
  }
})

const useCashMachine = computed(() => {
  if (!currentMethod.value) return false
  return CashMachine.methods.includes(currentMethod.value)
})

const diff = computed(() => {
  if (tillReport.value) {
    return tillReport.value.endAmount - tillReport.value.expectedEndAmount
  } else if (selectedReport.value?.till) {
    return (
      selectedReport.value.till.end -
      selectedReport.value.till.expectedEndAmount
    )
  } else {
    return 0
  }
})

const closingTill = computed(() => {
  const till = tills.value['cash'].find(till => till.id === props.tillId)
  if (!till) {
    const employee = getEmployeeByTill()
    if (employee) {
      return { id: props.tillId, name: employee.name } as EmployeeTill
    }
  }
  return till as Till
})

const currentMethod = computed(() => {
  if (!openMethods.value[currentStep.value]) {
    return null
  }
  return openMethods.value[currentStep.value].method
})

const currentMethodStart = computed(() => {
  if (!openMethods.value[currentStep.value]) {
    return null
  }
  return openMethods.value[currentStep.value].start
})

const doneLabel = computed(() => {
  if (isLastStep.value && !currentMethodIsFilled.value) {
    return t('end-shift.end')
  } else {
    return t('end-shift.next')
  }
})

const totalFilledSteps = computed(() => {
  return Object.keys(amounts.value).length
})

const currentMethodIsFilled = computed(() => {
  return (
    amounts.value[currentMethod.value!] ||
    amounts.value[currentMethod.value!] === 0
  )
})

const isLastStep = computed(() => {
  return totalFilledSteps.value === openMethods.value.length - 1
})

const amountsList = computed(() => {
  return Object.keys(amounts.value).map(key => {
    return {
      method: key,
      amount: amounts.value[key]
    }
  })
})

function onStep(value: number) {
  currentStep.value = value
  showTillMethods.value = false
}

function printCurrentMethodMovements() {
  const currentMovements = movements.value[currentMethod.value!]
  printShiftMovements(
    currentMovements ? currentMovements : null,
    closingTill.value.name + ' ' + currentMethod.value
  )
}

function getEmployeeByTill() {
  return (
    listEmployees.value.find(employee => employee.tillId === props.tillId) ||
    null
  )
}

function getNextEmptyStep() {
  const nextStep = openMethods.value.findIndex(
    method =>
      !amounts.value[method.method] && amounts.value[method.method] !== 0
  )
  return nextStep
}

function printShiftMovements(
  movements?: ShiftMovement[] | null,
  name?: string
) {
  TicketPrinter.printShiftMovements(
    closingTill.value.id,
    name ? name : closingTill.value.name,
    movements ? movements : null
  )
}

function seeMovements() {
  seeingMovements.value = true
}

function closeReport() {
  selectedReport.value = null
  emit('close')
}

async function getTillReport(tillId: string) {
  tillReport.value = await getLastShift(tillId)
}

async function continueAction(amount: number) {
  amounts.value[currentMethod.value!] = amount
  if (totalFilledSteps.value === openMethods.value.length) {
    dialog({
      title: t('end-shift.close-till-confirmation-message'),
      secondaryLabel: t('end-shift.cancel'),
      mainLabel: t('end-shift.end'),
      content: amountsList.value
        .map(
          el => `${t('bill.' + el.method, el.method)}: ${currency(el.amount)}`
        )
        .join('\n'),
      onConfirm() {
        sendEndShift()
      }
    })
  } else {
    currentStep.value = getNextEmptyStep()
  }
}

function sendEndShift() {
  endShift({
    amounts: amountsList.value,
    tillId: closingTill.value.id
  })
  sync.observeEnd(async () => {
    await getReports(closingTill.value)
  })
}

async function getReports(till: Till | EmployeeTill) {
  await getTillReport(till.id)
  printTillReport(till, tillReport.value)
  selectedReport.value = tillReport.value
}

function printTillReport(
  till: Till | EmployeeTill,
  report?: TillReport | null
) {
  TicketPrinter.printTillReport(till, report)
}
</script>
