import i18n from '@/i18n'

import { EmptySeparator, Label, SubTitle } from './components.js'

function getKitchenNoteTitle() {
  return [
    new SubTitle(`${i18n.global.t('ordering.kitchen-note')}`, { fontSize: 32 })
  ]
}

function getKitchenNoteTable(tab) {
  return [
    new SubTitle(
      `${i18n.global.t('kitchen-order.delivery-time')}: ${tab.tableName}`,
      {
        fontSize: 24
      }
    )
  ]
}

function getKitchenNoteCourse(courseLabel) {
  return [
    new SubTitle(`${i18n.global.t('ordering.send-kitchen-note')}`, {
      fontSize: 50
    }),
    new SubTitle(courseLabel, { fontSize: 50 })
  ]
}

function getKitchenNoteActualDateAndHour() {
  return [
    new Label(new Date(), {
      style: '18px',
      lineHeight: 30,
      valueType: 'date',
      align: 'center'
    })
  ]
}

function generateKitchenNote(courseLabel, tab) {
  return [
    ...getKitchenNoteTitle(),
    new EmptySeparator(),
    ...getKitchenNoteTable(tab),
    ...getKitchenNoteActualDateAndHour(),
    new EmptySeparator(),
    new EmptySeparator(),
    ...getKitchenNoteCourse(courseLabel)
  ]
}

export default generateKitchenNote
