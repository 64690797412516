<template>
  <info-screen
    :title="$t('plans.forbidden-title')"
    :message="$t('plans.forbidden-text')"
    image="device-error.svg"
    :confirm-text="$t('ctas.back')"
    @confirm="logout"
  />
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'

import InfoScreen from '../core/InfoScreen.vue'

const authStore = useAuthStore()

function logout() {
  authStore.logout()
}
</script>
