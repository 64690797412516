<template>
  <div :style="style">
    <l-dropdown
      v-model:open="contextMenu"
      :options="options"
      :mark-selected="false"
      placement="top-start"
      @update:model-value="executeAction"
    >
      <div
        v-on-long-press.stop="showContextMenu"
        :style="[
          getAnimationStyle,
          { width: props.table.width + 'px', height: props.table.height + 'px' }
        ]"
        class="relative flex items-center justify-center cursor-pointer"
        :class="{
          'opacity-30': isBlocked,
          'rounded-lg': table.type === 'rectangle',
          'rounded-[200px]': table.type === 'circle',
          'bg-n-900 text-n-200': table.type === 'wall',
          'border border-n-0': pendingKitchenOrders(table),
          'text-n-0 bg-b-500': tableState === 'occupied' && !hasReservation,
          'text-n-0 bg-dual-b-y': tableState === 'occupied' && hasReservation,
          'bg-r-300 text-n-0': tableState === 'toPay' && !hasReservation,
          'text-n-0 bg-dual-r-y': tableState === 'toPay' && hasReservation,
          'bg-y-500 text-n-0': tableState === 'reserved',
          'text-n-800 bg-n-0': tableState === 'empty',
          '!border-none !bg-v-300 moving !text-n-0': isSelected && !isBlocked
        }"
        @longpress="showContextMenu()"
        @contextmenu.prevent="showContextMenu()"
        @click="!isBlocked && $emit('selectedTable')"
      >
        <div class="w-full h-full p-0.5">
          <div class="flex flex-col justify-center w-full h-full">
            <div
              class="flex items-center justify-center overflow-hidden"
              :class="[table.height > 50 ? 'mb-0.5' : '-mb-0.5']"
            >
              <div
                class="text-center uppercase font-heading line-clamp-1"
                :class="{
                  'text-n-200 text-xs': table.type === 'wall',
                  'text-xs font-bold': table.type !== 'wall',
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                <span>{{ name }}</span>
                <span v-if="groupedTableNames.length > 0" class="font-normal">
                  {{ ', ' + groupedTableNames.join(', ') }}
                </span>
              </div>
            </div>
            <template v-if="mode === 'time'">
              <div
                v-if="table.tabIds.length > 0"
                class="flex flex-row justify-center items-center text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ elapsedTime(tabsStore.getLastInteraction(table.tabIds[0])) }}
              </div>
              <div
                v-else-if="hasReservation"
                class="flex flex-row justify-center items-end text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                <div>{{ timeUntil(reservationTime) }}</div>
              </div>
            </template>

            <template v-else-if="mode === 'diners'">
              <div
                v-if="seats > 0"
                class="flex items-center justify-center text-[0.625rem]"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ seats }}
              </div>
            </template>

            <template v-else-if="mode === 'amount'">
              <div
                v-if="total > 0"
                class="flex items-center justify-center text-[0.625rem] text-center"
                :class="{
                  'scale-75': Math.min(table.height, table.width) < 50
                }"
              >
                {{ currency(total) }}
              </div>
            </template>

            <div
              v-if="pendingKitchenOrders(table)"
              class="absolute bg-n-0 rounded-full w-2.5 h-2.5 mr-[-6px] mt-[-5px]"
              :style="getTopCornerStyles(table)"
            >
              <div class="absolute inset-[1px] rounded-full bg-y-500" />
            </div>

            <div
              v-if="table?.tabIds?.length > 1"
              class="absolute w-2.5 h-2.5 scale-75"
              :style="getBottomCornerStyles(table)"
            >
              <l-badge type="small" :value="table?.tabIds?.length" />
            </div>
          </div>
        </div>
      </div>
    </l-dropdown>
  </div>
</template>

<script setup lang="ts">
import { vOnLongPress } from '@vueuse/components'
import { differenceInMinutes } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, onMounted, ref, StyleValue } from 'vue'
import { useRouter } from 'vue-router'

import { currencyFilter } from '@last/core'
import { LBadge, LDropdown } from '@last/core-ui/paprika'
import { Option } from '@last/core-ui/paprika/components/dropdown/types'

import {
  mapTableStateToMenuItems,
  type TableStates
} from '@/components/tables/table.utils'
import { useTabs } from '@/composables/useTabs'
import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { useTabsStore } from '@/store/tabs'
import { Tab, Table } from '@/types'

type Props = {
  table: Table
  mode: 'time' | 'amount' | 'diners'
  floorplanId: string
  groupedTableNames: string[]
  wiggleEnabled?: boolean
  isSelected?: boolean
  isBlocked?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  wiggleEnabled: false,
  isSelected: false,
  isBlocked: false
})

const emit = defineEmits(['selectedTable', 'mergeTabs', 'assignTable'])

const tabsStore = useTabsStore()
const configStore = useConfigStore()
const reservationsStore = useReservationsStore()
const { searchReservation } = storeToRefs(reservationsStore)

const router = useRouter()

const { tabs, kitchenOrders } = storeToRefs(tabsStore)
const { config } = storeToRefs(configStore)

const currentDate = ref<Date>(new Date())
const dateInterval = ref()
const contextMenu = ref(false)

const options = computed<Option[]>(() => {
  return mapTableStateToMenuItems[tableState.value]
})

onMounted(() => {
  dateInterval.value = setInterval(() => {
    currentDate.value = new Date()
  }, 60 * 1000)
})

onBeforeUnmount(() => {
  if (dateInterval.value) {
    clearInterval(dateInterval.value)
  }
})

const tab = computed(() => {
  return (tabs.value as any)[props.table.tabIds[0]] as Tab | undefined
})

function currency(value: number): string {
  return currencyFilter(value, config.value.currencyCode || 'EUR')
}

const tableState = computed<TableStates>(() => {
  const tableHasTab = hasTab.value
  const tableHasReservation = hasReservation.value
  const tableHasBills = hasBills.value

  if (!tableHasTab && !tableHasReservation && !tableHasBills) {
    return 'empty'
  } else if (tableHasBills) {
    return 'toPay'
  } else if (tableHasTab) {
    return 'occupied'
  } else if (tableHasReservation) {
    return 'reserved'
  } else {
    return 'empty'
  }
})

const style = computed(() => {
  return {
    position: 'absolute',
    width: props.table.width + 'px',
    height: props.table.height + 'px',
    left: props.table.x + 'px',
    top: props.table.y + 'px'
  } as Partial<StyleValue>
})

const name = computed(() => {
  if (
    tab.value &&
    tab.value.lang &&
    tab.value.lang !== configStore.config.locationCountryCode
  ) {
    return `${tab.value.lang} - ${props.table.name}`
  }
  return props.table.name
})

const seats = computed(() => {
  if (tab.value) {
    return tab.value.seats?.length || 0
  }
  const reservation = searchReservation.value(props.table.id, currentDate.value)
  if (reservation) {
    return reservation.diners || 0
  }
  return 0
})

const total = computed(() => {
  return props.table.tabIds.reduce((total, id) => {
    const { total: tabTotal } = useTabs(id)
    return total + (tabTotal.value ?? 0)
  }, 0)
})

function pendingKitchenOrders(table: Table) {
  if (!config.value.enableKitchenOrders) return false
  return table.tabIds.some(tabId => {
    const { unsentProducts } = useTabs(tabId)
    return (
      unsentProducts.value.length > 0 ||
      tabs.value[tabId].kitchenOrders
        .map((id: string) => kitchenOrders.value[id])
        .filter(order => !order.printedTime && order.copies > 0).length > 0
    )
  })
}

const hasTab = computed(() => {
  return props.table.tabIds.length > 0
})

const hasBills = computed(() => {
  if (props.table.tabIds.length === 0) return false
  return props.table.tabIds.some(tabId => {
    return (tabs.value[tabId] as Tab)?.billingStartedTime
  })
})

const reservation = computed(() => {
  return searchReservation.value(props.table.id, currentDate.value)
})

const hasReservation = computed(() => {
  return !!reservation.value && !reservation.value.cancelled
})

const reservationTime = computed(() => {
  if (reservation.value) {
    return reservation.value.dateTime || ''
  }

  return ''
})

function elapsedTime(date?: Date) {
  if (!date) return
  const now = currentDate.value
  const minutes = Math.floor(Math.max(differenceInMinutes(now, date), 0))
  const roundMinutes =
    Array.from(`${minutes % 60}`).length <= 1
      ? `0${minutes % 60}`
      : minutes % 60
  return `${Math.floor(minutes / 60)}:${roundMinutes}`
}

function getTopCornerStyles(table: Table): Partial<StyleValue> {
  if (table.type === 'rectangle') {
    return {
      top: '1px',
      right: '1px'
    }
  } else if (table.type === 'circle') {
    return {
      top: `${calculateCornerPositionForRoundedTables(table)}px`,
      right: `${calculateCornerPositionForRoundedTables(table)}px`
    }
  }

  return {}
}

function getBottomCornerStyles(table: Table): Partial<StyleValue> {
  if (table.type === 'rectangle') {
    return {
      bottom: '-2px',
      right: '-2px'
    }
  } else if (table.type === 'circle') {
    return {
      bottom: `${calculateCornerPositionForRoundedTables(table) - 4}px`,
      right: `${calculateCornerPositionForRoundedTables(table) - 4}px`
    }
  }

  return {}
}

function calculateCornerPositionForRoundedTables(table: Table): number {
  const hipotenuse = Math.min(table.width, table.height) / 2
  const cathetus = Math.sqrt((hipotenuse * hipotenuse) / 2)

  return hipotenuse - cathetus
}

function timeUntil(date?: string) {
  if (!date) return
  const now = currentDate.value
  const start = new Date(date)
  const duration = differenceInMinutes(start, now)

  const hours = Math.floor(Math.abs(duration) / 60)
  const minutes = Math.abs(duration) % 60
  const formattedMinutes = String(minutes).padStart(2, '0')

  return `${duration < 0 ? '-' : ''}${hours}:${formattedMinutes}`
}

function showContextMenu() {
  if (props.wiggleEnabled) return
  contextMenu.value = true
}

function hideContextMenu() {
  contextMenu.value = false
}

const getAnimationStyle = computed(() => {
  if (props.table.type === 'wall' || !props.wiggleEnabled) return ''
  return {
    animationDuration: 0.3 + 's',
    animationDelay: -(Math.random() + 0.3) + 's',
    ['--table-animation-variant' as string]:
      getAngle(Math.max(props.table.width, props.table.height)) + 'deg'
  }
})

function getAngle(size: number): number {
  const maxAngle = 6
  const maxSize = 200
  const stepSize = maxSize / maxAngle

  const angle = maxAngle - Math.round(size / stepSize)

  return angle <= 2 ? 2 : angle >= maxAngle ? maxAngle : angle
}

function executeAction(action: string[]): void {
  const tabs = useTabs(props.table.tabIds[0])
  switch (action[0]) {
    case 'printTicket':
      tabs.printBill()
      break
    case 'moveAccount':
      emit('assignTable', props.table.tabIds)
      break
    case 'mergeTabs':
      emit('mergeTabs', props.table.tabIds[0])
      break
    case 'closeTable':
      tabs.closeTab()
      break
    case 'reservations':
      router.push({
        name: 'reservations',
        query: { initialTableId: props.table.id }
      })
      break
    case 'moveReservation':
      if (!hasReservation.value) {
        break
      }
      router.push({
        name: 'reservations',
        query: { editReservation: reservation.value!.id, showSelector: 1 }
      })
      break
  }
  hideContextMenu()
}
</script>

<style scoped>
:root {
  --table-animation-variant: 1deg;
}

.moving {
  animation-name: wiggle;
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(var(--table-animation-variant));
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(calc(var(--table-animation-variant) * -1));
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotate(var(--table-animation-variant));
    animation-timing-function: ease-out;
  }
}
</style>
