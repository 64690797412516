import api from '@/api/fetch'
import { Device, DeviceConfig } from '@/types/device'

export async function addDevice(device: Device): Promise<DeviceConfig> {
  return api
    .put<DeviceConfig>(`/devices/${device.id}`, { ...device, deleted: false })
    .then(res => res.data)
}

export function checkVersion(data: any) {
  return api
    .post<{
      version: string
      deprecated: boolean
      url: string
      checksum: string
    }>('/pos/versions/updates', data)
    .then(res => res.data)
}
