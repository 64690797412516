<template>
  <div
    class="bg-n-700 py-7 px-6 text-n-0 rounded-2xl moving"
    :style="[getAnimationStyle]"
  >
    <div class="flex flex-col items-center gap-2">
      <div class="flex flex-col items-center">
        <div class="font-heading text-3xl font-bold text-y-500">
          {{ beeper.id }}
        </div>
      </div>
      <div class="flex flex-col items-center w-full">
        <div class="text-lg text-n">
          {{ tabCode || t('beepers.unassigned') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { useTabs } from '@/composables/useTabs'
import type { Beeper } from '@/types/beeper'

const props = defineProps<{
  beeper: Beeper
  wiggle?: boolean
}>()

const { t } = useI18n()

const tabCode = computed(() => {
  if (props.beeper.tabId) {
    const { tab } = useTabs(props.beeper.tabId)
    return tab?.value?.code
  } else {
    return null
  }
})

const getAnimationStyle = computed(() => {
  if (!props.wiggle) return ''
  return {
    animationDuration: 0.3 + 's',
    animationDelay: -(Math.random() + 0.3) + 's',
    ['--beeper-animation-variant' as string]: '2deg'
  }
})
</script>
<style scoped>
:root {
  --beeper-animation-variant: 1deg;
}

.moving {
  animation-name: wiggle;
  animation-iteration-count: infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(var(--beeper-animation-variant));
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(calc(var(--beeper-animation-variant) * -1));
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotate(var(--beeper-animation-variant));
    animation-timing-function: ease-out;
  }
}
</style>
