<template>
  <button
    class="flex items-center justify-center relative gap-1"
    :class="classes"
    @click="click"
  >
    <l-icon v-if="loading" size="small" name="status" class="animate-spin" />
    <template v-else>
      <l-icon
        v-if="icon"
        :size="isOnlyIcon && size !== 'x-small' ? size : 'small'"
        :name="icon"
      />
      <slot></slot>
    </template>
  </button>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'

import { useTracker, type LIconName } from '@last/core-ui/paprika'

import LIcon from './LIcon.vue'

interface Props {
  /**
   * Button type
   * @default primary
   * */
  type?: 'primary' | 'secondary' | 'text' | 'link'
  /**
   * Button size
   * @default medium
   * */
  size?: 'x-small' | 'small' | 'medium' | 'large'
  /**
   * Button color
   * If you use custom, you need to set the --primary-color css variable
   * @default primary
   */
  color?:
    | 'primary'
    | 'red'
    | 'yellow'
    | 'blue'
    | 'green'
    | 'white'
    | 'violet'
    | 'custom'
  /**
   * Show a loading spinner and disable the button
   * @default false
   */
  loading?: boolean
  /**
   * Disable the button
   * @default false
   */
  disabled?: boolean
  /**
   * Icon alignment
   * @default left
   * */
  iconAlign?: 'left' | 'right'
  /**
   * Icon name
   * */
  icon?: LIconName
  /**
   * Event ID send to the tracker when the button is clicked
   * */
  trackingId?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 'medium',
  color: 'primary',
  type: 'primary',
  loading: false,
  disabled: false,
  iconAlign: 'left',
  icon: undefined,
  trackingId: undefined
})

const slots = useSlots()

const emit = defineEmits(['click'])

const tracker = useTracker()

const isOnlyIcon = computed<boolean>(() => {
  return !!props.icon && !slots.default
})

const sizeClasses = computed<string[]>(() => {
  if (isOnlyIcon.value) {
    if (props.size === 'x-small') return ['h-6', 'w-6', 'rounded']
    if (props.size === 'small') return ['h-9', 'w-9', 'rounded']
    if (props.size === 'large') return ['h-14', 'w-14', 'rounded']
    return ['h-12', 'w-12', 'rounded']
  }
  if (props.size === 'x-small') return ['text-sm', 'h-6', 'px-4', 'rounded']
  if (props.size === 'small') return ['text-sm', 'h-9', 'px-4', 'rounded']
  if (props.size === 'large') return ['text-lg', 'h-14', 'px-6', 'rounded']
  return ['text-base', 'h-12', 'px-4', 'rounded']
})

const colorClasses = computed<string[]>(() => {
  const colors: Record<
    typeof props.color,
    {
      border: string[]
      text: string[]
      altBg?: string[]
      bg: string[]
    }
  > = {
    primary: {
      border: ['border-v-300', 'hover:border-v-350'],
      text: [
        'text-v-500',
        'dark:text-v-300',
        'hover:text-v-600',
        'dark:hover:text-v-350'
      ],
      bg: [
        'bg-v-100',
        'hover:bg-v-200/75',
        'dark:bg-v-300',
        'dark:hover:bg-v-350'
      ]
    },
    red: {
      border: ['border-r-300', 'hover:border-r-350'],
      text: ['text-r-300', 'hover:text-r-350'],
      bg: ['bg-r-300', 'hover:bg-r-350']
    },
    yellow: {
      border: ['border-y-500', 'hover:border-y-550'],
      text: ['text-y-500', 'hover:text-y-550'],
      bg: ['bg-y-500', 'hover:bg-y-550']
    },
    blue: {
      border: ['border-b-500', 'hover:border-b-550'],
      text: ['text-b-500', 'hover:text-b-550'],
      bg: ['bg-b-500', 'hover:bg-b-550']
    },
    green: {
      border: ['border-g-500', 'hover:border-g-550'],
      text: ['text-g-500', 'hover:text-g-550'],
      bg: ['bg-g-500', 'hover:bg-g-550']
    },
    white: {
      border: ['border-n-0', 'hover:border-n-50'],
      text: ['text-n-0', 'hover:text-n-50'],
      bg: ['bg-n-0', 'hover:bg-n-50']
    },
    violet: {
      border: ['border-v-500', 'hover:border-v-550'],
      text: ['text-v-500', 'hover:text-v-550'],
      altBg: ['hover:bg-v-150'],
      bg: ['bg-v-500', 'hover:bg-v-550']
    },
    custom: {
      border: [`border-[var(--primary-color)]`],
      text: [`text-[var(--primary-color)]`],
      // Temporal fix for this custom color
      bg: [`bg-[var(--primary-color)] !text-n-0`]
    }
  }

  if (props.type === 'secondary') {
    return [
      'border',
      ...colors[props.color].border,
      ...colors[props.color].text
    ]
  }

  if (props.type === 'text') {
    return [...colors[props.color].text]
  }

  if (props.type === 'link') {
    return [
      'underline',
      ...colors[props.color].text,
      ...(colors[props.color].altBg || '')
    ]
  }

  return [
    'text-v-500',
    'hover:text-v-600',
    'dark:text-n-0',
    ...colors[props.color].border,
    ...colors[props.color].bg
  ]
})

const disabledClasses = computed<string[]>(() => {
  if (props.disabled)
    return ['opacity-30', 'cursor-not-allowed', 'pointer-events-none']
  return []
})

const iconClasses = computed<string[]>(() => {
  if (props.iconAlign === 'right') return ['flex-row-reverse']
  return []
})

const loadingClasses = computed<string[]>(() => {
  if (props.loading) return ['cursor-wait', 'pointer-events-none']
  return []
})

const classes = computed<string[]>(() => {
  return [
    ...sizeClasses.value,
    ...colorClasses.value,
    ...disabledClasses.value,
    ...iconClasses.value,
    ...loadingClasses.value
  ]
})

function click(e: MouseEvent): void {
  if (props.trackingId) {
    tracker.track(props.trackingId)
  }
  emit('click', e)
}
</script>
