<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 font-body text-3 transition bg-n-900/80 backdrop-blur-sm absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden dark pt-safe"
        @click="$emit('close')"
      >
        <div
          :class="{ open: open, closed: !open }"
          class="menu transition bg-n-800 text-n-0 w-full h-full flex flex-col pb-safe"
          @click.stop
        >
          <div
            class="flex-none relative bg-n-900 flex flex-row px-4 h-14 items-center"
          >
            <div class="flex-1 text-xl font-bold">
              {{ config?.locationName }}
            </div>
            <div class="flex-none">
              <l-button
                size="medium"
                icon="close"
                type="text"
                color="white"
                @click="$emit('close')"
              />
            </div>
          </div>
          <div class="flex-1 [&>*]:border-b [&>*]:border-b-n-700">
            <div class="mx-6 py-6 flex flex-col last:border-b-0">
              <div class="text-base font-medium">
                {{ currentEmployee?.name }}
              </div>
              <router-link :to="{ name: 'employees' }">
                <l-button type="text" class="!px-0">
                  {{ $t('sidemenu.change-employee') }}
                </l-button>
              </router-link>
            </div>
            <div
              v-if="isDataphoneWithPrinter"
              class="py-4 flex items-center mx-4 px-4"
            >
              <l-checkbox
                v-model="useSelfPrinter"
                :label="$t('sidemenu.use-self-printer')"
              />
            </div>
            <div
              v-if="!isMobile"
              class="py-4 flex items-center mx-6 px-0 last:border-b-0"
              @click="closeApp()"
            >
              <l-icon size="small" name="power" />
              <span class="ml-2">{{ $t('sidemenu.close-application') }}</span>
            </div>
            <div
              v-if="startedShifts.length > 0"
              class="mx-6 px-0 py-4 flex items-center cursor-pointer last:border-b-0"
              @click="endShift()"
            >
              <l-icon name="inactive-2" class="mr-2" />
              {{ t('sidemenu.end-shift') }}
            </div>
            <router-link
              :to="{ name: 'hardwareSelector' }"
              class="mx-6 px-0 py-4 flex items-center cursor-pointer last:border-b-0"
              @click="$emit('close')"
            >
              <l-icon name="printer" class="mr-2" />
              {{ $t('sidemenu.hardware-management') }}
            </router-link>
          </div>
          <div class="flex-none sm:hidden pb-4">
            <div
              class="flex flex-row text-n-0 text-xs justify-center items-center cursor-pointer"
              @click="openAbout"
            >
              <div>{{ $t('sidemenu.version') }}: {{ appVersion }}</div>
              <l-icon class="px-1" name="question-circle" size="medium" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LButton, LCheckbox, LIcon, useDialog } from '@last/core-ui/paprika'

import app from '@/app'
import { useNotifications } from '@/composables/useNotifications'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import { useConfirm } from '@/plugins/managerConfirmation'
import { useAuthStore } from '@/store/auth'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { useTillStore } from '@/store/till'

type Props = {
  open: boolean
}

defineProps<Props>()

const emit = defineEmits(['close'])

const { t } = useI18n()
const dialog = useDialog()
const { notifyInfo } = useNotifications()
const managerConfirmation = useConfirm()
const router = useRouter()
const authStore = useAuthStore()
const tillStore = useTillStore()
const tabsStore = useTabsStore()

const { currentEmployee, listEmployees } = storeToRefs(authStore)

const configStore = useConfigStore()
const { updateHardwarePreferences } = configStore
const { device, config, tills, appVersion } = storeToRefs(configStore)
const { startedShifts } = storeToRefs(tillStore)
const { tabs } = storeToRefs(tabsStore)

const isMobile = computed(() => {
  const platform = Capacitor.getPlatform()
  return platform !== 'web'
})

function openAbout() {
  router.push({ name: 'about' })
}

const useSelfPrinter = computed({
  get: () => !!device.value.useSelfPrinter,
  set: (value: boolean) => updateHardwarePreferences({ useSelfPrinter: value })
})

const isDataphoneWithPrinter = computed<boolean>(() => {
  return device.value?.model === 'Saturn1000F2'
})

const employeeTills = computed(() => {
  return listEmployees.value
    .filter(employee => !!employee.tillId && employee.tillEnabled)
    .map(employee => employee.tillId!)
})

async function performActionAskPinIfNeeded(privilege: string) {
  const hasPrivilege = await managerConfirmation(privilege)
  if (hasPrivilege) {
    return true
  } else {
    emit('close')
    return false
  }
}

async function endShift() {
  if (!(await performActionAskPinIfNeeded('SHIFT_MANAGER'))) return
  const openTabs =
    Object.values(tabs.value).filter(tab => tab.open && !!tab.activationTime)
      .length > 0
  const cashTillIds: string[] = [
    ...tills.value.cash.map(till => till.id),
    ...employeeTills.value
  ]
  const cashShifts: string[] = startedShifts.value.filter(tillId =>
    cashTillIds.includes(tillId)
  )
  if (openTabs && cashShifts.length > 1) {
    dialog({
      title: t('open-tabs-dialog.title'),
      content: t('open-tabs-dialog.message'),
      onConfirm: () => {
        router.push({ name: 'endShift' })
      }
    })
  } else if (openTabs) {
    notifyInfo({
      title: t('open-tabs-dialog.title')
    })
  } else {
    router.push({ name: 'endShift' })
  }
  emit('close')
}

async function closeApp() {
  if (CashMachine.isEnabled()) {
    await CashMachine.close()
  }
  app.quit()
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.transition {
  transition: all 0.3s;
}

.closed {
  transform: translateX(20rem);
}

.open {
  transform: translateX(0rem);
}
</style>
