import { NetworkError } from '@/api/errors'
import { AuthApi, Configuration, CouriersApi, CustomersApi } from '@/openapi'
import { useAuthStore } from '@/store/auth'

const configuration = new Configuration({
  basePath: `${import.meta.env.VITE_APP_BASE_URL}/pos`,
  middleware: [
    {
      pre: async ctx => {
        const auth = useAuthStore()
        if (auth.accessToken && auth.locationId) {
          ctx.init.headers = {
            ...ctx.init.headers,
            Authorization: auth.accessToken,
            'Location-ID': auth.locationId
          }
        } else if (auth.accessToken) {
          ctx.init.headers = {
            ...ctx.init.headers,
            Authorization: auth.accessToken
          }
        }
        return ctx
      }
    },
    {
      onError: async ctx => {
        if (
          ctx.error instanceof Error &&
          (ctx.error.message === 'Failed to fetch' ||
            ctx.error.message === 'Load failed')
        ) {
          throw new NetworkError('No internet connection')
        }
        throw ctx.error
      }
    }
  ]
})

export const customersApi = new CustomersApi(configuration)
export const couriersApi = new CouriersApi(configuration)
export const authApi = new AuthApi(configuration)
