<template>
  <div class="flex flex-col justify-between items-center gap-8">
    <div class="flex w-full flex-col gap-8">
      <div>
        <h1 class="text-3xl text-n-800 dark:text-n-0 font-bold font-poppins">
          {{ $t('new-password.email.title') }}
        </h1>
        <p class="text-sm text-n-300 mt-2">
          {{ $t('new-password.email.description-sent') }}
        </p>
      </div>
    </div>
    <l-button class="w-full" @click="() => emit('login')">
      {{ $t('ctas.login') }}
    </l-button>
  </div>
</template>

<script setup lang="ts">
import { LButton } from '@last/core-ui/paprika'

const emit = defineEmits<{
  login: []
}>()
</script>
