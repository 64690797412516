import { createRouter, createWebHashHistory } from 'vue-router'

import app from '@/app'
import { mobileRoutes } from '@/router/mobileRoutes'
import { routes } from '@/router/routes'
import pinia from '@/store'
import { useAuthStore } from '@/store/auth'
import { useStatusStore } from '@/store/status'

export const router = createRouter({
  history: createWebHashHistory(),
  routes: app.isMobile ? mobileRoutes : routes
})

router.beforeEach(async (to, _from, next) => {
  const auth = useAuthStore(pinia)
  const status = useStatusStore(pinia)
  const needsAuth =
    !['login', 'signUp', 'supportLogin', 'demoPos', 'newPassword'].includes(
      to.name?.toString() || ''
    ) &&
    ![
      '/pos/login',
      '/pos/signUp',
      '/support-login',
      '/demo-pos',
      '/new-password'
    ].includes(to.path)
  const isAuthenticated = auth.isAuthenticated
  const gracePeriodExpired = auth.gracePeriodExpired
  const trialExpired = auth.trialExpired
  const hasTimeError = status.hasTimeError

  if (needsAuth && !isAuthenticated) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else if (
    needsAuth &&
    gracePeriodExpired &&
    to.name !== 'gracePeriodExpired'
  ) {
    next({
      name: 'gracePeriodExpired'
    })
  } else if (needsAuth && trialExpired && to.name !== 'freeTrialExpired') {
    next({
      name: 'freeTrialExpired'
    })
  } else if (hasTimeError && to.name !== 'wrongTime') {
    next({
      name: 'wrongTime'
    })
  } else {
    next()
  }
})
