<template>
  <div class="flex">
    <div
      v-if="floorplanIds.length > 0"
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': route.name === 'floorplan'
      }"
      @click="router.push({ name: 'floorplan' })"
    >
      <l-icon size="medium" name="floorplan" />
    </div>
    <div
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': route.name === 'delivery'
      }"
      @click="router.push({ name: 'delivery' })"
    >
      <l-icon size="medium" name="bike" />
    </div>
    <div
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': route.name === 'takeAway'
      }"
      @click="router.push({ name: 'takeAway' })"
    >
      <l-icon size="medium" name="take-away" />
    </div>
    <div
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': route.name === 'tabs'
      }"
      @click="router.push({ name: 'tabs' })"
    >
      <l-icon size="medium" name="cards" />
    </div>
    <div
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': route.name === 'reservations'
      }"
      @click="router.push({ name: 'reservations' })"
    >
      <l-icon size="medium" name="calendar" />
    </div>
    <div
      v-if="config.fastMode && !app.isMobile"
      class="px-3 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="[route.name === 'fastMode' ? 'bg-y-500 text-n-0' : 'text-y-500']"
      @click="router.push({ name: 'fastMode' })"
    >
      <l-icon size="medium" name="fast-pos" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

import { LIcon } from '@last/core-ui/paprika'

import app from '@/app'
import { useConfigStore } from '@/store/config'
import { useTablesStore } from '@/store/tables'

const tableStore = useTablesStore()
const configStore = useConfigStore()
const { floorplanIds } = storeToRefs(tableStore)
const { config } = storeToRefs(configStore)

const route = useRoute()
const router = useRouter()
</script>
