<template>
  <div class="w-full h-screen flex justify-center items-center relative">
    <component
      v-if="component"
      class="w-full h-2/3 sm:w-1/2 p-8"
      :is="component"
      :email="email"
      :recover-id="recoverId"
      :token="token"
      :verification-token="verificationToken"
      :new-password="newPassword"
      @email-sent="emailSent"
      @login="goToLogin"
      @done="goToDone"
    />
    <img
      src="@last/core-ui/paprika/assets/svg/logo-last.svg"
      class="absolute mx-auto w-40 dark:hidden"
      style="bottom: 20px"
    />
    <img
      src="@last/core-ui/paprika/assets/svg/logo-last-white.svg"
      class="absolute mx-auto w-40 dark:block hidden"
      style="bottom: 20px"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef, type Component } from 'vue'

import EmailInput from './EmailInput.vue'
import EmailInputDone from './EmailInputDone.vue'
import NewPasswordDone from './NewPasswordDone.vue'
import PasswordInput from './PasswordInput.vue'
import RecoveryNotFound from './RecoveryNotFound.vue'

const email = ref<string>()

const component = shallowRef<Component>()

const components = {
  EmailInput,
  EmailInputDone,
  RecoveryNotFound,
  NewPasswordDone,
  PasswordInput
}

const props = defineProps<{
  recoverId: string
  token: string
  recoverPassword: (recoverId: string) => Promise<string>
  verificationToken: (email: string) => Promise<any>
  newPassword: (data: {
    token: string
    recoverId: string
    password: string
  }) => Promise<any>
}>()

onMounted(async () => {
  if (!props.recoverId) {
    component.value = components['EmailInput']
  } else if (!props.token) {
    component.value = components['RecoveryNotFound']
  } else {
    await getUserEmailByRecoveryId(props.recoverId)
  }
})

const emit = defineEmits<{
  login: []
}>()

async function goToLogin() {
  emit('login')
}

function emailSent() {
  component.value = components['EmailInputDone']
}

async function goToDone() {
  component.value = components['NewPasswordDone']
}

async function getUserEmailByRecoveryId(recoverId: string) {
  const data = await props.recoverPassword(recoverId)
  if (!data) {
    component.value = components['RecoveryNotFound']
  } else {
    email.value = data
    component.value = components['PasswordInput']
  }
}
</script>
