<template>
  <label
    class="flex items-center text-n-600 dark:text-n-0"
    :class="[
      $attrs.disabled
        ? 'pointer-events-none cursor-not-allowed opacity-30'
        : 'cursor-pointer',
      size === 'medium' ? 'text-base' : 'text-sm'
    ]"
  >
    <input
      type="radio"
      v-bind="$attrs"
      v-model="model"
      :class="[
        'appearance-none m-0 rounded-full border dark:border-v-300 border-v-500 grid place-items-center transition-transform mr-2',
        'before:rounded-full before:scale-0 dark:before:bg-v-300 before:bg-v-500 before:checked:scale-100',
        size === 'medium' ? 'h-5 w-5' : 'h-4 w-4',
        size === 'medium'
          ? 'before:h-3 before:w-3'
          : 'before:h-[0.375rem] before:w-[0.375rem]'
      ]"
    />
    <slot>{{ label }}</slot>
  </label>
</template>

<script setup lang="ts">
import { type InputHTMLAttributes } from 'vue'

type Props = {
  /** @deprecated Use slot */
  label?: string
  /**
   * Size of the radio button
   * @default small
   */
  size?: 'small' | 'medium'
} & /* @vue-ignore */ InputHTMLAttributes

/**
 * Propagate
 */
defineProps<Props>()

const model = defineModel<string | boolean>()
</script>
