import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import api from '@/api/fetch'
import sync from '@/sync/service'
import { Beeper } from '@/types/beeper'

export const useBeepersStore = defineStore(
  'beepers',
  () => {
    const beepers = ref<Record<string, Beeper>>({})
    const beepersMetadata = ref<any>({})

    async function refreshBeepers() {
      const { beepers: localBeepers, metadata } = (
        await api.get('/pos/beepers')
      ).data
      beepers.value = localBeepers.reduce(
        (acc: Record<string, Beeper>, beeper: Beeper) => {
          acc[beeper.id] = beeper
          return acc
        },
        {}
      )
      beepersMetadata.value = metadata
    }

    async function assignBeeper(id: string, tabId: string) {
      beepers.value[id].tabId = tabId
      sync.record('beeperAssigned', {
        beeperId: id,
        tabId
      })
    }

    return {
      refreshBeepers,
      assignBeeper,
      beepers,
      beepersMetadata
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBeepersStore, import.meta.hot))
}
