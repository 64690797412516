<template>
  <div class="flex flex-col items-center gap-4">
    <div class="w-22 h-22 dark:hidden" v-html="svg.light" />
    <div class="w-22 h-22 hidden dark:block" v-html="svg.dark" />
    <div
      class="font-body text-sm text-n-400 dark:text-n-0 first-letter:uppercase text-center"
    >
      <slot />
    </div>
    <slot name="action" />
  </div>
</template>

<script lang="ts">
const modules = import.meta.glob('../assets/svg/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
  type: 'error' | 'empty' | 'warning' | string | { light: string; dark: string }
}

const props = withDefaults(defineProps<Props>(), {
  type: 'error'
})

const svg = computed(() => {
  let iconName = ''
  switch (props.type) {
    case 'empty':
      iconName = 'empty-case'
      break
    case 'warning':
      iconName = 'warning'
      break
    case 'error':
      iconName = 'dizzy-robot'
      break
    default:
      return typeof props.type === 'string'
        ? { light: props.type, dark: props.type }
        : props.type
  }
  return {
    light: modules['../assets/svg/' + iconName + '.svg'],
    dark: modules['../assets/svg/' + iconName + '-dark.svg']
  }
})
</script>
