/* tslint:disable */
/* eslint-disable */
/**
 * POS API
 * Internal POS API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { LoginRequestOneOf } from './LoginRequestOneOf';
import {
    instanceOfLoginRequestOneOf,
    LoginRequestOneOfFromJSON,
    LoginRequestOneOfFromJSONTyped,
    LoginRequestOneOfToJSON,
} from './LoginRequestOneOf';
import type { LoginRequestOneOf1 } from './LoginRequestOneOf1';
import {
    instanceOfLoginRequestOneOf1,
    LoginRequestOneOf1FromJSON,
    LoginRequestOneOf1FromJSONTyped,
    LoginRequestOneOf1ToJSON,
} from './LoginRequestOneOf1';

/**
 * @type LoginRequest
 * 
 * @export
 */
export type LoginRequest = LoginRequestOneOf | LoginRequestOneOf1;

export function LoginRequestFromJSON(json: any): LoginRequest {
    return LoginRequestFromJSONTyped(json, false);
}

export function LoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequest {
    if (json == null) {
        return json;
    }
    if (instanceOfLoginRequestOneOf(json)) {
        return LoginRequestOneOfFromJSONTyped(json, true);
    }
    if (instanceOfLoginRequestOneOf1(json)) {
        return LoginRequestOneOf1FromJSONTyped(json, true);
    }

    return {} as any;
}

export function LoginRequestToJSON(json: any): any {
    return LoginRequestToJSONTyped(json, false);
}

export function LoginRequestToJSONTyped(value?: LoginRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfLoginRequestOneOf(value)) {
        return LoginRequestOneOfToJSON(value as LoginRequestOneOf);
    }
    if (instanceOfLoginRequestOneOf1(value)) {
        return LoginRequestOneOf1ToJSON(value as LoginRequestOneOf1);
    }

    return {};
}

