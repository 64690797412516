import { Capacitor } from '@capacitor/core'
import { nextTick } from 'vue'

import { useDialog } from '@last/core-ui/paprika'

import i18n from '@/i18n'
import { useConfigStore } from '@/store/config'
import { Config } from '@/types/config'

import { AdyenDataphone } from './adyenDataphone'
import { DevDataphone } from './devDataphone'
import { RedsysDataphone } from './redsysDataphone'

export interface IDataphone {
  initialized: boolean
  canCancel: boolean
  init: () => void
  charge: (args: {
    amount: number
    paymentId: string
    tabId: string
  }) => Promise<any>
  refund: (payment: any, tabId: string) => Promise<any>
  cancel: (args: { paymentId: string; tabId: string }) => Promise<any>
}

class Dataphone {
  private device?: IDataphone

  constructor() {}

  async init(config: Config, preferredDataphoneId?: string) {
    try {
      const platform = Capacitor.getPlatform()
      const configStore = useConfigStore()
      if (!configStore.config.paymentMethods.includes('Dataphone')) return

      if (platform === 'web') {
        this.device = new DevDataphone()
      } else if (configStore.currentAdyenDataphone) {
        this.device = new AdyenDataphone(configStore.currentAdyenDataphone)
      } else {
        for (const dataphone of config.dataphoneConfigs) {
          if (
            dataphone.id === preferredDataphoneId ||
            (!preferredDataphoneId && dataphone.business)
          ) {
            this.device = this.device ?? new RedsysDataphone(dataphone)
            break
          }
        }

        for (const dataphone of config.adyenDataphones) {
          if (dataphone.id === preferredDataphoneId) {
            this.device = this.device ?? new AdyenDataphone(dataphone)
            break
          }
        }
      }

      if (!this.device) return
      this.device.init()
    } catch {
      throw new Error('No dataphone capability')
    }
  }

  async charge({
    amount,
    paymentId,
    tabId
  }: {
    amount: number
    paymentId: string
    tabId: string
  }) {
    if (!this.device || !this.initialized()) {
      throw new Error('Dataphone is not initialized')
    }
    let dialogInstance
    try {
      let cancelled = false
      const dialog = useDialog()
      dialogInstance = dialog({
        icon: 'status',
        iconAnimation: 'animate-spin',
        ...(this.device.canCancel
          ? {
              mainLabel: i18n.global.t('payment-screen.cancel-operation'),
              onConfirm: () => {
                cancelled = true
              }
            }
          : {
              noLabels: true
            }),
        title: i18n.global.t('payment-screen.payment-in-process'),
        content: i18n.global.t('payment-screen.check-the-dataphone'),
        dismissable: false,
        hiddenClose: true
      })
      const metadata = await this.device.charge({ amount, paymentId, tabId })
      if (cancelled) {
        this.device.cancel({ paymentId, tabId })
        throw new Error('Payment cancelled')
      }
      await nextTick()
      dialogInstance?.close()
      return metadata
    } catch (e) {
      dialogInstance?.close()
      throw e
    }
  }

  refund(payment: any, tabId: string) {
    if (!this.device || !this.initialized()) {
      throw new Error('Dataphone is not initialized')
    }
    return this.device.refund(payment, tabId)
  }

  initialized() {
    return this.device?.initialized ?? false
  }
}

export default new Dataphone()
