<template>
  <div class="z-[100] top-0 fixed">
    <l-notification-list
      :notifications="notifications"
      @update:notifications="
        newValue => notifications.splice(0, notifications.length, ...newValue)
      "
      @cancel="notification => cancel(notification, true)"
    />
    <l-notification-fixed-list
      :open="fixedComponentOpen"
      :notifications="fixedNotifications"
      @update:notifications="
        newValue =>
          fixedNotifications.splice(0, fixedNotifications.length, ...newValue)
      "
      @cancel="notification => cancel(notification, false)"
      @close="fixedComponentOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import LNotificationFixedList from '../../components/Notifications/LNotificationFixedList.vue'
import LNotificationList from '../../components/Notifications/LNotificationList.vue'
import {
  fixedComponentOpen,
  fixedNotifications,
  notifications
} from './notification'
import type { Notification } from './types'

function cancel(notification: Notification, filter: boolean) {
  if (notification.onCancel) {
    notification.onCancel()
  }
  if (filter) {
    fixedNotifications.splice(fixedNotifications.indexOf(notification), 1)
  }
}
</script>
