import { isBefore, subMinutes } from 'date-fns'

import { logger } from '@/monitoring'
import sync from '@/sync/service'

import AdyenPrinter from './printers/adyenPrinter.js'
import Browser from './printers/browser'
import EscPos from './printers/escpos'
import KDS from './printers/kds'
import StarPrint from './printers/starPrint'
import UsbPrinter from './printers/usb'
import ZPL from './printers/zpl'
import { Printer, PrinterConfig, PrinterDriver, PrinterJob } from './types'

const MAX_RETRIES = 120

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function getPrinter(printer: Printer): PrinterDriver {
  const config: PrinterConfig = {
    name: printer.name,
    address: printer.ip,
    type: printer.type,
    metadata: printer.metadata,
    ticketWidth: printer.ticketWidth,
    ticketHeight: printer.ticketHeight,
    ticketPaddingLeft: printer.ticketPaddingLeft
  }

  switch (printer.type) {
    case 'StarGraphic':
      return new StarPrint(config)
    case 'StarPRNT':
      return new StarPrint(config)
    case 'EscPos':
      return new EscPos(config)
    case 'AdyenPrinter':
      return new AdyenPrinter(config)
    case 'EscPosMatrix':
      return new EscPos({ ...config, matrixMode: true })
    case 'ZPL':
      return new ZPL(config)
    case 'Usb':
      return new UsbPrinter(config)
    case 'KDS':
      return new KDS()
    default:
      return new Browser(config)
  }
}

async function print(queue: PrinterQueue) {
  if (queue.printing) return
  queue.printing = true
  while (queue.jobs.length > 0) {
    const job = queue.jobs[0]
    let jobDone = false
    try {
      if (
        job.time &&
        isBefore(new Date(job.time), subMinutes(new Date(), 10))
      ) {
        job.retries = MAX_RETRIES
        throw 'Job is too old'
      }
      if (job.type === 'printImage') {
        await queue.printer.printImage(job.image!)
      } else if (job.type === 'openCashDrawer') {
        await queue.printer.openCashDrawer()
      }
      jobDone = true
    } catch (error) {
      logger.error('Printer queue error', { error })
      await sleep(5000)
      if (
        (!job.retries || job.retries < MAX_RETRIES) &&
        job.type !== 'openCashDrawer'
      ) {
        queue.jobs.push({ ...job, retries: (job.retries || 0) + 1 })
      }
    }
    if (job.printedEvent && jobDone) {
      try {
        sync.record(job.printedEvent.name, {
          ...job.printedEvent.data,
          printedTime: new Date()
        })
      } catch (error) {
        logger.error('Printer queue error', { error })
      }
    }
    queue.jobs.shift()
  }
  queue.printing = false
}

class PrinterQueue {
  public jobs: PrinterJob[] = []
  public printer: PrinterDriver
  public printerId: string
  public printing: boolean

  constructor(printer: Printer) {
    this.printerId = printer.id
    this.printing = false
    this.printer = getPrinter(printer)
    this.jobs = []
  }

  addJob(job: PrinterJob) {
    this.jobs.push(job)
    print(this)
  }
}

export default PrinterQueue
