<template>
  <div
    v-if="svg"
    class="flex items-center justify-center flex-shrink-0"
    :class="{
      'current-color-fill': !originalColor,
      'w-3 h-3': props.size === 'x-small',
      'w-4 h-4': props.size === 'small',
      'w-6 h-6': props.size === 'medium',
      'w-8 h-8': props.size === 'large'
    }"
    v-html="svg"
  />
</template>

<script lang="ts">
const modules = import.meta.glob('../assets/icons/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: false
})
</script>

<script setup lang="ts">
import { computedAsync } from '@vueuse/core'

import type { Size } from '@last/core-ui/paprika/components/types'

import type { LIconName } from './icon.type'

interface Props {
  name: LIconName
  size?: Size
  originalColor?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  originalColor: false,
  size: 'small'
})

const svg = computedAsync<string>(async () => {
  const path = `../assets/icons/${props.name}.svg`
  const iconSvg = (await modules[path]()) as string
  if (!iconSvg) {
    // eslint-disable-next-line no-console
    console.error(`LIcon ${props.name} not found`)
    return ''
  }
  return iconSvg
})
</script>

<style>
.current-color-fill path {
  fill: currentColor;
}
</style>
